import './App.scss';
import LanguageContextProvider from './context/LanguageContext';
import Router from './Routes';

const App = () => {
	return (
		<LanguageContextProvider>
			<Router />
		</LanguageContextProvider>
	);
};

export default App;
