import { TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SearchIcon from '@mui/icons-material/Search';
import React from 'react';
import useTranslation from '../../hooks/translation';
import './Chatwithus.scss';

const ITEM_HEIGHT = 48;

const ChatWithUs = () => {
	const translation = useTranslation() as any;
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const options = [
		translation.account.profile,
		translation.account.viewChat,
		translation.account.markRead,
		translation.account.delete,
	];

	const data = [
		{
			id: 1,
			profileImage: '/static/images/profilepic.svg',
			username: 'Arlan Pond',
			message: 'Photo',
			letter: 'A',
		},
		{
			id: 2,
			profileImage: '/static/images/profilepic.svg',
			username: 'Arlan Pond',
			message: 'Hello how are you?',
			letter: 'A',
		},
		{
			id: 3,
			profileImage: '/static/images/profilepic.svg',
			username: 'Arlan Pond',
			message: 'Hi!',
			letter: 'A',
		},
		{
			id: 4,
			profileImage: '/static/images/profilepic.svg',
			username: 'Arlan Pond',
			message: 'Hi!',
			letter: 'A',
		},
	];
	return (
		<div className='chatmain'>
			{/* <h3>Hi Sameera !</h3>
			<p>{translation.account.dashboardtext}</p> */}
			<div className='chatcontainer'>
				<div className='chatleft'>
					<div className='chatbtn'>
						<button type='button' className='chat'>
							{translation.account.chatwith}
						</button>
						<button type='button' className='vendorlist'>
							{translation.account.vendor}
						</button>
					</div>

					<div className='searc'>
						<input
							type='text'
							placeholder={translation.account.search}
						/>
						<SearchIcon />
					</div>
					<div className='users'>
						<ul>
							{data.map((item) => {
								return (
									<li key={item.id}>
										<div className='dbleft'>
											<div className='ppic'>
												<h1>{item.letter}</h1>
												<div className='onlined' />
											</div>

											<div className='profile'>
												<h5>{item.username}</h5>
												<p>{item.message}</p>
											</div>
										</div>
										<div className='dbryt'>
											<IconButton
												sx={{
													position: 'relative',
												}}
												aria-label='more'
												id='long-button'
												aria-controls={
													open
														? 'long-menu'
														: undefined
												}
												aria-expanded={
													open ? 'true' : undefined
												}
												aria-haspopup='true'
												onClick={handleClick}
											>
												<MoreHorizIcon />
											</IconButton>
											<Menu
												className='menu_iitem'
												id='long-menu'
												MenuListProps={{
													'aria-labelledby':
														'long-button',
												}}
												anchorEl={anchorEl}
												open={open}
												onClose={handleClose}
												PaperProps={{
													style: {
														maxHeight:
															ITEM_HEIGHT * 4.5,
														width: '20ch',
													},
												}}
											>
												{options.map((option, i) => (
													<MenuItem
														// eslint-disable-next-line react/no-array-index-key
														key={i}
														selected={
															option === 'Pyxis'
														}
														onClick={handleClose}
													>
														{option}
													</MenuItem>
												))}
											</Menu>

											<h4>08.27 PM</h4>
										</div>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
				<div className='chatright'>
					<div className='chatdiv'>
						<div className='outgoing'>
							<p>Lorem ipsum dolor sit </p>
						</div>

						<div className='incomingdoc'>
							<figure>
								<img src='/static/images/doc.svg' alt='' />
							</figure>
							{/* <p>Lorem ipsum dolor sit </p> */}
						</div>

						<div className='outgoing'>
							<figure>
								<img src='/static/images/chatimg.png' alt='' />
							</figure>
						</div>
						<div className='incoming'>
							<p>Lorem ipsum dolor sit </p>
						</div>
						<div className='or_opt'>
							<hr />
							<p>{translation.account.today}</p>
						</div>
						<div className='outgoing'>
							<p>Lorem ipsum dolor sit </p>
						</div>
					</div>
					<div className='sendbar'>
						<div className='sharebtn'>
							<figure>
								<img src='/static/images/emoji.svg' alt='' />
							</figure>
							<figure>
								<img src='/static/images/share.svg' alt='' />
							</figure>
						</div>

						<div className='sendbarr'>
							<input
								type='text'
								placeholder={translation.account.writemsg}
							/>
							<figure>
								<img src='/static/images/send.svg' alt='' />
							</figure>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ChatWithUs;
