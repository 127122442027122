import React from 'react';
import { useNavigate } from 'react-router-dom';
import EastIcon from '@mui/icons-material/East';
import useTranslation from '../hooks/translation';

type PresaleCardtype = {
	id: number;
	sideImages: string[];
	image: string;
	daysLeft: string;
	hoursLeft: string;
	minutesLeft: string;
	title: string;
	subTitle: string;
	rating: string;
	reviews: string;
	description: string;
	city: string;
	completeAddress: string;
	price1: string;
	price2: string;
	navigateTo: () => void;
};

const PresaleCard = ({
	navigateTo,
	id,
	sideImages,
	image,
	daysLeft,
	hoursLeft,
	minutesLeft,
	title,
	subTitle,
	rating,
	reviews,
	description,
	city,
	completeAddress,
	price1,
	price2,
}: PresaleCardtype) => {
	const translation = useTranslation() as any;
	const navigate = useNavigate();
	return (
		<div className='pre-crd'>
			<div className='pre-crd-lft'>
				{sideImages.map((item) => {
					return (
						<figure>
							<img src={item} alt='' />
						</figure>
					);
				})}
			</div>
			<div className='pre-crd-mdl'>
				<figure>
					<img src={image} alt='' />
				</figure>
				<div className='tym-lft'>
					<div>
						{daysLeft}
						<h6>{translation.Globals.days}</h6>
					</div>
					<div>
						{hoursLeft}
						<h6>{translation.Globals.hours}</h6>
					</div>
					<div>
						{minutesLeft}
						<h6>{translation.Globals.minutes}</h6>
					</div>
				</div>
			</div>
			<div className='pre-crd-ryt'>
				<div className='title-sec'>
					<h2>{title}</h2>
					<figure>
						<img src='static/images/like2.svg' alt='' />
					</figure>
				</div>
				<h4>{subTitle}</h4>
				<div className='rtng'>
					<div>
						<figure>
							<img src='static/images/rating-star.svg' alt='' />
						</figure>
						<h6>{rating}</h6>
					</div>
					<p>({reviews})</p>
				</div>
				<p>{description}</p>
				<div className='adrss-dtls'>
					<figure>
						<img src='/static/images/map2.svg' alt='' />
					</figure>
					<p>
						{city}
						<span>{completeAddress}</span>
					</p>
				</div>
				<div className='prc-sec'>
					<h4>
						{price1}{' '}
						<span>
							<s>
								<i>{price2}</i>
							</s>
						</span>
					</h4>
					<button
						className='btn2'
						type='button'
						onClick={() =>
							navigate('/details', { state: 'presale' })
						}
					>
						{translation.Globals.viewDetails}
						<EastIcon />
					</button>
				</div>
			</div>
		</div>
	);
};

export default PresaleCard;
