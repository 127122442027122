// eslint-disable-next-line import/no-extraneous-dependencies
import { useFormik } from 'formik';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Yup from 'yup';
import { Divider } from '@mui/material';

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	ContactInformation,
	OrderDetail,
	OrderSummary,
	PaymentMethod,
} from '../../components';
import useTranslation from '../../hooks/translation';
import Layout from '../../layout';
import './Cart.scss';
import { useLanguageContext } from '../../context/LanguageContext';

const Cart = () => {
	const navigate = useNavigate();
	const translation = useTranslation() as any;
	const [activeStep, setActiveStep] = useState(1);
	const { language, changeLanguage } = useLanguageContext();
	const formik = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			address: '',
			state: '',
			city: '',
			pinCode: '',
			remember: false,
		},
		validationSchema: Yup.object({
			remember:
				activeStep === 2 ? Yup.boolean() : Yup.boolean().notRequired(),
			firstName:
				activeStep === 2
					? Yup.string()
							.max(15, 'Must be 15 characters or less')
							.min(3, 'Must be atleast 3 characters')
							.required('This field is required')
					: Yup.string().notRequired(),
			lastName:
				activeStep === 2
					? Yup.string()
							.max(20, 'Must be 20 characters or less')
							.min(3, 'Must be atleast 3 characters')
							.required('This field is required')
					: Yup.string().notRequired(),
			address:
				activeStep === 2
					? Yup.string()
							.required('This field is required.')
							.label('Address')
							.min(3, 'Must be atleast 3 characters')
					: Yup.string().notRequired(),

			state:
				activeStep === 2
					? Yup.string()
							.required('This field is required.')
							.label('State')
					: Yup.string().notRequired(),
			// .min(3, 'Must be atleast 3 characters'),
			city:
				activeStep === 2
					? Yup.string()
							.required('This field is required.')
							.label('State')
					: Yup.string().notRequired(),
			// .min(3, 'Must be atleast 3 characters'),

			pinCode:
				activeStep === 2
					? Yup.string()
							.required('This field is required.')
							.label('Pin code')
							.min(3, 'Pin code must be at least 3 characters.')
							.max(10, 'Pin code must be at most 10 digits.')
					: Yup.string().notRequired(),
		}),
		onSubmit: () => {
			if (activeStep === 1) {
				setActiveStep(2);
			} else if (activeStep === 2) {
				setActiveStep(3);
			}
		},
	});

	const data = [
		{
			id: 1,
			img: '/static/images/ordr-smry-img.svg',
			name: 'Oderm',
			des: 'Simple Beaute, Esthetique',
		},
		{
			id: 2,
			img: '/static/images/ordr-smry-img.svg',
			name: 'Oderm',
			des: 'Simple Beaute, Esthetique',
		},
	];

	const handleRemember = (item: boolean) => {
		console.log(item, 'ittttt');

		if (!item) {
			const creds = {
				firstName: formik.values.firstName,
				lastName: formik.values.lastName,
				address: formik.values.address,
				state: formik.values.state,
				city: formik.values.city,
				pinCode: formik.values.pinCode,
				remember: !item,
			};
			console.log(creds, 'truee cookie');
			// setCookie('Remember', JSON.stringify(creds));
		} else {
			console.log('false cookie');

			// deleteCookie('Remember');
		}
	};

	const handleActiveSteps = () => {
		switch (activeStep) {
			case 1:
				return (
					<div className='inr-crt-crd'>
						<h2>{translation.account.cart.cart}</h2>
						<p>{translation.account.orderSummary.des}</p>
						<Divider />
						{data?.length
							? data?.map((item) => {
									return (
										<OrderDetail
											key={item.id}
											img={item.img}
											name={item.name}
											description={item.des}
										/>
									);
							  })
							: null}
					</div>
				);
			case 2:
				return (
					<ContactInformation
						formik={formik}
						handleRemember={handleRemember}
					/>
				);
			case 3:
				return <PaymentMethod />;

			default:
				return null;
		}
	};
	// const handleButton = () => {
	// 	if (activeStep === 1) {
	// 		setActiveStep(2);
	// 	} else if (activeStep === 2) {
	// 		setActiveStep(3);
	// 	}
	// };

	return (
		<Layout>
			<div className='cart-pg container'>
				<div className='crt-lft'>
					<div
						className={
							language === 'fr' ? 'stppr-sec2' : 'stppr-sec'
						}
					>
						<div className='stpr-dv'>
							<div className='stpr_inr'>
								<figure>
									<img
										src='/static/images/white-tick.svg'
										alt=''
									/>
								</figure>
							</div>
							<h4 className='stpr_inrh4'>
								{translation.account.cart.cart}
							</h4>
						</div>
						<div className='stpr-dv'>
							<div
								className={
									activeStep >= 2 ? 'stpr_inr' : 'stpr_inr2'
								}
							>
								<figure>
									<img
										src='/static/images/white-tick.svg'
										alt=''
									/>
								</figure>
							</div>
							<h4
								className={
									activeStep >= 2
										? 'stpr_inrh4'
										: 'stpr_in2rh4'
								}
							>
								{translation.account.cart.info}
							</h4>
						</div>
						<div className='stpr-dv'>
							<div
								className={
									activeStep >= 3 ? 'stpr_inr' : 'stpr_inr2'
								}
							>
								<figure>
									<img
										src='/static/images/white-tick.svg'
										alt=''
									/>
								</figure>
							</div>
							<h4
								className={
									activeStep >= 3
										? 'stpr_inrh4'
										: 'stpr_in2rh4'
								}
							>
								{translation.account.cart.payment}
							</h4>
						</div>
					</div>
					<form onSubmit={formik.handleSubmit}>
						<div
							className={
								activeStep === 1 ? 'cart-crd' : 'cart-crd2'
							}
						>
							{handleActiveSteps()}

							{activeStep < 3 ? (
								<div className='crt-btn-sec'>
									<button
										className='btn2'
										type='button'
										onClick={() => navigate('')}
									>
										{
											translation.account.checkAvailabilty
												.continueShopping
										}
									</button>

									<button className='btn' type='submit'>
										{translation.Globals.proceed}
									</button>
								</div>
							) : null}
						</div>
					</form>
				</div>

				<div className='order-summry'>
					<OrderSummary
						activeStep={activeStep}
						setActiveStep={setActiveStep}
					/>
				</div>
			</div>
		</Layout>
	);
};

export default Cart;
