import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import React, { Dispatch, SetStateAction, useState } from 'react';
import {
	ForgotPassword,
	SignUp,
	Login,
	UpdatePassword,
} from '../../components';
import useTranslation from '../../hooks/translation';
import './Authentication.scss';

const Authentication = () => {
	const navigate = useNavigate();
	const translation = useTranslation() as any;
	const [screen, setScreen] = useState<number>(1);
	const [step, setStep] = useState<number>(1);

	const handleScreens = () => {
		switch (screen) {
			case 1:
				return (
					<SignUp
						step={step}
						setStep={setStep}
						setScreen={setScreen}
					/>
				);
			case 2:
				return (
					<Login
						setScreen={setScreen}
						step={step}
						setStep={setStep}
					/>
				);
			case 3:
				return <ForgotPassword setScreen={setScreen} />;
			case 4:
				return <UpdatePassword setScreen={setScreen} />;

			default:
				return null;
		}
	};
	return (
		<div className='auth-sec'>
			<div className='auth-lft'>
				<div className='container'>
					<div onClick={() => navigate('/')}>
						<figure
							className='auth_logo'
							style={{ cursor: 'pointer' }}
						>
							<img src='../static/images/logo_coup.svg' alt='' />
						</figure>
					</div>
				</div>
				<div className='form_sc'>{handleScreens()}</div>
				<div className='btm-auth'>
					<h5>
						{translation.Globals.copyright}
						<span>{dayjs().year()}</span>{' '}
						{translation.Globals.coupify}
					</h5>
					<h6>{translation.Home.Footer.content.privacy}</h6>
				</div>
			</div>
			<div className='auth-ryt'>
				<figure>
					<img src='../static/images/coupify-auth-img.svg' alt='' />
				</figure>
			</div>
		</div>
	);
};

export default Authentication;
