import React, { Dispatch, SetStateAction } from 'react';
import { Divider } from '@mui/material';
import useTranslation from '../../hooks/translation';
import '../../pages/sellCouponNow/SellNow.scss';

type Stepstype = {
	setStep: Dispatch<SetStateAction<number>>;
	step: number;
};
const Stepper = ({ step, setStep }: Stepstype) => {
	const translation = useTranslation() as any;
	const stepperData = [
		{
			id: 1,
			step: translation.SellNow.Stepper.step,
			stepNo: translation.SellNow.Stepper.steps.one,
			stepName: translation.SellNow.Stepper.titles.browse,
		},
		{
			id: 2,
			step: translation.SellNow.Stepper.step,
			stepNo: translation.SellNow.Stepper.steps.two,
			stepName: translation.SellNow.Stepper.titles.details,
		},
		{
			id: 3,
			step: translation.SellNow.Stepper.step,
			stepNo: translation.SellNow.Stepper.steps.three,
			stepName: translation.SellNow.Stepper.titles.price,
		},
		{
			id: 4,
			step: translation.SellNow.Stepper.step,
			stepNo: translation.SellNow.Stepper.steps.four,
			stepName: translation.SellNow.Stepper.titles.info,
		},
		{
			id: 5,
			step: translation.SellNow.Stepper.step,
			stepNo: translation.SellNow.Stepper.steps.five,
			stepName: translation.SellNow.Stepper.titles.summary,
		},
	];
	return (
		<section className='sell_nw_stpr'>
			<ul className='container'>
				<Divider sx={{ margin: '10px 0 30px' }} />
				<div className='mn_stpr'>
					{stepperData.map((item) => {
						return (
							<div
								key={item.id}
								onClick={() => setStep(item?.id)}
								className={
									step === item?.id
										? 'sell_nw_stps'
										: 'sell_nw_stps2'
								}
							>
								<div className='inr_stps'>
									<h4>{item.step}</h4>
									<h2>{item.stepNo}</h2>
								</div>
								<div className='stp_nm'>
									<h3>{item.stepName}</h3>
								</div>
							</div>
						);
					})}
				</div>
			</ul>
		</section>
	);
};

export default Stepper;
