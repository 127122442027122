import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useTranslation from '../../hooks/translation';

const Offers = () => {
	const translation = useTranslation() as any;
	const [active, setActive] = useState(1);

	const navigate = useNavigate();
	const handleClick = (id: number) => {
		if (id === 1) {
			navigate('/discounted-services');
		} else if (id === 2) {
			navigate('/coupons');
		} else {
			navigate('/presale-coupons');
		}
	};

	const data = [
		{
			id: 1,
			img: '../static/images/Discounted _Services.svg',
			title: translation.Home.Header.discServc,
		},
		{
			id: 2,
			img: '../static/images/coupon_1.svg',
			title: translation.Home.Header.coupon,
		},
		{
			id: 3,
			img: '../static/images/Pre_Sale.svg',
			title: translation.Home.Header.presale,
		},
	];
	return (
		<section className='ofrs'>
			<ul className='container mn_ofrs'>
				{data.map((item, index) => {
					return (
						<div
							key={item.id}
							onClick={() => handleClick(item.id)}
							className={`ofr-dv ${
								active === index ? 'active' : ''
							}`}
							onMouseEnter={() => setActive(index)}
							onMouseLeave={() => setActive(1)}
						>
							<li>
								<div className='ofr-fig'>
									<figure>
										<img src={item.img} alt='' />
									</figure>
								</div>
								<h2>{item.title}</h2>
							</li>
						</div>
					);
				})}
			</ul>
		</section>
	);
};

export default Offers;
