import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Typography,
} from '@mui/material';
import { useState } from 'react';
import useTranslation from '../../hooks/translation';
import Layout from '../../layout';
import './PricingModel.scss';

const PricingModel = () => {
	const translation = useTranslation() as any;
	const [annual, setAnnual] = useState(true);

	const array = [
		{
			id: 1,
			question: 'Ques:1',
			answer: 'Ans:1',
		},
		{
			id: 2,
			question: 'Ques:2',
			answer: 'Ans:2',
		},
		{
			id: 3,
			question: 'Ques:3',
			answer: 'Ans:3',
		},
		{
			id: 4,
			question: 'Ques:4',
			answer: 'Ans:4',
		},
	];

	const data = [
		{
			id: 1,
			para: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
		},
		{
			id: 2,
			para: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
		},
		{
			id: 3,
			para: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
		},
	];
	return (
		<Layout>
			<div className='pmmain'>
				<div className='pmbnr'>
					<div className='ppbanner'>
						<h2>{translation.Cms.getstart}</h2>
						<h1>{translation.Cms.pickPan}</h1>
						<p>{translation.Cms.description}</p>
						<div className='tab'>
							<div
								className={annual ? 'active' : 'inactive'}
								onClick={() => setAnnual(true)}
							>
								<h3>{translation.Cms.annually}</h3>
							</div>
							<div
								className={!annual ? 'active' : 'inactive'}
								onClick={() => setAnnual(false)}
							>
								<h3>{translation.Cms.monthly}</h3>
							</div>
						</div>
					</div>
				</div>
				<div className='prmain'>
					<div className='container'>
						<div className='pfdivmain'>
							{data.map((item) => {
								return (
									<div className='professiondiv'>
										<div className='pdtop'>
											<h3>
												{translation.Cms.profession}
											</h3>
											<p>
												$490 {translation.Cms.permonth}
											</p>
										</div>
										<p>
											{translation.Cms.admonth}: $500 -
											$2500
										</p>

										<div className='lang'>
											<h3>{translation.Cms.lang}</h3>
											<p>
												$490 {translation.Cms.frenchEng}
											</p>
										</div>

										<div className='tools'>
											<h2>{translation.Cms.tool}</h2>

											<ul>
												<li>{item.para}</li>
												<li>{item.para}</li>
												<li>{item.para}</li>
												<li>{item.para}</li>
												<li>{item.para}</li>
												<li>{item.para}</li>
												<li>{item.para}</li>
											</ul>
										</div>
									</div>
								);
							})}
						</div>

						<div className='faqdiv'>
							<div className='faqleft'>
								<figure>
									<img
										src='/static/images/faqman.svg'
										alt=''
									/>
								</figure>
							</div>
							<div className='faqright'>
								<h2>{translation.Cms.faqwork}</h2>
								<div className=' faqsdiv'>
									{array.map((item) => {
										return (
											<Accordion className='accd'>
												<AccordionSummary
													aria-controls='panel1a-content'
													id='panel1a-header'
												>
													<Typography>
														{item?.question}
													</Typography>
												</AccordionSummary>
												<AccordionDetails>
													<Typography>
														{item.answer}
													</Typography>
												</AccordionDetails>
											</Accordion>
										);
									})}
								</div>
							</div>
						</div>
						{/* <ul>
							<li>
								<div className='limain'>
									<div className='litop'>
										<div className='liheading'>
											<h3>{}</h3>
										</div>
									</div>
									 <div className='libtm'></div> 
								</div>
							</li>
						</ul> */}
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default PricingModel;
