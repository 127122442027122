import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Typography,
} from '@mui/material';
import KeyboardArrowRightSharpIcon from '@mui/icons-material/KeyboardArrowRightSharp';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import React, { useState } from 'react';
import useTranslation from '../../hooks/translation';
import Layout from '../../layout';
import './faq.scss';

const Faq = () => {
	const translation = useTranslation() as any;

	const array = [
		{
			id: 1,
			question: translation.Cms.ques1,
			answer: translation.Cms.ans1,
		},
		{
			id: 2,
			question: translation.Cms.ques2,
			answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
		},
		{
			id: 3,
			question: translation.Cms.ques3,
			answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
		},
		{
			id: 4,
			question: translation.Cms.ques4,
			answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
		},
	];

	const array2 = [
		{
			id: 1,
			question: translation.Cms.ques5,
			answer: translation.Cms.ans2,
		},
		{
			id: 2,
			question: translation.Cms.ques6,
			answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
		},
		{
			id: 3,
			question: translation.Cms.ques7,
			answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
		},
		{
			id: 4,
			question: translation.Cms.ques8,
			answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
		},
	];

	const array3 = [
		{
			id: 1,
			question: translation.Cms.ques9,
			answer: translation.Cms.ans3,
		},
		{
			id: 2,
			question: translation.Cms.ques10,
			answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
		},
		{
			id: 3,
			question: translation.Cms.ques11,
			answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
		},
		{
			id: 4,
			question: translation.Cms.ques12,
			answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
		},
	];

	const array4 = [
		{
			id: 1,
			question: translation.Cms.ques13,
			answer: translation.Cms.ans4,
		},
		{
			id: 2,
			question: translation.Cms.ques14,
			answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
		},
		{
			id: 3,
			question: translation.Cms.ques15,
			answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
		},
		{
			id: 4,
			question: translation.Cms.ques16,
			answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
		},
	];

	const array5 = [
		{
			id: 1,
			question: translation.Cms.ques17,
			answer: translation.Cms.ans5,
		},
		{
			id: 2,
			question: translation.Cms.ques18,
			answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
		},
		{
			id: 3,
			question: translation.Cms.ques19,
			answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
		},
		{
			id: 4,
			question: translation.Cms.ques20,
			answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
		},
	];
	return (
		<Layout>
			<div className='contactmain'>
				<div className='privacybnr'>
					<div className='container'>
						<div className='ppbanner'>
							<div className='ppleft'>
								<h1>{translation.Cms.faq}</h1>
								<h3>{translation.Cms.coupnPurchase}</h3>
							</div>
							<div className='ppright'>
								<figure>
									<img src='/static/images/faq.svg' alt='' />
								</figure>
							</div>
						</div>
					</div>
				</div>
				<div className=' container faqs'>
					{array.map((item) => {
						return (
							<Accordion className='accd'>
								<AccordionSummary
									aria-controls='panel1a-content'
									id='panel1a-header'
								>
									<Typography>q: {item?.question}</Typography>
									<figure className='grey_img'>
										<img
											src='/static/images/down-grey.svg'
											alt=''
										/>
									</figure>
									<figure className='white_img'>
										<img
											src='/static/images/uparrow.svg'
											alt=''
										/>
									</figure>
								</AccordionSummary>
								<AccordionDetails className='acc-dtls'>
									<Typography
										sx={{
											fontSize: '14px',
											color: '#565656',
										}}
									>
										a: {item.answer}
									</Typography>
								</AccordionDetails>
							</Accordion>
						);
					})}

					<div className='faqdi'>
						<h3>{translation.Cms.electronicTicket}</h3>
						{array2.map((item) => {
							return (
								<Accordion className='accd'>
									<AccordionSummary
										aria-controls='panel1a-content'
										id='panel1a-header'
									>
										<Typography>
											q: {item?.question}
										</Typography>
										<figure className='grey_img'>
											<img
												src='/static/images/down-grey.svg'
												alt=''
											/>
										</figure>
										<figure className='white_img'>
											<img
												src='/static/images/uparrow.svg'
												alt=''
											/>
										</figure>
									</AccordionSummary>
									<AccordionDetails className='acc-dtls'>
										<Typography
											sx={{
												fontSize: '14px',
												color: '#565656',
											}}
										>
											a: {item.answer}
										</Typography>
									</AccordionDetails>
								</Accordion>
							);
						})}
					</div>

					<div className='faqdi'>
						<h3>{translation.Cms.shipping}</h3>
						{array3.map((item) => {
							return (
								<Accordion className='accd'>
									<AccordionSummary
										aria-controls='panel1a-content'
										id='panel1a-header'
									>
										<Typography>
											q: {item?.question}
										</Typography>
										<figure className='grey_img'>
											<img
												src='/static/images/down-grey.svg'
												alt=''
											/>
										</figure>
										<figure className='white_img'>
											<img
												src='/static/images/uparrow.svg'
												alt=''
											/>
										</figure>
									</AccordionSummary>
									<AccordionDetails className='acc-dtls'>
										<Typography
											sx={{
												fontSize: '14px',
												color: '#565656',
											}}
										>
											a: {item.answer}
										</Typography>
									</AccordionDetails>
								</Accordion>
							);
						})}
					</div>

					<div className='faqdi'>
						<h3>{translation.Cms.refund}</h3>
						{array4.map((item) => {
							return (
								<Accordion className='accd'>
									<AccordionSummary
										aria-controls='panel1a-content'
										id='panel1a-header'
									>
										<Typography>
											q: {item?.question}
										</Typography>
										<figure className='grey_img'>
											<img
												src='/static/images/down-grey.svg'
												alt=''
											/>
										</figure>
										<figure className='white_img'>
											<img
												src='/static/images/uparrow.svg'
												alt=''
											/>
										</figure>
									</AccordionSummary>
									<AccordionDetails className='acc-dtls'>
										<Typography
											sx={{
												fontSize: '14px',
												color: '#565656',
											}}
										>
											a: {item.answer}
										</Typography>
									</AccordionDetails>
								</Accordion>
							);
						})}
					</div>

					<div className='faqdi'>
						<h3>{translation.Cms.manage}</h3>
						{array5.map((item) => {
							return (
								<Accordion className='accd'>
									<AccordionSummary
										aria-controls='panel1a-content'
										id='panel1a-header'
									>
										<Typography>
											q: {item?.question}
										</Typography>
										<figure className='grey_img'>
											<img
												src='/static/images/down-grey.svg'
												alt=''
											/>
										</figure>
										<figure className='white_img'>
											<img
												src='/static/images/uparrow.svg'
												alt=''
											/>
										</figure>
									</AccordionSummary>
									<AccordionDetails className='acc-dtls'>
										<Typography
											sx={{
												fontSize: '14px',
												color: '#565656',
											}}
										>
											a: {item.answer}
										</Typography>
									</AccordionDetails>
								</Accordion>
							);
						})}
					</div>

					<div className='termsofuse'>
						<h5>{translation.Cms.termsofUse}</h5>
						<p>{translation.Cms.termsUse}</p>
						<p>{translation.Cms.sendEmail}</p>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default Faq;
