import { Dispatch, SetStateAction } from 'react';
import { Divider } from '@mui/material';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import useTranslation from '../../hooks/translation';
import cardData from '../../data/slider.json';
import { CouponsToResell } from '../../components';
import '../../pages/homepage/Home.scss';
import './BrowseCoupons.scss';

type Stepstype = {
	setStep: Dispatch<SetStateAction<number>>;
};
const BrowseCoupons = ({ setStep }: Stepstype) => {
	const translation = useTranslation() as any;

	const data = [
		{
			id: 1,
			img: '../../static/images/easy-n-effective.svg',
			title: translation.SellNow.features.titles.easy,
			description: translation.SellNow.features.description.easy,
		},
		{
			id: 2,
			img: '../../static/images/free.svg',
			title: translation.SellNow.features.titles.free,
			description: translation.SellNow.features.description.free,
		},
		{
			id: 3,
			img: '../../static/images/safe-n-secure.svg',
			title: translation.SellNow.features.titles.safe,
			description: translation.SellNow.features.description.safe,
		},
	];
	return (
		<section className='brws_cpns'>
			<div className='container mn_brws_cpns'>
				<div className='cpn_to_sell'>
					<div className='to-sell-heading'>
						<h2>{translation.SellNow.browseCoupons.toSell}</h2>
					</div>
					<Divider />
					<div className='container inr_to_sell'>
						<div className='cpn-img'>
							<figure>
								<img
									src='../../static/images/coupon-slant.svg'
									alt=''
								/>
							</figure>
						</div>

						<div className='inpt-fld-sec'>
							<p>{translation.SellNow.browseCoupons.warning}</p>
							<input
								type='text'
								placeholder={
									translation.SellNow.browseCoupons
										.placeholder
								}
							/>
						</div>

						<div className='btn-sec'>
							<div onClick={() => setStep(2)}>
								<figure>
									<img
										src='../../static/images/sell-img.svg'
										alt=''
									/>
								</figure>
								<p>{translation.SellNow.browseCoupons.btn}</p>
								<ArrowForwardIcon />
							</div>
						</div>
					</div>
				</div>
				<ul className='sell_cpn_ftrs'>
					{data.map((item) => {
						return (
							<li key={item.id}>
								<div>
									<div className='sell-ftrs-img'>
										<figure>
											<img src={item.img} alt='' />
										</figure>
									</div>
									<div className='sell-ftrs-hdng'>
										<h4>{item.title}</h4>
									</div>
								</div>
								<p>{item.description}</p>
							</li>
						);
					})}
				</ul>
				<div className='cpns_to_resell'>
					<h2>{translation.SellNow.resell.heading}</h2>
					<Divider />

					<div className='tp-cpns-lst'>
						{cardData.data?.length
							? cardData.data?.slice(0, 5).map((item) => {
									return (
										<div
											className='mn-cpns-lst'
											key={item.id}
										>
											<h2>{item.id}</h2>
											<CouponsToResell data={item} />
										</div>
									);
							  })
							: null}
					</div>
				</div>
			</div>
		</section>
	);
};

export default BrowseCoupons;
