import { TextField } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Yup from 'yup';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useFormik } from 'formik';
import useTranslation from '../hooks/translation';

type Stepstype = {
	setScreen: Dispatch<SetStateAction<number>>;
};

const ForgotPassword = ({ setScreen }: Stepstype) => {
	const translation = useTranslation() as any;
	const formik = useFormik({
		initialValues: {
			email: '',
		},
		validationSchema: Yup.object({
			email: Yup.string()
				.required('Email is required')
				// .email('Enter a valid email address'),
				.matches(
					/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
					'Enter a valid email address',
				),
		}),
		onSubmit: () => {
			formik.setSubmitting(true);
			setScreen(4);
		},
	});
	return (
		<div className='signUp-screen'>
			<h3>{translation.forgot.forgotpass}</h3>
			<p>{translation.forgot.text}</p>

			<form className='signup-form' onSubmit={formik.handleSubmit}>
				<div className='sign_bx'>
					<TextField
						className='txt-fld-auth'
						variant='outlined'
						placeholder={translation.signUp.email}
						id='email'
						{...formik.getFieldProps('email')}
						helperText={formik.touched.email && formik.errors.email}
						fullWidth
					/>
				</div>
				<button
					className='btn'
					type='submit'
					// onClick={() => setScreen(4)}
					disabled={formik.isSubmitting}
				>
					{translation.forgot.continue}
				</button>
				<button
					className='btn2'
					type='button'
					onClick={() => setScreen(2)}
				>
					{translation.forgot.cancel}
				</button>
				<p>
					{translation.login.signUp}
					<span onClick={() => setScreen(1)}>
						{translation.Globals.signUp}
					</span>
				</p>
			</form>
		</div>
	);
};

export default ForgotPassword;
