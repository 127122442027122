import {
	Autocomplete,
	Divider,
	InputAdornment,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import SouthIcon from '@mui/icons-material/South';

import { CouponCard, ProductFilters } from '../../components';
import useTranslation from '../../hooks/translation';
import cardData from '../../data/slider.json';
import filterData from '../../data/ProductFilter.json';
import { DiscountedServices } from '../../features';
import Layout from '../../layout';
import './Products.scss';

interface SortOption {
	id: number;
	option: string;
}
const Products = () => {
	const translation = useTranslation() as any;
	const sortOptions: SortOption[] = [
		{ id: 1, option: translation.productFilter.new },
		{ id: 2, option: translation.productFilter.bestSelling },
		{ id: 3, option: translation.productFilter.featured },
		{ id: 4, option: translation.productFilter.onSale },
		{ id: 5, option: translation.productFilter.rating },
	];

	const navigate = useNavigate();
	const [service, setService] = React.useState('');
	const [upgrade, setUpgrade] = useState(false);
	const [cities, setCities] = useState<SortOption[]>([]);

	const handleChange = (event: {
		target: { value: React.SetStateAction<string> };
	}) => {
		setService(event.target.value);
	};

	const navigateTo = () => {
		navigate('/details', { state: 'data' });
	};

	return (
		<Layout>
			<div className='prdcts-lst'>
				<div className='container mn-prdcts-lst'>
					<Divider />
					<div className='prdct-sectop'>
						<div className='lst-lft'>
							{/* {filterData.data?.length */}
							{/* ? filterData.data?.map((item) => { */}
							{/* return ( */}
							<div className='mn-cpns-lst'>
								<ProductFilters />
							</div>
							{/* ); */}
							{/* })
								: null} */}
						</div>
						<div className='lst-ryt'>
							<div className='lst-ryt-top'>
								<h4>{translation.productFilter.allProducts}</h4>
								<div className='align_dropdown'>
									<Select
										value={service}
										onChange={handleChange}
										displayEmpty
										inputProps={{
											'aria-label': 'Without label',
										}}
										className='srvc-sel'
									>
										<MenuItem className='srcv-lst' value=''>
											{translation.productFilter.service}
										</MenuItem>
										<MenuItem
											className='srcv-lst'
											value='service1'
										>
											{translation.productFilter.service}
										</MenuItem>
										<MenuItem
											className='srcv-lst'
											value='service2'
										>
											{translation.productFilter.service}
										</MenuItem>
									</Select>
									<Autocomplete
										multiple
										id='tags-outlined'
										options={sortOptions}
										disableCloseOnSelect
										getOptionLabel={(option) =>
											option?.option
										}
										className='fltr-optns'
										renderInput={(params) => (
											<TextField
												className='auto-inpt'
												{...params}
												placeholder={
													cities.length === 0
														? translation
																.productFilter
																.sort
														: ''
												}
												InputProps={{
													...params.InputProps,
													startAdornment: (
														<>
															<InputAdornment position='start'>
																<figure>
																	<img
																		src='/static/images/sort-icn.svg'
																		alt=''
																	/>
																</figure>
															</InputAdornment>
															{
																params
																	.InputProps
																	.startAdornment
															}
														</>
													),
												}}
											/>
										)}
										onChange={(event, value) =>
											setCities(value)
										}
									/>
								</div>
							</div>
							<div className='lst-ryt-btm'>
								{cardData.data?.length
									? cardData.data?.map((item) => {
											return (
												<div className='mn-cpns-lst'>
													<CouponCard
														key={item.id}
														upgrade={upgrade}
														setUpgrade={setUpgrade}
														type='sell'
														page=' auction'
														data={item}
														id={item.id}
														image={item.img}
														title={item.title}
														address={item.address}
														price={item.price}
														price2={item.price2}
														navigateTo={navigateTo}
													/>
												</div>
											);
									  })
									: null}
								<div className='prdct-sec-btm'>
									<h4>
										{translation.productFilter.show}
										<span>
											<SouthIcon />
										</span>
									</h4>
								</div>
							</div>
						</div>
					</div>
					<Divider />
				</div>
				<DiscountedServices />
			</div>
		</Layout>
	);
};

export default Products;
