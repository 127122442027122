import dayjs from 'dayjs';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Divider,
	FormControl,
	InputAdornment,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import useTranslation from '../../hooks/translation';
import { useLanguageContext } from '../../context/LanguageContext';

function Footer() {
	const navigate = useNavigate();
	const translation = useTranslation() as any;
	// const [lang, setLang] = React.useState('english');
	const { language, changeLanguage } = useLanguageContext();

	// const handleChange = (event: {
	// 	target: { value: React.SetStateAction<string> };
	// }) => {
	// 	setLang(event.target.value);
	// };

	return (
		<footer className='ft_r'>
			<Divider />
			<div className='top_ftr container'>
				<div className='tp_ftr_lft'>
					<p>{translation.Home.Footer.inform}</p>
					<div className='subscrb'>
						<TextField
							id='input-with-icon-textfield'
							className='txt_fld'
							placeholder={translation.Home.Footer.email}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<figure>
											<img
												src='../static/images/sms.svg'
												alt=''
											/>
										</figure>
									</InputAdornment>
								),
							}}
							variant='outlined'
						/>
						<button type='button' className='btn'>
							{translation.Globals.subscribe}
						</button>
					</div>
				</div>
				<div className='tp_ftr_ryt'>
					<h3>{translation.Home.Footer.join}</h3>
					<ul>
						<a
							href='https://www.facebook.com/'
							target='_blank'
							rel='noopener noreferrer'
						>
							<li>
								<figure>
									<img
										src='../static/images/facebook.svg'
										alt=''
									/>
								</figure>
							</li>
						</a>
						<a
							href='https://www.instagram.com/'
							target='_blank'
							rel='noopener noreferrer'
						>
							<li>
								<figure>
									<img
										src='../static/images/instagram.svg'
										alt=''
									/>
								</figure>
							</li>
						</a>
						<a
							href='https://twitter.com/Twitter'
							target='_blank'
							rel='noopener noreferrer'
						>
							<li>
								<figure>
									<img
										src='../static/images/twitter.svg'
										alt=''
									/>
								</figure>
							</li>
						</a>
						<a
							href='https://in.linkedin.com/'
							target='_blank'
							rel='noopener noreferrer'
						>
							<li>
								<figure>
									<img
										src='../static/images/linkedin.svg'
										alt=''
									/>
								</figure>
							</li>
						</a>
						<a
							href='https://www.youtube.com/'
							target='_blank'
							rel='noopener noreferrer'
						>
							<li>
								<figure>
									<img
										src='../static/images/youtube.svg'
										alt=''
									/>
								</figure>
							</li>
						</a>
					</ul>
				</div>
			</div>
			<Divider />
			<div className='container'>
				<ul className='mdl_ftr'>
					<li className='ftr_sec'>
						<figure className='logo'>
							<img src='./static/images/logo_coup.svg' alt='' />
						</figure>
						<p>{translation.Home.Footer.description}</p>
					</li>
					<li>
						<h4>{translation.Home.Footer.headings.know}</h4>

						<p>{translation.Home.Footer.content.about} </p>

						<p>{translation.Home.Footer.content.help}</p>
						<div
							onClick={() =>
								navigate('/how-it-works', { state: 'cms' })
							}
						>
							<p>{translation.Home.Footer.content.how}</p>
						</div>
						<div onClick={() => navigate('/faq', { state: 'cms' })}>
							<p>{translation.Home.Footer.content.faq}</p>
						</div>
					</li>
					<li>
						<h4>{translation.Home.Footer.headings.company}</h4>
						<div
							onClick={() =>
								navigate('/terms-and-condition', {
									state: 'cms',
								})
							}
						>
							<p>{translation.Home.Footer.content.terms}</p>
						</div>
						<div
							onClick={() =>
								navigate('/privacy-policy', { state: 'cms' })
							}
						>
							<p>{translation.Home.Footer.content.privacy}</p>
						</div>
						<p>{translation.Home.Footer.content.needHelp}</p>
						<a
							href='https://academie-coupify.com/'
							target='_blank'
							rel='noopener noreferrer'
							style={{ textDecoration: 'none' }}
						>
							<p>{translation.Home.Footer.content.workwithus}</p>
						</a>
					</li>
					<li className='ftr_sec2'>
						<h4>{translation.Home.Footer.headings.contact}</h4>
						<div
							onClick={() =>
								navigate('/contact-us', { state: 'cms' })
							}
						>
							<p>{translation.Home.Footer.headings.contact}</p>
						</div>
						<div>
							<figure>
								<img src='../static/images/call.svg' alt='' />
							</figure>
							<p>
								<a href='tel:+1 866-987-4149'>
									{translation.Home.Footer.content.phn}
								</a>
							</p>
						</div>

						<div>
							<figure>
								<img src='../static/images/sms.svg' alt='' />
							</figure>
							<p>
								<a
									href='mailto:info@coupify.ca'
									target='_blank'
									rel='noopener noreferrer'
								>
									{translation.Home.Footer.content.email}
								</a>
							</p>
						</div>
					</li>
					<li>
						<h4>{translation.Home.Footer.headings.language}</h4>
						<FormControl sx={{ width: '100%' }}>
							<Select
								value={language}
								onChange={(e) => changeLanguage(e.target.value)}
								displayEmpty
								inputProps={{ 'aria-label': 'Without label' }}
								className='lang_select'
								fullWidth
							>
								<MenuItem
									value='en'
									// value='english'
									className='mnu_itm_size'
								>
									{translation.Home.Footer.content.lang.en}
								</MenuItem>
								<MenuItem
									// onClick={() => changeLanguage('fr')}
									// value='french'
									value='fr'
									className='mnu_itm_size'
								>
									{translation.Home.Footer.content.lang.fr}
								</MenuItem>
							</Select>
						</FormControl>
					</li>
				</ul>
			</div>
			<Divider />
			<div className='btm_ftr container'>
				<p>
					©{translation.Globals.coupify} {dayjs().year()}.{' '}
					{translation.Home.Footer.content.rights}
				</p>
			</div>
		</footer>
	);
}

export default Footer;
