import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import EastIcon from '@mui/icons-material/East';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import useTranslation from '../../hooks/translation';
import { CouponCard } from '../../components';
import cardData from '../../data/slider.json';
import './Sliders.scss';
import { useLanguageContext } from '../../context/LanguageContext';

const PresaleCoupons = () => {
	const translation = useTranslation() as any;
	const [upgrade, setUpgrade] = useState(false);
	const { language, changeLanguage } = useLanguageContext();

	const navigate = useNavigate();
	const settings = {
		dots: false,
		arrows: true,
		infinite: false,
		speed: 500,
		slidesToShow: 4.25,
		slidesToScroll: 1,
		prevArrow: <KeyboardBackspaceIcon />,
		nextArrow: <EastIcon />,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 3.25,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 3.25,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 2.25,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1.25,
					slidesToScroll: 1,
				},
			},
		],
	};

	const navigateTo = () => {
		navigate('/details', { state: 'presaleBuy' });
	};

	return (
		<section className='presl_cpns'>
			<div className='container'>
				<div className='presl_hdng '>
					{language === 'fr' ? (
						<h2 className='red-hdng'>
							{' '}
							{translation.Home.presale.coupons}
							<span>{translation.Home.presale.presale}</span>
						</h2>
					) : (
						<h2>
							{translation.Home.presale.presale}
							<span className='undrln_hdng'>
								{' '}
								{translation.Home.presale.coupons}
							</span>
						</h2>
					)}

					<button
						className='btn2'
						type='button'
						onClick={() => navigate('/products')}
					>
						{translation.Globals.explore}
						{/* <ArrowForwardIcon /> */}
					</button>
				</div>
			</div>

			<div className='slider_outr'>
				<Slider {...settings}>
					{cardData.data?.length
						? cardData.data?.map((item) => {
								return (
									<CouponCard
										upgrade={upgrade}
										setUpgrade={setUpgrade}
										navigateTo={navigateTo}
										type='buy'
										page=' presale'
										key={item.id}
										data={item}
										id={item.id}
										image={item.img}
										title={item.title}
										address={item.address}
										price={item.price}
										price2={item.price2}
									/>
								);
						  })
						: null}
				</Slider>
			</div>
		</section>
	);
};

export default PresaleCoupons;
