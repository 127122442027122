import React, { useState } from 'react';
import { Divider } from '@mui/material';
import {
	BrowseCoupons,
	CouponDetails,
	CouponPrice,
	PersonalInformation,
	Stepper,
	Summary,
} from '../../features';
import Layout from '../../layout';

const SellCouponNow = () => {
	const [step, setStep] = useState<number>(1);

	const handleSteps = () => {
		switch (step) {
			case 1:
				return <BrowseCoupons setStep={setStep} />;
			case 2:
				return <CouponDetails setStep={setStep} />;
			case 3:
				return <CouponPrice setStep={setStep} />;
			case 4:
				return <PersonalInformation step={step} setStep={setStep} />;
			case 5:
				return <Summary />;

			default:
				return null;
		}
	};

	return (
		<Layout>
			<Stepper step={step} setStep={setStep} />
			{handleSteps()}
		</Layout>
	);
};

export default SellCouponNow;
