// eslint-disable-next-line import/no-extraneous-dependencies
import { useFormik } from 'formik';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { TextField } from '@mui/material';
import React, { Dispatch, SetStateAction, useState } from 'react';
import useTranslation from '../../hooks/translation';
import './PersonalInfo.scss';

type accountTab = {
	image: any;
	setImage: Dispatch<SetStateAction<any>>;
};

const PersonalInfo = ({ image, setImage }: accountTab) => {
	const translation = useTranslation() as any;
	const navigate = useNavigate();
	const [payment, setPayment] = useState(false);

	// const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
	const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files[0]) {
			setImage(URL.createObjectURL(event.target.files[0]));
		}
	};

	const formik = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			email: '',
		},
		validationSchema: Yup.object({
			firstName: Yup.string()
				.max(15, 'Must be 15 characters or less')
				.required('This field is required')
				.min(3, 'Must be atleast 3 characters'),
			lastName: Yup.string()
				.max(20, 'Must be 20 characters or less')
				.required('This field is required')
				.min(3, 'Must be atleast 3 characters'),
			email: Yup.string()
				// .email('Must be valid email address')
				.required('This field is required')
				.matches(
					/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
					'Must be a valid email.',
				),
		}),
		onSubmit: async () => {
			formik.setSubmitting(true);
			navigate('/');
			formik.setSubmitting(false);
		},
	});

	const addressformik = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			address: '',
			state: '',
			pinCode: '',
		},
		validationSchema: Yup.object({
			firstName: Yup.string()
				.max(15, 'Must be 15 characters or less')
				.required('This field is required')
				.min(3, 'Must be atleast 3 characters'),
			lastName: Yup.string()
				.max(20, 'Must be 20 characters or less')
				.required('This field is required')
				.min(3, 'Must be atleast 3 characters'),

			address: Yup.string()
				.required('This field is required.')
				.label('Address')
				.min(3, 'Must be atleast 3 characters'),
			state: Yup.string()
				.required('This field is required.')
				.label('State'),
			// .min(3, 'Must be atleast 3 characters'),
			country: Yup.string()
				.required('This field is required.')
				.label('City'),
			// .min(3, 'Must be atleast 3 characters'),
			pinCode: Yup.string()
				.required('This field is required.')
				.label('Pin code')
				.min(3, 'Pin code must be at least 3 characters.')
				.max(10, 'Pin code must be at most 10 digits.'),
		}),
		onSubmit: async () => {
			formik.setSubmitting(true);
			navigate('/');
			formik.setSubmitting(false);
		},
	});

	return (
		<div className='personalinfo'>
			<div className='profilediv'>
				<figure>
					<img
						src={
							image !== null && image !== undefined
								? image
								: '/static/images/user-img-ph.svg'
						}
						alt=''
					/>
				</figure>
				<label htmlFor='icon-button-file'>
					<div className='uploader'>
						<TextField
							inputProps={{
								accept: 'image/*',
							}}
							id='icon-button-file'
							type='file'
							sx={{ display: 'none' }}
							onChange={handleImageUpload}
						/>
						<figure>
							<img src='/static/images/camera.svg' alt='' />
						</figure>
						<input type='file' id='myfile' name='myfile' />
					</div>
				</label>

				<form onSubmit={formik.handleSubmit}>
					<div className='inputdiv'>
						<TextField
							className='info-inpt'
							placeholder={
								translation.PartnerWithUs.registration.firstName
							}
							id='firstName'
							type='text'
							{...formik.getFieldProps('firstName')}
							helperText={
								formik.touched.firstName &&
								formik.errors.firstName
							}
						/>
						<TextField
							className='info-inpt'
							placeholder={
								translation.PartnerWithUs.registration.lastName
							}
							id='lastName'
							type='text'
							{...formik.getFieldProps('lastName')}
							helperText={
								formik.touched.lastName &&
								formik.errors.lastName
							}
						/>
						<TextField
							className='info-inpt w_100'
							placeholder={translation.signUp.email}
							fullWidth
							id='email'
							type='text'
							{...formik.getFieldProps('email')}
							helperText={
								formik.touched.email && formik.errors.email
							}
						/>
						<div className='btndiv'>
							<button
								type='submit'
								className='btn'
								disabled={formik.isSubmitting}
							>
								{translation.Globals.undateInfo}
							</button>
						</div>
					</div>
				</form>

				{/* <div className='address'>
					<h2>{translation.account.delieryAddress}</h2>
					<ul>
						<li className='billing-adrss'>
							<div className='bill-ryt'>
								<h3>{translation.account.billingAddress}</h3>

								<p>
									3522 Interstate 75 Business Spur, Sault Ste.
									Marie, MI 49783 New York
								</p>
							</div>
							<div className='body'>
								<figure>
									<img src='/static/images/edit.svg' alt='' />
								</figure>
							</div>
						</li>
						<li className='shipping-adrss'>
							<div className='ship-ryt'>
								<Checkbox {...label} />
							</div>
							<div className='ship-mdl'>
								<h3>{translation.account.shippingaddress}</h3>

								<p>
									4299 Express Lane Sarasota, FL 34249 USA
									Phone: 1.941.227.4444 Sarasota
								</p>
							</div>
							<div className='body'>
								<figure>
									<img src='/static/images/edit.svg' alt='' />
								</figure>

								<figure>
									<img
										src='/static/images/delete.svg'
										alt=''
									/>
								</figure>
							</div>
						</li>
					</ul>
					<form onSubmit={addressformik.handleSubmit}>
						<div className='inputdiv2'>
							<TextField
								className='info-inpt'
								placeholder={
									translation.PartnerWithUs.registration
										.firstName
								}
								id='firstName'
								type='text'
								{...addressformik.getFieldProps('firstName')}
								helperText={
									addressformik.touched.firstName &&
									addressformik.errors.firstName
								}
							/>
							<TextField
								className='info-inpt'
								placeholder={
									translation.PartnerWithUs.registration
										.lastName
								}
								id='lastName'
								type='text'
								{...addressformik.getFieldProps('lastName')}
								helperText={
									addressformik.touched.lastName &&
									addressformik.errors.lastName
								}
							/>

							<TextField
								className='info-inpt'
								placeholder={
									translation.PartnerWithUs.registration
										.addressline
								}
								id='address'
								type='text'
								{...addressformik.getFieldProps('address')}
								helperText={
									addressformik.touched.address &&
									addressformik.errors.address
								}
							/>
							<TextField
								className='info-inpt'
								placeholder={
									translation.PartnerWithUs.registration
										.addressline2
								}
							/>
							<TextField
								className='info-inpt'
								placeholder={
									translation.PartnerWithUs.registration.city
								}
								id='state'
								type='text'
								{...addressformik.getFieldProps('state')}
								helperText={
									addressformik.touched.state &&
									addressformik.errors.state
								}
							/>
							<TextField
								className='info-inpt'
								placeholder={
									translation.PartnerWithUs.registration
										.postal
								}
								id='pinCode'
								type='text'
								{...addressformik.getFieldProps('pinCode')}
								helperText={
									addressformik.touched.pinCode &&
									addressformik.errors.pinCode
								}
							/>
							<div className='btndiv'>
								<button
									type='submit'
									className='btn'
									disabled={addressformik.isSubmitting}
								>
									{translation.Globals.saveaddress}
								</button>
							</div>
						</div>
					</form>
				</div> */}

				<div className='paymentmethod'>
					<h2>{translation.account.paymentmethod}</h2>
					<div className='paymain'>
						<div
							className='payoption'
							onClick={() => setPayment(!payment)}
						>
							<figure>
								<img src='/static/images/credit.svg' alt='' />
							</figure>
							<p>{translation.account.credit}</p>
						</div>
						<div
							className='payoption'
							onClick={() => setPayment(!payment)}
						>
							<figure>
								<img
									src='/static/images/paypal_icon.png'
									alt=''
								/>
							</figure>
							<p>{translation.account.paypal}</p>
						</div>
					</div>
				</div>

				{payment ? (
					<div className='cardInfo'>
						<TextField
							id='filled-basic'
							fullWidth
							placeholder={translation.account.cardNo}
						/>

						<TextField
							id='filled-basic'
							fullWidth
							placeholder={translation.account.namecard}
						/>
						<div className='cvv'>
							<TextField
								id='filled-basic'
								fullWidth
								placeholder={translation.account.expiry}
							/>
							<TextField
								id='filled-basic'
								fullWidth
								placeholder={translation.account.cvv}
							/>
						</div>

						<div className='btndiv'>
							<button type='button' className='btn'>
								{translation.Globals.done}
							</button>
						</div>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default PersonalInfo;
