import { MenuItem, Select } from '@mui/material';
import React from 'react';
import useTranslation from '../hooks/translation';

type OrderDetailtype = {
	img: string;
	name: string;
	description: string;
};
const OrderDetail = ({ img, name, description }: OrderDetailtype) => {
	const translation = useTranslation() as any;

	const [quantity, setQuantity] = React.useState('');

	const handleChange = (event: {
		target: { value: React.SetStateAction<string> };
	}) => {
		setQuantity(event.target.value);
	};

	return (
		<div className='ordr-dtl'>
			<div className='dtl-crd'>
				<div className='dtl-lft'>
					<figure>
						<img src={img} alt='' />
					</figure>
				</div>
				<div className='dtl-mddl'>
					<h4>{name}</h4>
					<p>{description}</p>
					<div className='dtlbtns-sec'>
						<button type='button'>
							{translation.account.cart.view}
						</button>
						<button type='button'>
							{translation.account.cart.delete}
						</button>
						<button type='button'>
							{translation.account.cart.share}
						</button>
					</div>
				</div>
				<div className='dtl-ryt'>
					<Select
						className='quantiooooo'
						value={quantity}
						onChange={handleChange}
						displayEmpty
						inputProps={{ 'aria-label': 'Without label' }}
					>
						<MenuItem value=''>
							<em>Qty 01</em>
						</MenuItem>
						<MenuItem value='1'>1</MenuItem>
						<MenuItem value='2'>2</MenuItem>
					</Select>
				</div>
			</div>
		</div>
	);
};

export default OrderDetail;
