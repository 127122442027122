import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Player } from '@lottiefiles/react-lottie-player';
import useTranslation from '../../hooks/translation';
import './HotDeals.scss';

const HotDeals = () => {
	const speed = parseFloat('1');
	const translation = useTranslation() as any;
	return (
		<section className='hot_dls'>
			<div className='container mn_dls'>
				<div className='dls_lft'>
					{/* <figure>
						<img alt='' src='../static/images/hot_deals.svg' />
					</figure> */}
					<Player
						src='https://lottie.host/7ab95340-fe8e-42e4-beca-f5be53afcbad/sNmDpKzJaw.json'
						className='hot_dl_lottie'
						autoplay
						loop
						background='transparent'
						speed={speed}
					/>
				</div>
				<div className='dls_ryt'>
					<h4>{translation.Home.HotDeals.presale}</h4>
					<h2>{translation.Home.HotDeals.services}</h2>
					<p>{translation.Home.HotDeals.description}</p>
					<div className='price'>
						<h2>{translation.Home.HotDeals.price}</h2>
						<s>
							<i>
								<p>{translation.Home.HotDeals.price2}</p>
							</i>
						</s>
					</div>
					<div className='frsh_dls'>
						<p>{translation.Home.HotDeals.deals}</p>
						<ul className='tym_lft'>
							<li>
								<h4>23</h4>
							</li>
							<span>:</span>
							<li>
								<h4>14</h4>
							</li>
							<span>:</span>
							<li>
								<h4>05</h4>
							</li>
							<span>:</span>
							<li>
								<h4>29</h4>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
	);
};

export default HotDeals;
