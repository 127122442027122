import React from 'react';
import Header from './header';
import Footer from './footer';
import './Layout.scss';

function Layout({ children }: { children: any }) {
	return (
		<div className='mn_lyout'>
			<Header />
			{children}
			<Footer />
		</div>
	);
}

export default Layout;
