import React from 'react';
import { SellWithCoupifyCard } from '../../components';
import useTranslation from '../../hooks/translation';

const SellWithCoupify = () => {
	const translation = useTranslation() as any;
	const data = [
		{
			id: 1,
			title: translation.SellCoupons.sellWithCoupify.title.freeToList,
			des: translation.SellCoupons.sellWithCoupify.description.freeToList,
		},
		{
			id: 2,
			title: translation.SellCoupons.sellWithCoupify.title.yourCoupon,
			des: translation.SellCoupons.sellWithCoupify.description.yourCoupon,
		},
		{
			id: 3,
			title: translation.SellCoupons.sellWithCoupify.title
				.secureTransaction,
			des: translation.SellCoupons.sellWithCoupify.description
				.secureTransaction,
		},
		{
			id: 4,
			title: translation.SellCoupons.sellWithCoupify.title.quick,
			des: translation.SellCoupons.sellWithCoupify.description.quick,
		},
		{
			id: 5,
			title: translation.SellCoupons.sellWithCoupify.title.care,
			des: translation.SellCoupons.sellWithCoupify.description.care,
		},
		{
			id: 6,
			title: translation.SellCoupons.sellWithCoupify.title.largestMarket,
			des: translation.SellCoupons.sellWithCoupify.description
				.largestMarket,
		},
	];
	return (
		<section className='sell-wt_cpfy_main'>
			<h2>
				{translation.SellCoupons.sellWithCoupify.whyCoupify}
				<figure>
					<img src='../../static/images/logo_coup.svg' alt='' />
				</figure>
				<span>?</span>
			</h2>
			<div className='sellcpn-wrk-cntnt '>
				{data?.length
					? data?.map((item) => {
							return (
								<SellWithCoupifyCard
									key={item.id}
									data={item}
								/>
							);
					  })
					: null}
			</div>
		</section>
	);
};

export default SellWithCoupify;
