import { Route, Routes } from 'react-router-dom';
import Pages from './pages';

const Router = () => {
	return (
		<Routes>
			<Route path='/' element={<Pages.HomePage />} />
			<Route path='/sell-coupons' element={<Pages.SellCoupons />} />
			<Route path='/sell-coupon-now' element={<Pages.SellCouponNow />} />
			<Route path='/partner-with-us' element={<Pages.PartnerWithUs />} />
			<Route
				path='/partner-register'
				element={<Pages.PartnerRegister />}
			/>
			<Route path='/contact-us' element={<Pages.ContactUs />} />
			<Route path='/privacy-policy' element={<Pages.PrivacyPolicy />} />
			<Route path='/faq' element={<Pages.Faq />} />
			<Route path='/how-it-works' element={<Pages.HowItWorks />} />
			<Route
				path='/terms-and-condition'
				element={<Pages.TermsAndCondition />}
			/>
			<Route path='/pricing-model' element={<Pages.PricingModel />} />
			<Route path='/authentication' element={<Pages.Authentication />} />
			<Route path='/products' element={<Pages.Products />} />
			<Route path='/discounted-services' element={<Pages.Products />} />
			<Route path='/coupons' element={<Pages.Products />} />
			<Route path='/product-detail' element={<Pages.ProductDetail />} />
			<Route
				path='/order-successful'
				element={<Pages.OrderSuccessfull />}
			/>
			<Route path='/account' element={<Pages.Account />} />
			<Route
				path='/book-appointment'
				element={<Pages.BookAppointment />}
			/>
			<Route path='/cart' element={<Pages.Cart />} />
			<Route path='/presale-coupons' element={<Pages.PresaleCoupons />} />
			<Route path='/coupon-saving' element={<Pages.CouponSaving />} />
			<Route path='/details' element={<Pages.PresaleDetails />} />
			<Route path='/product2' element={<Pages.Product2 />} />
		</Routes>
	);
};

export default Router;
