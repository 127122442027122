import { useNavigate } from 'react-router-dom';
import { Dispatch, SetStateAction, useState } from 'react';
import {
	CouponCard,
	ForgotPassword,
	Login,
	SignUp,
	UpdatePassword,
} from '../../components';
import useTranslation from '../../hooks/translation';
import cardData from '../../data/slider.json';

type Stepstype = {
	step: number;
	setStep: Dispatch<SetStateAction<number>>;
};
const PersonalInformation = ({ step, setStep }: Stepstype) => {
	const translation = useTranslation() as any;

	const [upgrade, setUpgrade] = useState(false);
	const [screen, setScreen] = useState<number>(1);

	const navigate = useNavigate();
	const navigateTo = () => {
		navigate('/');
	};
	const handleScreens = () => {
		switch (screen) {
			case 1:
				return (
					<SignUp
						step={step}
						setStep={setStep}
						setScreen={setScreen}
					/>
				);
			case 2:
				return (
					<Login
						setScreen={setScreen}
						step={step}
						setStep={setStep}
					/>
				);
			case 3:
				return <ForgotPassword setScreen={setScreen} />;
			case 4:
				return <UpdatePassword setScreen={setScreen} />;

			default:
				return null;
		}
	};
	return (
		<section className='prsnl-info'>
			<div className='container mn_cpn-dtls'>
				<div className='cpn-details-ryt'>
					<div>
						{cardData.data?.length
							? cardData.data?.slice(0, 1).map((item) => {
									return (
										<CouponCard
											upgrade={upgrade}
											setUpgrade={setUpgrade}
											type='no btns'
											page=' sellCouponNow'
											key={item.id}
											data={item}
											id={item.id}
											image={item.img}
											title={item.title}
											address={item.address}
											price={item.price}
											price2={item.price2}
											navigateTo={navigateTo}
										/>
									);
							  })
							: null}
					</div>
					<p className='dsclmr'>
						{translation.SellNow.personalInformation.disclaimer}
					</p>
				</div>
				<div className='cpn-details-lft'>
					<div>
						<h2>
							{translation.SellNow.personalInformation.details}
						</h2>
					</div>
					<div className='mn-dtls-lft'>
						<div className='signUp'>
							{/* <SignUp
								step={step}
								setStep={setStep}
								setScreen={setScreen}
							/> */}
							{handleScreens()}
						</div>
						<div className='cpn-dtl-btns'>
							<button
								className='btn2'
								type='button'
								onClick={() => setStep(3)}
							>
								{translation.Globals.back}
							</button>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default PersonalInformation;
