import React from 'react';
import {
	AuctionCoupons,
	BuyAndSell,
	DiscountedServices,
	Features,
	HotDeals,
	Introduction,
	Offers,
	PresaleCoupons,
} from '../../features';
import Categories from '../../features/home/Categories';
import Layout from '../../layout';

const HomePage = () => {
	return (
		<Layout>
			<Introduction />
			<Offers />
			<Categories />
			<PresaleCoupons />
			<HotDeals />
			<AuctionCoupons />
			<BuyAndSell />
			<DiscountedServices />
			<Features />
		</Layout>
	);
};

export default HomePage;
