import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useTranslation from '../../hooks/translation';
import Layout from '../../layout';
import './OrderSuccess.scss';

const OrderSuccessfull = () => {
	const translation = useTranslation() as any;
	const navigate = useNavigate();

	useEffect(() => {
		setTimeout(() => {
			navigate('/');
		}, 3000);
	});

	return (
		<Layout>
			<div className='mn-ordr-sccs'>
				<div className='orderbnr'>
					<figure>
						<img src='/static/images/orderCircle.svg' alt='' />
					</figure>
				</div>
				<div className='orderstatus container'>
					<h2>{translation.orderplace.confirmOrder}</h2>
					<h3>
						{translation.orderplace.couponNo} :{' '}
						<span>FEGHRE1858</span>
					</h3>
					<p>{translation.orderplace.couponemail} </p>
					<div className='btndiv'>
						<div className='btn-scts-wdth'>
							<button className='btn2' type='button'>
								<figure>
									<img src='/static/images/sms.svg' alt='' />
								</figure>
								{translation.Globals.sharecoupon}
							</button>
						</div>
						<div className='btn-scts-wdth'>
							<button className='btn' type='button'>
								<figure>
									<img
										src='/static/images/phone-icn.svg'
										alt=''
									/>
								</figure>
								{translation.Globals.sendCoupon}
							</button>
						</div>
						<div className='btn-scts-wdth'>
							<p>{translation.orderplace.text}</p>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default OrderSuccessfull;
