import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { CouponCard } from '../../components';
import useTranslation from '../../hooks/translation';
import cardData from '../../data/slider.json';
import './MyOrder.scss';

const Wishlist = () => {
	const translation = useTranslation() as any;
	const [upgrade, setUpgrade] = useState(false);
	const navigate = useNavigate();
	const navigateTo = () => {
		navigate('/details');
	};
	return (
		<div className='wishlist'>
			<div className='cardsdiv'>
				{cardData.data?.length
					? cardData.data?.slice(0, 3).map((item) => {
							return (
								<CouponCard
									upgrade={upgrade}
									setUpgrade={setUpgrade}
									type='sell'
									page=''
									key={item.id}
									data={item}
									id={item.id}
									image={item.img}
									title={item.title}
									address={item.address}
									price={item.price}
									price2={item.price2}
									navigateTo={navigateTo}
								/>
							);
					  })
					: null}
			</div>
		</div>
	);
};

export default Wishlist;
