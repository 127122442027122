import { Visibility, VisibilityOff } from '@mui/icons-material';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Yup from 'yup';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useFormik } from 'formik';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import React, { Dispatch, SetStateAction, useState } from 'react';
import useTranslation from '../hooks/translation';

type Stepstype = {
	setScreen: Dispatch<SetStateAction<number>>;
};
const UpdatePassword = ({ setScreen }: Stepstype) => {
	const translation = useTranslation() as any;
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setshowConfirmPassword] = useState(false);

	const formik = useFormik({
		initialValues: {
			password: '',
			confirmPassword: '',
		},
		validationSchema: Yup.object({
			password: Yup.string()
				.required('This field is required')
				.matches(
					/(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
					'Password must contain atleast 8 characters, one uppercase, one lowercase, one number and one special character. ',
				),
			confirmPassword: Yup.string()
				.oneOf([Yup.ref('password')], 'Both passwords should match.')
				.required('This field is required.'),
		}),
		onSubmit: () => {
			formik.setSubmitting(true);
			setScreen(2);
		},
	});

	return (
		<div className='signUp-screen'>
			<h3>{translation.updatePassword.update}</h3>
			<p>{translation.updatePassword.text}</p>

			<form className='signup-form' onSubmit={formik.handleSubmit}>
				<div className='sign_bx'>
					<TextField
						className='txt-fld-auth'
						variant='outlined'
						placeholder={translation.updatePassword.newPass}
						id='password'
						{...formik.getFieldProps('password')}
						helperText={
							formik.touched.password && formik.errors.password
						}
						fullWidth
						type={showPassword ? 'text' : 'password'}
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton
										aria-label='toggle password visibility'
										onClick={() =>
											setShowPassword(!showPassword)
										}
										onMouseDown={(event) =>
											event.preventDefault()
										}
										edge='end'
									>
										{showPassword ? (
											<Visibility />
										) : (
											<VisibilityOff />
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					<TextField
						className='txt-fld-auth'
						variant='outlined'
						id='confirmPassword'
						{...formik.getFieldProps('confirmPassword')}
						helperText={
							formik.touched.confirmPassword &&
							formik.errors.confirmPassword
						}
						placeholder={translation.updatePassword.confirmPass}
						fullWidth
						type={showConfirmPassword ? 'text' : 'password'}
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton
										aria-label='toggle password visibility'
										onClick={() =>
											setshowConfirmPassword(
												!showConfirmPassword,
											)
										}
										onMouseDown={(event) =>
											event.preventDefault()
										}
										edge='end'
									>
										{showConfirmPassword ? (
											<Visibility />
										) : (
											<VisibilityOff />
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				</div>

				<button
					className='btn'
					type='submit'
					disabled={formik.isSubmitting}
					// onClick={() => setScreen(2)}
				>
					{translation.forgot.continue}
				</button>

				<button
					className='btn2'
					type='button'
					onClick={() => setScreen(3)}
				>
					{translation.forgot.cancel}
				</button>
			</form>
		</div>
	);
};
export default UpdatePassword;
