import React from 'react';
import useTranslation from '../../hooks/translation';
import Layout from '../../layout';
import './PrivacyPolicy.scss';

const PrivacyPolicy = () => {
	const translation = useTranslation() as any;
	return (
		<Layout>
			<div className='contactmain'>
				<div className='privacybnr'>
					<div className='container'>
						<div className='ppbanner'>
							<div className='ppleft'>
								<h1>{translation.Cms.privacy}</h1>
							</div>
							<div className='ppright'>
								<figure>
									<img
										src='/static/images/privacybnr.svg'
										alt=''
									/>
								</figure>
							</div>
						</div>
					</div>
				</div>
				<div className='container'>
					<div className='policycontent upd_text'>
						<h5 className='upd_te'>
							{translation.Cms.lastUpdated}
						</h5>
						<p>{translation.Cms.lastUpPara}</p>
						<p>{translation.Cms.lastUpPara2}</p>

						<h5>{translation.Cms.heading2}</h5>
						<ul className='dots_para'>
							<li>
								<span>•</span>
								<p>{translation.Cms.para3}</p>
							</li>
							<li>
								<span>•</span>
								<p>{translation.Cms.para4}</p>
							</li>
							<li>
								<span>•</span>
								<p>{translation.Cms.para5}</p>
							</li>
							<li>
								<span>•</span>
								<p>{translation.Cms.para6}</p>
							</li>
							<li>
								<span>•</span>
								<p>{translation.Cms.para7}</p>
							</li>
							<li>
								<span>•</span>
								<p>{translation.Cms.para8}</p>
							</li>
							<li>
								<span>•</span>
								<p>{translation.Cms.para9}</p>
							</li>
						</ul>

						<h5>Customer Support</h5>
						<h6>{translation.Cms.support1}</h6>
						<h6>{translation.Cms.support2}</h6>
						<h6>{translation.Cms.support3}</h6>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default PrivacyPolicy;
