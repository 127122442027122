import { useNavigate } from 'react-router-dom';
import React from 'react';
import useTranslation from '../../hooks/translation';
import { useLanguageContext } from '../../context/LanguageContext';

const HowItWorks = () => {
	const translation = useTranslation() as any;
	const navigate = useNavigate();
	const { language, changeLanguage } = useLanguageContext();
	const array = [
		{
			id: 1,
			img: '/static/images/workpic3.png',
			text: translation.PartnerWithUs.howDoescost.work1,
		},
		{
			id: 2,
			img: '/static/images/workpic1.png',
			text: translation.PartnerWithUs.howDoescost.work2,
		},
		{
			id: 3,
			img: '/static/images/workpic2.png',
			text: translation.PartnerWithUs.howDoescost.work3,
		},
	];

	const array2 = [
		{
			id: 1,
			no: ' 01',
			text: translation.PartnerWithUs.profitable.option1,
		},
		{
			id: 1,
			no: '02',
			text: translation.PartnerWithUs.profitable.option2,
		},
		{
			id: 1,
			no: '03',
			text: translation.PartnerWithUs.profitable.option3,
		},
	];
	return (
		<>
			<section className='howitworks'>
				<div className='howitworks_main'>
					<div className='howitwork_lhs'>
						<figure>
							<img src='/static/images/work.png' alt='' />
						</figure>
					</div>
					<div className='howitwrk'>
						<h3>{translation.PartnerWithUs.howDoescost.works}</h3>

						<ul className='worksoption'>
							{array.map((item) => {
								return (
									<li key={item.id}>
										<div className='womain'>
											<div className='woleft'>
												<figure>
													<img
														src={item.img}
														alt=''
													/>
												</figure>
											</div>
											<div className='woright'>
												<p>{item.text}</p>
											</div>
										</div>
									</li>
								);
							})}
						</ul>
						<p>
							{
								translation.PartnerWithUs.howDoescost
									.workDescription
							}
						</p>
						<button
							style={{ marginTop: '16px' }}
							className='btn'
							type='button'
							onClick={() => navigate('/partner-register')}
						>
							{translation.Globals.setStarted}
						</button>
					</div>
				</div>
			</section>

			<section className='profitable'>
				<div className='container'>
					<div className='profit'>
						<div className='pleft'>
							<h3>
								{translation.PartnerWithUs.profitable.title}
							</h3>
							<p>
								{
									translation.PartnerWithUs.profitable
										.description
								}
							</p>
							<button
								style={{ marginTop: '16px' }}
								className='btn'
								type='button'
								onClick={() => navigate('/partner-register')}
							>
								{translation.Globals.setStarted}
							</button>
						</div>
						<div className='pright'>
							<img
								className='vec_tor'
								src='/static/images/vec_tor.png'
								alt=''
							/>
							<ul>
								{array2.map((item) => {
									return (
										<li>
											<h2>{item?.no}</h2>
											<p>{item.text}</p>
										</li>
									);
								})}
							</ul>
							<img
								className='triangle'
								src='/static/images/triangle.png'
								alt=''
							/>
						</div>
					</div>
				</div>
			</section>

			<section className='partnercard'>
				<div className='container'>
					<ul className='ptnrcard'>
						<li>
							<figure>
								{language === 'fr' ? (
									<img
										src='/static/images/publish-fr.svg'
										alt=''
									/>
								) : (
									<img
										src='/static/images/publish.png'
										alt=''
									/>
								)}
							</figure>
						</li>
						<li>
							<figure>
								{language === 'fr' ? (
									<img
										src='/static/images/e-tickets-fr.png'
										alt=''
									/>
								) : (
									<img
										src='/static/images/ticket.png'
										alt=''
									/>
								)}
							</figure>
						</li>
						<li>
							<figure>
								{language === 'fr' ? (
									<img
										src='/static/images/track-fr.png'
										alt=''
									/>
								) : (
									<img
										src='/static/images/track.png'
										alt=''
									/>
								)}
							</figure>
						</li>
					</ul>
					{/* <div
						style={{
							display: 'flex',
							justifyContent: 'flex-end',
						}}
					>
						<button
							className='btn'
							type='button'
							onClick={() => navigate('/partner-register')}
						>
							{translation.Globals.setStarted}
						</button>
					</div> */}
				</div>
			</section>
		</>
	);
};

export default HowItWorks;
