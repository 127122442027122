import { Divider } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import useTranslation from '../../hooks/translation';

// type Stepstype = {
// 	setStep: Dispatch<SetStateAction<number>>;
// };
const Summary = () => {
	const translation = useTranslation() as any;
	const navigate = useNavigate();
	return (
		<div className='summary-sec'>
			<div className='summary-left'>
				<figure>
					<img src='../static/images/circle-img.svg' alt='' />
				</figure>
			</div>
			<div className='summry-ryt'>
				<h2>{translation.SellNow.summary.congratulations}</h2>
				<h4>{translation.SellNow.summary.shortlyOnSale}</h4>
				<Divider />
				<div className='scl-media'>
					<h6>{translation.SellNow.summary.sclMedia}</h6>
					<div className='scl-icns-lst'>
						<a
							href='https://www.facebook.com/'
							target='_blank'
							rel='noopener noreferrer'
						>
							<div className='scl-icns'>
								<figure>
									<img
										src='../static/images/facebook.svg'
										alt=''
									/>
								</figure>
							</div>
						</a>
						<a
							href='https://www.instagram.com/'
							target='_blank'
							rel='noopener noreferrer'
						>
							<div className='scl-icns'>
								<figure>
									<img
										src='../static/images/instagram.svg'
										alt=''
									/>
								</figure>
							</div>
						</a>
						<a
							href='https://twitter.com/Twitter'
							target='_blank'
							rel='noopener noreferrer'
						>
							<div className='scl-icns'>
								<figure>
									<img
										src='../static/images/twitter.svg'
										alt=''
									/>
								</figure>
							</div>
						</a>
						<a
							href='https://in.linkedin.com/'
							target='_blank'
							rel='noopener noreferrer'
						>
							<div className='scl-icns'>
								<figure>
									<img
										src='../static/images/linkedin.svg'
										alt=''
									/>
								</figure>
							</div>
						</a>
						<a
							href='https://telegram.org/'
							target='_blank'
							rel='noopener noreferrer'
						>
							<div className='scl-icns'>
								<figure>
									<img
										src='../static/images/telegram.svg'
										alt=''
									/>
								</figure>
							</div>
						</a>
					</div>
				</div>
				<div className='cpy-link-sec'>
					<h6>{translation.SellNow.summary.copyLink}</h6>
					<div className='drb_link'>
						<p>{translation.SellNow.summary.link}</p>
						<p className='pink_text'>
							{translation.SellNow.summary.copy}
						</p>
					</div>
					<div className='usr-pnl-btn'>
						<button
							className='btn2'
							type='button'
							onClick={() => navigate('/account')}
						>
							{translation.SellCoupons.btn_txt2}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Summary;
