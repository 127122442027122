import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useNavigate } from 'react-router-dom';
import { Player } from '@lottiefiles/react-lottie-player';
import useTranslation from '../../hooks/translation';

const HowDoesCost = () => {
	const translation = useTranslation() as any;
	const navigate = useNavigate();
	const speed = parseFloat('1');
	return (
		<section className='costmain'>
			<div className='container'>
				<div className='costm'>
					<div className='cleft'>
						<div className='heading'>
							<h3>
								{translation.PartnerWithUs.howDoescost.cost}
							</h3>
							<h2>$0</h2>
						</div>
						<p>
							{translation.PartnerWithUs.howDoescost.description}
						</p>
						<button
							style={{ marginTop: '16px' }}
							className='btn'
							type='button'
							onClick={() => navigate('/partner-register')}
						>
							{translation.Globals.setStarted}
						</button>
					</div>
					<div className='cright'>
						<Player
							src='	https://lottie.host/75c14e02-41cc-41e8-9dab-71c800437a24/SXT39SA6kl.json'
							className='hw-cst-lottie'
							autoplay
							loop
							background='transparent'
							speed={speed}
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default HowDoesCost;
