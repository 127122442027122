import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Checkbox, IconButton, InputAdornment, TextField } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Yup from 'yup';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useFormik } from 'formik';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useTranslation from '../hooks/translation';

type Stepstype = {
	setScreen: Dispatch<SetStateAction<number>>;
	setStep: Dispatch<SetStateAction<number>>;
	step: number;
};
const Login = ({ setScreen, setStep, step }: Stepstype) => {
	const navigate = useNavigate();
	const translation = useTranslation() as any;
	const [showPassword, setShowPassword] = useState(false);
	const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		validationSchema: Yup.object({
			email: Yup.string()
				.required('Email is required')
				// .email('Enter a valid email address'),
				.matches(
					/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
					'Enter a valid email address',
				),
			password: Yup.string()
				.required('Password is required')
				.matches(
					/(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
					'Password must contain atleast 8 characters, one uppercase, one lowercase, one number and one special character. ',
				),
		}),
		onSubmit: () => {
			formik.setSubmitting(true);
			if (step === 4) {
				setStep(5);
			} else {
				navigate('/');
			}
		},
	});
	return (
		<div className='signUp-screen'>
			<h3>{translation.login.login}</h3>
			<p>
				<b>{translation.login.welcome}</b>
				<span className='grey_text'>
					{translation.login.enterDetails}
				</span>
			</p>
			<div className='scl-login'>
				<figure>
					<img src='../static/images/google-login.svg' alt='' />
				</figure>
				<figure>
					<img src='../static/images/fb-login.svg' alt='' />
				</figure>
			</div>
			<div className='or_opt'>
				<hr />
				<p>{translation.signUp.or}</p>
			</div>
			<form className='signup-form' onSubmit={formik.handleSubmit}>
				<div className='sign_bx'>
					<TextField
						className='txt-fld-auth'
						variant='outlined'
						placeholder={translation.signUp.email}
						id='email'
						{...formik.getFieldProps('email')}
						helperText={formik.touched.email && formik.errors.email}
						fullWidth
					/>
					<TextField
						className='txt-fld-auth'
						variant='outlined'
						placeholder={translation.signUp.createPassword}
						id='password'
						{...formik.getFieldProps('password')}
						helperText={
							formik.touched.password && formik.errors.password
						}
						fullWidth
						type={showPassword ? 'text' : 'password'}
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton
										aria-label='toggle password visibility'
										onClick={() =>
											setShowPassword(!showPassword)
										}
										onMouseDown={(event) =>
											event.preventDefault()
										}
										edge='end'
									>
										{showPassword ? (
											<Visibility />
										) : (
											<VisibilityOff />
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				</div>
				<div className='frgt-sec'>
					<div className='chckbx-sec'>
						<Checkbox {...label} />
						<p>{translation.login.remember}</p>
					</div>
					<div onClick={() => setScreen(3)}>
						<p className='frgt_pass'>{translation.login.forgot}</p>
					</div>
				</div>
				<button
					className='btn'
					type='submit'
					disabled={formik.isSubmitting}
				>
					{translation.Globals.logIn}
				</button>
				<p>
					{translation.login.signUp}
					<span onClick={() => setScreen(1)}>
						{translation.Globals.signUp}
					</span>
				</p>
			</form>
		</div>
	);
};

export default Login;
