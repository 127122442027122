import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Player } from '@lottiefiles/react-lottie-player';
import useTranslation from '../../hooks/translation';
import './HotDeals.scss';

const BuyAndSell = () => {
	const translation = useTranslation() as any;
	const speed = parseFloat('1');
	return (
		<section className='buy_n_sell'>
			<div className='container mn_sctn'>
				<div className='sctn_lft'>
					<h2>{translation.Home.BuyAndSell.BuyAndSell}</h2>
					<p>{translation.Home.BuyAndSell.description}</p>
					<button type='button' className='btn2'>
						{translation.Home.BuyAndSell.startNow}
					</button>
				</div>

				<div className='sctn_ryt'>
					<Player
						// src='https://lottie.host/a55c49c8-22f5-4e9d-a257-6796cfae8377/ju9xKKkcL4.json'
						src='https://lottie.host/2f17c0e6-fbef-4f00-9216-1644d5048c41/Rv8VWftDDd.json'
						className='buy-nw-lottie'
						autoplay
						loop
						background='transparent'
						speed={speed}
					/>
				</div>
			</div>
		</section>
	);
};

export default BuyAndSell;
