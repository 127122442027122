import React from 'react';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Player } from '@lottiefiles/react-lottie-player';
import useTranslation from '../../hooks/translation';
import '../../pages/homepage/Home.scss';
import { useLanguageContext } from '../../context/LanguageContext';

const Introduction = () => {
	const navigate = useNavigate();
	const translation = useTranslation() as any;
	const speed = parseFloat('1');
	const { language, changeLanguage } = useLanguageContext();
	return (
		<section className='bnr pt'>
			<div className='mn_bnr'>
				<div className='bnr_lft'>
					{language === 'fr' ? (
						<h1 className='fr_hdng'>
							{translation.Home.Banner.save}{' '}
							<span className='undrln_hdng_fr'>
								{' '}
								{translation.Home.Banner.coupify}
							</span>
						</h1>
					) : (
						<h1>
							{translation.Home.Banner.save}
							{'  '}
							<span className='undrln_hdng'>
								{translation.Home.Banner.percent}
							</span>{' '}
							{translation.Home.Banner.coupify}
						</h1>
					)}

					<p> {translation.Home.Banner.des}</p>
					<div className='explr'>
						<button className='btn' type='button'>
							{translation.Home.Banner.explore}
						</button>
						<button
							className='btn2'
							type='button'
							onClick={() => navigate('/partner-with-us')}
						>
							{' '}
							{translation.Home.Banner.partner}
						</button>
					</div>
				</div>
				<div className='bnr_ryt'>
					{/* <figure>
						<img src='../static/images/bnr_img.svg' alt='' />
					</figure> */}
					<Player
						src='https://lottie.host/f58b2083-a370-443b-b3ac-26a0ffd3bc6a/0ThnwD5pSO.json'
						className='hm-bnr-lottie'
						autoplay
						loop
						background='transparent'
						speed={speed}
					/>
				</div>
			</div>
		</section>
	);
};

export default Introduction;
