import {
	Checkbox,
	IconButton,
	InputAdornment,
	Step,
	TextField,
} from '@mui/material';
import {
	SettingsInputSvideoSharp,
	Visibility,
	VisibilityOff,
} from '@mui/icons-material';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Yup from 'yup';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useFormik } from 'formik';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useTranslation from '../hooks/translation';

type Stepstype = {
	setScreen: Dispatch<SetStateAction<number>>;
	setStep: Dispatch<SetStateAction<number>>;
	step: number;
};
const SignUp = ({ step, setStep, setScreen }: Stepstype) => {
	const navigate = useNavigate();
	const translation = useTranslation() as any;
	const [showPassword, setShowPassword] = useState(false);
	const [checked, setChecked] = useState(false);

	const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

	const formik = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			email: '',
			password: '',
		},
		validationSchema: Yup.object({
			firstName: Yup.string()
				.min(3, 'Name must be atleast 3 characters')
				.required('First name is required'),
			lastName: Yup.string()
				.min(3, 'Name must be atleast 3 characters')
				.required('Last name is required'),
			email: Yup.string()
				.required('Email is required')
				.matches(
					/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
					'Enter a valid email address',
				),
			password: Yup.string()
				.required('Password is required')
				.matches(
					/(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
					'Password must contain atleast 8 characters, one uppercase, one lowercase, one number and one special character. ',
				),
		}),

		onSubmit: () => {
			formik.setSubmitting(true);
			if (checked) {
				if (step === 4) {
					setStep(5);
				} else {
					navigate('/');
				}
			} else {
				// eslint-disable-next-line no-console
				alert('Please select terms and conditions');
			}
		},
	});

	return (
		<div className='signUp-screen'>
			<h3>{translation.signUp.createAccount}</h3>
			<p>{translation.signUp.sigupSocials}</p>
			<div className='scl-login'>
				<figure>
					<img src='../static/images/google-login.svg' alt='' />
				</figure>
				<figure>
					<img src='../static/images/fb-login.svg' alt='' />
				</figure>
			</div>
			<div className='or_opt'>
				<hr />
				<p>{translation.signUp.or}</p>
			</div>
			<form onSubmit={formik.handleSubmit} className='signup-form'>
				<div className='sign_bx'>
					<div className='names-sec'>
						<TextField
							id='firstName'
							variant='outlined'
							placeholder={translation.signUp.fname}
							className='txt-fld-auth'
							{...formik.getFieldProps('firstName')}
							helperText={
								formik.touched.firstName &&
								formik.errors.firstName
							}
							fullWidth
						/>

						<TextField
							id='filled-basic'
							variant='outlined'
							className='txt-fld-auth'
							placeholder={translation.signUp.lname}
							{...formik.getFieldProps('lastName')}
							helperText={
								formik.touched.lastName &&
								formik.errors.lastName
							}
							fullWidth
						/>
					</div>
					<TextField
						// className='w_100'

						variant='outlined'
						className='txt-fld-auth'
						placeholder={translation.signUp.email}
						fullWidth
						id='email'
						{...formik.getFieldProps('email')}
						helperText={formik.touched.email && formik.errors.email}
					/>
					<TextField
						// className='w_100'
						variant='outlined'
						placeholder={translation.signUp.createPassword}
						fullWidth
						className='txt-fld-auth'
						id='password'
						{...formik.getFieldProps('password')}
						helperText={
							formik.touched.password && formik.errors.password
						}
						type={showPassword ? 'text' : 'password'}
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton
										aria-label='toggle password visibility'
										onClick={() =>
											setShowPassword(!showPassword)
										}
										onMouseDown={(event) =>
											event.preventDefault()
										}
										edge='end'
									>
										{showPassword ? (
											<Visibility />
										) : (
											<VisibilityOff />
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				</div>
				<div className='chckbx-sec'>
					<Checkbox {...label} onClick={() => setChecked(!checked)} />
					<p>
						{translation.signUp.agree}
						<span>{translation.signUp.termsofsrvc}</span>
					</p>
				</div>

				<button
					className='btn'
					type='submit'
					// disabled={formik.isSubmitting}
				>
					{translation.Globals.signUp}
				</button>

				<p className='hav_acn'>
					{translation.signUp.haveAccount}
					<span onClick={() => setScreen(2)}>
						{' '}
						{translation.Globals.logIn}
					</span>
				</p>
			</form>
		</div>
	);
};

export default SignUp;
