import { Divider } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useTranslation from '../../hooks/translation';
import Layout from '../../layout';
import './ProductDetail.scss';

const ProductDetail = () => {
	const translation = useTranslation() as any;
	const navigate = useNavigate();
	return (
		<Layout>
			<div className='container prdct-dtl'>
				<Divider />
				<div className='productdmain'>
					<div className='pdleft'>
						<h2>Centre Option Détente</h2>
						<div className='views'>
							<div className='vleft'>
								<div className='vcleft'>
									<figure>
										<img
											src='/static/images/eyeicon.svg'
											alt=''
										/>
									</figure>
								</div>
								<div className='vcright'>
									<p>255 {translation.Globals.views}</p>
								</div>
							</div>
							<div className='vright'>
								<div className='vcleft'>
									<figure>
										<img
											src='/static/images/redmapicon.svg'
											alt=''
										/>
									</figure>
								</div>
								<div className='vcright'>
									<p>
										<span>BROMONT.</span> 837 rue shefford,
										QC, J2L 1C4 aesthetic
									</p>
								</div>
							</div>
						</div>
						<div className='content'>
							<h4>Simple Beaute, Esthetique</h4>
							<p>
								Lorem Ipsum is simply dummy text of the printing
								and typesetting industry. Lorem Ipsum has been
								the industrys standard dummy text ever since the
								1500s Lorem Ipsum is simply dummy text of the
								printing and typesetting industry. Lorem Ipsum
								has been the industrys.
							</p>

							<h4>{translation.Globals.highlight}</h4>
							<ul className='dots_para'>
								<li>
									<span>•</span>
									<p>
										Lorem ipsum dolor sit amet, consectetur
										adipiscing elit. Nullam convallis elit
									</p>
								</li>
								<li>
									<span>•</span>
									<p>
										eget ex faucibus, sed consectetur ex
										semper. Donec nec eros sagittis, pretium
										sapien ac, molestie elit. Cras iaculis
										lectus at dolor aliquam, vitae aliquet l
									</p>
								</li>
								<li>
									<span>•</span>
									<p>
										igula euismod. Duis nec velit fermentum,
										iaculis nisi vitae, auctor eros. Vivamus
										at metus in ex luctus malesuada. Donec
										ut ante eget dui feugiat luctus{' '}
									</p>
								</li>
								<li>
									<span>•</span>
									<p>
										commodo a sem. Sed ac dolor mattis,
										semper neque vel, posuere odio. Proin
									</p>
								</li>
								<li>
									<span>•</span>
									<p>
										fermentum tortor vel pellentesque
										rhoncus. Sed eget ante eu ex posuere
										varius. Nulla pellentesque nunc vitae
										erat tincidunt euismod. Sed malesuada
										est{' '}
									</p>
								</li>
								{/* <li>
									<span>•</span>
									<p>rhoncus elit commodo ullamcorper ut ut turpis.</p>
								</li> */}
							</ul>
						</div>
					</div>
					<div className='pdright'>
						<figure>
							<img src='/static/images/maskgirl.svg' alt='' />
						</figure>
						<div className='buttn'>
							<button
								className='btn'
								type='button'
								onClick={() => navigate('/book-appointment')}
							>
								{translation.Globals.buyCoupon}
							</button>
							<button
								className='btn2'
								type='button'
								onClick={() => navigate('/products')}
							>
								{translation.Globals.returnCoupon}
							</button>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default ProductDetail;
