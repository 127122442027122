import React, { useState } from 'react';
import { Divider } from '@mui/material';
import {
	Appointment,
	ChatWithUs,
	Dashboard,
	Logout,
	MyOrder,
	Payout,
	PersonalInfo,
	ReferenceCode,
	Sidebar,
	Wishlist,
} from '../../features';
import useTranslation from '../../hooks/translation';
import Layout from '../../layout';
import './Account.scss';

const Account = () => {
	const translation = useTranslation() as any;
	const [activeTab, setActiveTab] = useState(1);
	const [upgrade, setUpgrade] = useState(false);
	const [image, setImage] = useState<string | null>(null);

	const handleAccountTab = () => {
		switch (activeTab) {
			case 1:
				return <MyOrder />;
			case 2:
				return <Wishlist />;
			case 3:
				return <Appointment />;
			case 4:
				return <PersonalInfo setImage={setImage} image={image} />;
			case 5:
				return <Dashboard />;
			case 6:
				return <ChatWithUs />;
			case 7:
				return <Payout />;
			case 8:
				return <ReferenceCode />;
			case 9:
				return <Logout />;
			default:
				return null;
		}
	};
	const handleText = () => {
		switch (activeTab) {
			case 1:
				return (
					<div>
						<h3>{translation.Globals.hi} Sameera !</h3>
						<p>{translation.account.checkorder}</p>
					</div>
				);

			case 2:
				return (
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<div>
							<h3>Hi Sameera !</h3>
							<p>{translation.account.wishlisttext}</p>
						</div>

						<button className='btn2' type='button'>
							{translation.Globals.remove}
						</button>
					</div>
				);
			case 3:
				return (
					<div>
						<h3>{translation.Globals.hi} Sameera !</h3>
						<p>{translation.account.upgradetext}</p>
					</div>
				);

			case 4:
				return (
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<div>
							<h3>{translation.Globals.hi} Sameera !</h3>
							<p>{translation.account.infoText}</p>
						</div>

						<p>ID No : FEWE4741561</p>
					</div>
				);
			case 5:
				return (
					<div>
						<h3>{translation.Globals.hi} Sameera !</h3>
						<p>{translation.account.dashboardtext}</p>
					</div>
				);

			case 6:
				return (
					<div>
						<h3>{translation.Globals.hi} Sameera !</h3>
						<p>{translation.account.messageCenter}</p>
					</div>
				);

			case 7:
				return (
					<div>
						<h3>{translation.Globals.hi} Sameera !</h3>
						<p>{translation.account.payoutInfo}</p>
					</div>
				);

			case 8:
				return (
					<div>
						<h3>{translation.Globals.hi} Sameera !</h3>
						<p>{translation.account.referenceInfo}</p>
					</div>
				);

			case 9:
				return (
					<div>
						<h3>{translation.Globals.hi} Sameera !</h3>
						<p>{translation.account.referenceInfo}</p>
					</div>
				);

			default:
				return null;
		}
	};

	return (
		<Layout>
			<div className='accnt'>
				<div className='container '>
					<Divider />
					<div className='accMain'>
						<div className='accleft'>
							<Sidebar
								activeTab={activeTab}
								setActiveTab={setActiveTab}
								image={image}
							/>
						</div>

						<div className='accright'>
							{handleText()}

							{handleAccountTab()}
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default Account;
