import { Checkbox, Divider, TextField } from '@mui/material';
import { useState } from 'react';
import useTranslation from '../hooks/translation';

type Cart = {
	formik: any;
	handleRemember: (item: boolean) => void;
};

const ContactInformation = ({ formik, handleRemember }: Cart) => {
	const [email, setEmail] = useState('');
	const [error, setError] = useState(false);
	const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
	const translation = useTranslation() as any;
	return (
		<div className='cntct-info'>
			<h2>{translation.account.contactInformation.info}</h2>

			<div className='mn-cntct-info'>
				<div className='eml-fld'>
					<div className='selectr'>
						<TextField
							className='eml-txtfld'
							placeholder={
								translation.account.contactInformation.input
							}
							variant='outlined'
							fullWidth
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
						{error && !email ? (
							<div className='validation_msg'>
								This field is required
							</div>
						) : (
							''
						)}
					</div>
					<button
						type='button'
						className='btn'
						onClick={() => setError(true)}
					>
						{translation.account.contactInformation.notifyMe}
					</button>
				</div>

				<Divider sx={{ background: '#fe6e6b63 ' }} />
				<h3>{translation.account.contactInformation.informations}</h3>

				<div className='name-fld'>
					<TextField
						placeholder={
							translation.account.contactInformation.fname
						}
						variant='outlined'
						fullWidth
						className='cntct-info-txtfld'
						id='firstName'
						{...formik.getFieldProps('firstName')}
						helperText={
							formik.touched.firstName && formik.errors.firstName
						}
					/>
					<TextField
						placeholder={
							translation.account.contactInformation.lname
						}
						variant='outlined'
						fullWidth
						className='cntct-info-txtfld'
						id='lastName'
						{...formik.getFieldProps('lastName')}
						helperText={
							formik.touched.lastName && formik.errors.lastName
						}
					/>
				</div>
				<TextField
					placeholder={translation.account.contactInformation.addrss1}
					variant='outlined'
					fullWidth
					className='cntct-info-txtfld'
					id='address'
					{...formik.getFieldProps('address')}
					helperText={formik.touched.address && formik.errors.address}
				/>
				<TextField
					id='outlined-basic'
					placeholder={
						translation.account.contactInformation.address2
					}
					variant='outlined'
					fullWidth
					className='cntct-info-txtfld'
				/>
				<div className='adrss-flds'>
					<TextField
						placeholder={
							translation.account.contactInformation.city
						}
						variant='outlined'
						fullWidth
						className='cntct-info-txtfld'
						id='city'
						{...formik.getFieldProps('city')}
						helperText={formik.touched.city && formik.errors.city}
					/>
					<TextField
						placeholder={
							translation.account.contactInformation.state
						}
						variant='outlined'
						fullWidth
						className='cntct-info-txtfld'
						id='state'
						{...formik.getFieldProps('state')}
						helperText={formik.touched.state && formik.errors.state}
					/>
					<TextField
						placeholder={translation.account.contactInformation.pin}
						variant='outlined'
						fullWidth
						className='cntct-info-txtfld'
						id='pinCode'
						{...formik.getFieldProps('pinCode')}
						helperText={
							formik.touched.pinCode && formik.errors.pinCode
						}
					/>
				</div>
				<div className='sv-info'>
					<Checkbox
						{...label}
						// defaultChecked={true}
						name='remember'
						onChange={formik.handleChange}
						onClick={() => {
							handleRemember(formik.values.remember);
						}}
					/>
					<p>{translation.account.contactInformation.saveInfo}</p>
				</div>
			</div>
		</div>
	);
};

export default ContactInformation;
