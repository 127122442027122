import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { MenuItem, Select, TextField } from '@mui/material';
import React, { useState } from 'react';
import useTranslation from '../../hooks/translation';

const PartnerRegistrationForm = () => {
	const translation = useTranslation() as any;
	const navigate = useNavigate();
	const [category, setCategory] = useState('');
	const [error, setError] = useState(false);

	const formik = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			email: '',
			phone: '',
			shopName: '',
			website: '',
			address: '',
			country: '',
			state: '',
			pinCode: '',
			bussinessPhone: '',
			businessLicense: '',
			neq: '',
			password: '',
			confirmPassword: '',
		},
		validationSchema: Yup.object({
			firstName: Yup.string()
				.max(15, 'Must be 15 characters or less')
				.required('This field is required')
				.min(3, 'Must be atleast 3 characters'),
			lastName: Yup.string()
				.max(20, 'Must be 20 characters or less')
				.required('This field is required')
				.min(3, 'Must be atleast 3 characters'),
			email: Yup.string()
				// .email('Must be valid email address')
				.required('This field is required')
				.matches(
					/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
					'Must be a valid email.',
				),
			phone: Yup.number()
				.typeError('Must be a valid number.')
				.positive('Must be a valid number.')
				.integer('Must be a valid number.')
				.min(8)
				.required('This field is required.')
				.label('Phone number'),
			shopName: Yup.string().required('This field is required'),
			// .min(3, 'Must be atleast 3 characters'),
			website: Yup.string().required('This field is required'),
			address: Yup.string()
				.required('This field is required.')
				.label('Address')
				.min(3, 'Must be atleast 3 characters'),
			state: Yup.string()
				.required('This field is required.')
				.label('State'),
			// .min(3, 'Must be atleast 3 characters'),
			country: Yup.string()
				.required('This field is required.')
				.label('City'),
			// .min(3, 'Must be atleast 3 characters'),
			pinCode: Yup.string()
				.required('This field is required.')
				.label('Pin code')
				.min(3, 'Pin code must be at least 3 characters.')
				.max(10, 'Pin code must be at most 10 digits.'),
			bussinessPhone: Yup.string()
				.required('This field is required.')
				.label('Business Phone Number'),
			// .min(3, 'Must be atleast 3 characters'),
			businessLicense: Yup.string()
				.required('This field is required.')
				.label('Business License Number'),
			neq: Yup.string()
				.required('This field is required.')
				.label('City')
				.min(3, 'Must be atleast 3 characters'),
			password: Yup.string()
				.required('This field is required.')
				.matches(
					/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
					'Must contain 8 or more characters, one uppercase, one lowercase and one number. ',
				)
				.label('Password'),
			confirmPassword: Yup.string()
				.oneOf([Yup.ref('password')], 'Password should be match.')
				.required('This field is required.'),
		}),
		onSubmit: async () => {
			formik.setSubmitting(true);
			navigate('/');
			formik.setSubmitting(false);
		},
	});

	const handleChange = (event: {
		target: { value: React.SetStateAction<string> };
	}) => {
		setCategory(event.target.value);
	};

	return (
		<div className='registForm'>
			<div className='container regimain'>
				<div className='rFleft'>
					<h3>{translation.PartnerWithUs.registration.title}</h3>
					<p>{translation.PartnerWithUs.registration.subtitle}</p>
					<form onSubmit={formik.handleSubmit}>
						<div className='personal'>
							<TextField
								placeholder={
									translation.PartnerWithUs.registration
										.firstName
								}
								className='partner-reg-fld'
								id='firstName'
								{...formik.getFieldProps('firstName')}
								helperText={
									formik.touched.firstName &&
									formik.errors.firstName
								}
							/>
							<TextField
								placeholder={
									translation.PartnerWithUs.registration
										.lastName
								}
								className='partner-reg-fld'
								id='lastName'
								{...formik.getFieldProps('lastName')}
								helperText={
									formik.touched.lastName &&
									formik.errors.lastName
								}
							/>
							<TextField
								placeholder={
									translation.PartnerWithUs.registration
										.mobile
								}
								className='partner-reg-fld'
								id='phone'
								type='number'
								{...formik.getFieldProps('phone')}
								helperText={
									formik.touched.phone && formik.errors.phone
								}
							/>
						</div>
						<div className='bussiness'>
							<TextField
								placeholder={
									translation.PartnerWithUs.registration
										.shopName
								}
								className='partner-reg-fld'
								id='shopName'
								{...formik.getFieldProps('shopName')}
								helperText={
									formik.touched.shopName &&
									formik.errors.shopName
								}
							/>
							<TextField
								placeholder={
									translation.PartnerWithUs.registration
										.website
								}
								className='partner-reg-fld'
								id='website'
								{...formik.getFieldProps('website')}
								helperText={
									formik.touched.website &&
									formik.errors.website
								}
							/>
							<TextField
								placeholder={
									translation.PartnerWithUs.registration
										.address
								}
								className='partner-reg-fld'
								id='address'
								{...formik.getFieldProps('address')}
								helperText={
									formik.touched.address &&
									formik.errors.address
								}
							/>
							<TextField
								placeholder={
									translation.PartnerWithUs.registration.city
								}
								className='partner-reg-fld'
								id='state'
								{...formik.getFieldProps('state')}
								helperText={
									formik.touched.state && formik.errors.state
								}
							/>
							<TextField
								placeholder={
									translation.PartnerWithUs.registration
										.country
								}
								className='partner-reg-fld'
								id='country'
								{...formik.getFieldProps('country')}
								helperText={
									formik.touched.country &&
									formik.errors.country
								}
							/>
							<TextField
								placeholder={
									translation.PartnerWithUs.registration.pin
								}
								className='partner-reg-fld'
								id='pinCode'
								{...formik.getFieldProps('pinCode')}
								helperText={
									formik.touched.pinCode &&
									formik.errors.pinCode
								}
							/>
							<TextField
								placeholder={
									translation.PartnerWithUs.registration
										.businessNo
								}
								className='partner-reg-fld'
								id='bussinessPhone'
								{...formik.getFieldProps('bussinessPhone')}
								helperText={
									formik.touched.bussinessPhone &&
									formik.errors.bussinessPhone
								}
							/>
							<div className='selectr'>
								<Select
									style={{ color: '#808080c9' }}
									value={category}
									onChange={handleChange}
									displayEmpty
									inputProps={{
										'aria-label': 'Without label',
									}}
									className='partner-reg-fld-rg-selll'
								>
									<MenuItem className='srcv-lst' value=''>
										{
											translation.PartnerWithUs
												.registration.businesCategory
										}
									</MenuItem>
									<MenuItem
										className='srcv-lst'
										value='service1'
									>
										{
											translation.PartnerWithUs
												.registration.businesCategory
										}
									</MenuItem>
									<MenuItem
										className='srcv-lst'
										value='service2'
									>
										{
											translation.PartnerWithUs
												.registration.businesCategory
										}
									</MenuItem>
								</Select>
								{error && !category ? (
									<div className='validation_msg'>
										This field is required
									</div>
								) : (
									''
								)}
							</div>
							<TextField
								placeholder={
									translation.PartnerWithUs.registration
										.businesslicenseNo
								}
								className='partner-reg-fld'
								id='bussinessLicense'
								{...formik.getFieldProps('businessLicense')}
								helperText={
									formik.touched.businessLicense &&
									formik.errors.businessLicense
								}
							/>
						</div>
						<div className='confirmpasswrd'>
							<TextField
								placeholder={
									translation.PartnerWithUs.registration.email
								}
								className='partner-reg-fld'
								id='email'
								{...formik.getFieldProps('email')}
								helperText={
									formik.touched.email && formik.errors.email
								}
							/>
							<TextField
								placeholder={
									translation.PartnerWithUs.registration.neq
								}
								className='partner-reg-fld'
								id='neq'
								{...formik.getFieldProps('neq')}
								helperText={
									formik.touched.neq && formik.errors.neq
								}
							/>
							<TextField
								placeholder={
									translation.PartnerWithUs.registration
										.password
								}
								className='partner-reg-fld'
								id='password'
								{...formik.getFieldProps('password')}
								helperText={
									formik.touched.password &&
									formik.errors.password
								}
							/>
							<TextField
								placeholder={
									translation.PartnerWithUs.registration
										.confirmPass
								}
								className='partner-reg-fld'
								id='confirmPassword'
								{...formik.getFieldProps('confirmPassword')}
								helperText={
									formik.touched.confirmPassword &&
									formik.errors.confirmPassword
								}
							/>
						</div>
						<div className='register'>
							<TextField
								placeholder={
									translation.PartnerWithUs.registration
										.promoCode
								}
								className='partner-reg-fld'
							/>
							<div className='btndiv'>
								<button
									onClick={() => setError(true)}
									className='btn'
									type='submit'
									disabled={formik.isSubmitting}
								>
									{translation.Globals.register}
								</button>
							</div>
						</div>
					</form>
				</div>
				<div className='rFright'>
					<figure>
						<img src='/static/images/Logo2.svg' alt='' />
					</figure>
				</div>
			</div>
		</div>
	);
};

export default PartnerRegistrationForm;
