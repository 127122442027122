import React, { useState } from 'react';

import { SellWithCoupify } from '../../types.ts/General';

type SellWithCoupifytype = {
	data: SellWithCoupify;
};

const SellWithCoupifyCard = ({ data }: SellWithCoupifytype) => {
	return (
		<div className='sell_wt_cpfy_crd'>
			<div className='top_sellcpfy'>
				<h4>{data.title}</h4>
			</div>
			<figure>
				<img src='../../static/images/green-tick.svg' alt='' />
			</figure>
			<p>{data.des}</p>
		</div>
	);
};

export default SellWithCoupifyCard;
