import React from 'react';
import { SellCoupon, SellCouponWork, SellWithCoupify } from '../../features';
import Layout from '../../layout';

const SellCoupons = () => {
	return (
		<Layout>
			<SellCoupon />
			<SellCouponWork />
			<SellWithCoupify />
		</Layout>
	);
};

export default SellCoupons;
