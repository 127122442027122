import React, { useEffect } from 'react';
import {
	Box,
	Breadcrumbs,
	FormControl,
	InputAdornment,
	MenuItem,
	Select,
	SwipeableDrawer,
	TextField,
	Typography,
} from '@mui/material';

import HomeIcon from '@mui/icons-material/Home';
import { useLocation, useNavigate } from 'react-router-dom';
import useTranslation from '../../hooks/translation';
import { useLanguageContext } from '../../context/LanguageContext';

function Header() {
	const translation = useTranslation() as any;
	const navigate = useNavigate();
	const [sidebarVisible, setSidebarVisible] = React.useState(false);
	const [lang, setLang] = React.useState('english');
	const [paths, setPaths] = React.useState('');
	const { language, changeLanguage } = useLanguageContext();
	const pageLocation = window.location.pathname;

	const location = useLocation();
	const { state } = location;
	useEffect(() => {
		if (pageLocation) {
			const route = pageLocation.replace('/', '');
			const name = route.split('-');
			// 'presale-coupon'
			// ['presale', 'coupon']
			let newName = '';
			if (name?.length > 1) {
				newName = name.join(' ');
				// presale coupon]
			} else {
				newName = route;
			}
			setPaths(newName);
			// console.log(arr, 'arrr');
		}
	}, [pageLocation]);

	function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
		event.preventDefault();
	}

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);

	const handleChange = (event: {
		target: { value: React.SetStateAction<string> };
	}) => {
		setLang(event.target.value);
	};

	const toggleDrawer =
		(open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
			if (
				event &&
				event.type === 'keydown' &&
				((event as React.KeyboardEvent).key === 'Tab' ||
					(event as React.KeyboardEvent).key === 'Shift')
			) {
				return;
			}
			setSidebarVisible(open);
		};

	const list = () => (
		<Box sx={{ width: 300 }} role='presentation'>
			<div className='mnu'>
				<div className='itemlogo'>
					<div onClick={() => navigate('/')} className='logo'>
						<figure>
							<img src='./static/images/logo_coup.svg' alt='' />
						</figure>
					</div>
				</div>
				<div className='item'>
					<div
						className='menuitem'
						onClick={() => navigate('/coupons')}
					>
						{translation.Home.Header.coupon}
					</div>

					<div
						className='menuitem'
						onClick={() => navigate('/discounted-services')}
					>
						{translation.Home.Header.discServc}
					</div>
					<div
						className='menuitem'
						onClick={() => navigate('/presale-coupons')}
					>
						{translation.Home.Header.presale}
					</div>
					<div
						className='menuitem'
						onClick={() => navigate('/partner-with-us')}
					>
						{translation.Home.Banner.partner}
					</div>
				</div>

				<div className='btndiv'>
					<div onClick={() => navigate('/sell-coupons')}>
						<button className='btn2 gry-txt' type='button'>
							{translation.Home.Header.sell}
						</button>
					</div>
					<button
						className='btn2'
						type='button'
						onClick={() => navigate('/authentication')}
					>
						{translation.Globals.signUp}
					</button>
				</div>

				<div className='contatus'>
					<p>{translation.Home.Header.description}</p>
					<div className='inputdiv'>
						<div className='enter'>
							<figure>
								<img src='./static/images/sms.svg' alt='' />
							</figure>
							<input
								placeholder={translation.Home.Footer.email}
							/>
						</div>

						<div className='btndiv'>
							<button className='btn' type='button'>
								{translation.Globals.subscribe}
							</button>
						</div>
					</div>

					<div className='contctdiv'>
						<figure className='clogoo'>
							<img src='/static/images/cLogo.svg' alt='' />
						</figure>
						<div className='cdtop'>
							<div className='cdleft'>
								<h4>{translation.Home.Header.selectLang}</h4>
								<FormControl sx={{ width: '100%' }}>
									<Select
										value={language}
										onChange={(e) =>
											changeLanguage(e.target.value)
										}
										displayEmpty
										inputProps={{
											'aria-label': 'Without label',
										}}
										className='lang_select'
										fullWidth
									>
										<MenuItem
											value='en'
											onClick={() =>
												setSidebarVisible(false)
											}
											className='mnu_itm_size'
										>
											{
												translation.Home.Footer.content
													.lang.en
											}
										</MenuItem>
										<MenuItem
											value='fr'
											className='mnu_itm_size'
											onClick={() =>
												setSidebarVisible(false)
											}
										>
											{
												translation.Home.Footer.content
													.lang.fr
											}
										</MenuItem>
									</Select>
								</FormControl>
							</div>
							<div className='cdRyt'>
								<div className='ftr_sec2'>
									<h4>
										{
											translation.Home.Footer.headings
												.contact
										}
									</h4>
									<div className='flexdiv'>
										<figure>
											<img
												src='../static/images/call.svg'
												alt=''
											/>
										</figure>
										<p>
											<a href='tel:+1 866-987-4149'>
												{
													translation.Home.Footer
														.content.phn
												}
											</a>
										</p>
									</div>

									<div className='flexdiv'>
										<figure>
											<img
												src='../static/images/sms.svg'
												alt=''
											/>
										</figure>
										<p>
											<a href='mailto:info@coupify.ca'>
												{
													translation.Home.Footer
														.content.email
												}
											</a>
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className='cdbtm'>
							<h4>{translation.Home.Header.joinCommunity}</h4>
							{/* <ul>
								<li>
									<a
										href='https://www.facebook.com/'
										target='_blank'
										rel='noopener noreferrer'
									>
										<figure>
											<img
												src='/static/images/fb1.svg'
												alt=''
											/>
										</figure>
									</a>
								</li>
								<li>
									<a
										href='https://www.instagram.com/'
										target='_blank'
										rel='noopener noreferrer'
									>
										<figure>
											<img
												src='/static/images/insta1.svg'
												alt=''
											/>
										</figure>
									</a>
								</li>
								<li>
									<a
										href='https://twitter.com/Twitter'
										target='_blank'
										rel='noopener noreferrer'
									>
										<figure>
											<img
												src='/static/images/twitter1.svg'
												alt=''
											/>
										</figure>
									</a>
								</li>
								<li>
									<a
										href='https://in.linkedin.com/'
										target='_blank'
										rel='noopener noreferrer'
									>
										<figure>
											<img
												src='/static/images/link1.svg'
												alt=''
											/>
										</figure>
									</a>
								</li>
								<li>
									<a
										href='https://www.youtube.com/'
										target='_blank'
										rel='noopener noreferrer'
									>
										<figure>
											<img
												src='/static/images/youtube1.svg'
												alt=''
											/>
										</figure>
									</a>
								</li>
							</ul> */}
							<ul>
								<a
									href='https://www.facebook.com/'
									target='_blank'
									rel='noopener noreferrer'
								>
									<li>
										<figure>
											<img
												src='../static/images/facebook.svg'
												alt=''
											/>
										</figure>
									</li>
								</a>
								<a
									href='https://www.instagram.com/'
									target='_blank'
									rel='noopener noreferrer'
								>
									<li>
										<figure>
											<img
												src='../static/images/instagram.svg'
												alt=''
											/>
										</figure>
									</li>
								</a>
								<a
									href='https://twitter.com/Twitter'
									target='_blank'
									rel='noopener noreferrer'
								>
									<li>
										<figure>
											<img
												src='../static/images/twitter.svg'
												alt=''
											/>
										</figure>
									</li>
								</a>
								<a
									href='https://in.linkedin.com/'
									target='_blank'
									rel='noopener noreferrer'
								>
									<li>
										<figure>
											<img
												src='../static/images/linkedin.svg'
												alt=''
											/>
										</figure>
									</li>
								</a>
								<a
									href='https://www.youtube.com/'
									target='_blank'
									rel='noopener noreferrer'
								>
									<li>
										<figure>
											<img
												src='../static/images/youtube.svg'
												alt=''
											/>
										</figure>
									</li>
								</a>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</Box>
	);

	return (
		<header className='header'>
			<div className='hdr_mn'>
				<div className='hdr'>
					<div className='container mn_hdr'>
						{/* <HeaderSidebar /> */}
						<div className='hdr_lft'>
							<button className='burger' type='button'>
								<div
									className='burger-block'
									onClick={toggleDrawer(true)}
								>
									<span />
									<span />
									<span />
									{/* <figure className='mnu'>
									<img
										src='./static/images/menu.svg'
										alt=''
									/>
								</figure> */}
								</div>
							</button>

							<div onClick={() => navigate('/')}>
								<figure>
									<img
										src='./static/images/logo_coup.svg'
										alt=''
									/>
								</figure>
							</div>
						</div>

						<ul className='hdr_mdl'>
							<li>
								<div onClick={() => navigate('/coupons')}>
									<h6>{translation.Home.Header.coupon}</h6>
								</div>
							</li>
							<li>
								<div
									onClick={() =>
										navigate('/discounted-services')
									}
								>
									<h6>{translation.Home.Header.discServc}</h6>
								</div>
							</li>
							<li>
								<div
									onClick={() => navigate('/presale-coupons')}
								>
									<h6>{translation.Home.Header.presale}</h6>
								</div>
							</li>
						</ul>
						<div className='hdr_ryt'>
							<div
								className='hdr-txt'
								onClick={() => navigate('/sell-coupons')}
							>
								<h6>{translation.Home.Header.sell}</h6>
							</div>
							{language === 'fr' ? (
								<div
									className='hdr_ryt_cntnt lang_chng'
									onClick={() => changeLanguage('en')}
								>
									<h6>{translation.Home.Header.lang.en}</h6>
								</div>
							) : (
								<div
									className='hdr_ryt_cntnt lang_chng'
									onClick={() => changeLanguage('fr')}
								>
									<h6>FR</h6>
								</div>
							)}

							<div
								className='hdr_ryt_cntnt hdr_crt'
								onClick={() => navigate('/cart')}
							>
								<figure>
									<img
										src='static/images/shopping-cart.svg'
										alt=''
									/>
								</figure>
							</div>

							<div
								className='hdr_ryt_cntnt hdr_acc'
								onClick={() => navigate('/account')}
							>
								<figure>
									<img
										src='static/images/person.svg'
										alt=''
									/>
								</figure>
							</div>

							<div>
								<button
									type='button'
									className='btn2'
									onClick={() => navigate('/authentication')}
								>
									{translation.Globals.signUp}
								</button>
							</div>
						</div>

						{/* <div className='hdr_ryt'>
							<div
								className='hdr-txt'
								onClick={() => navigate('/sell-coupons')}
							>
								<h6>{translation.Home.Header.sell}</h6>
							</div>
							{language === 'fr' ? (
								<div
									className='hdr-txt2'
									onClick={() => changeLanguage('en')}
								>
									<h6>{translation.Home.Header.lang.en}</h6>
								</div>
							) : (
								<div
									className='hdr-txt2'
									onClick={() => changeLanguage('fr')}
								>
									<h6>FR</h6>
								</div>
							)}

							<div
								className='	hdr-icns'
								onClick={() => navigate('/cart')}
							>
								<ShoppingCartIcon />
							</div>

							<div
								className='	hdr-icns'
								onClick={() => navigate('/account')}
							>
								<PersonIcon />
							</div>

							<div
								className='hdr-txt2'
								onClick={() => navigate('/authentication')}
							>
								<h6>{translation.Globals.signUp}</h6>
							</div>
						</div> */}
					</div>
				</div>
				<div className='mn_hdr2'>
					{/* <input type="search" placeholder="Search" /> */}
					<TextField
						id='input-with-icon-textfield'
						className='txt_fld'
						placeholder={translation.Home.Header.search}
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<div className='srch_dv'>
										<figure>
											<img
												src='../static/images/search-normal.svg'
												alt=''
											/>
										</figure>
										<h6>{translation.Globals.search}</h6>
									</div>
								</InputAdornment>
							),
						}}
						variant='outlined'
					/>
				</div>

				<div>
					<SwipeableDrawer
						className='drwr'
						anchor='left'
						open={sidebarVisible}
						onClose={toggleDrawer(false)}
						onOpen={toggleDrawer(true)}
					>
						{list()}
					</SwipeableDrawer>
				</div>
			</div>
			<div className='container'>
				<div
					role='presentation'
					onClick={handleClick}
					style={{
						padding: '40px 0',
						position: 'absolute',
						zIndex: 1,
						cursor: 'pointer',
						// borderBottom: '1px solid #dedede',
					}}
				>
					<Breadcrumbs
						aria-label='breadcrumb'
						separator={paths === '' ? '' : '›'}
					>
						<div
							// style={{ cursor: 'pointer' }}
							onClick={() => {
								navigate('/');
							}}
							// href='/'
							// underline='hover'
							className={state === 'cms' ? 'cms_brcm' : 'brdcrmb'}
						>
							<HomeIcon
								style={{ fontSize: '20px', marginRight: '2px' }}
								className={
									state === 'cms' ? 'cms_brcm' : 'brdcrmb'
								}
							/>
							Home
						</div>

						<Typography
							sx={{
								display: 'flex',
								alignItems: 'center',
								color: '#565656',
								textTransform: 'capitalize',
							}}
							color='text.primary'
						>
							{paths}
						</Typography>
					</Breadcrumbs>
				</div>
			</div>
		</header>
	);
}

export default Header;
