import { useNavigate } from 'react-router-dom';
import {
	Divider,
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
} from '@mui/material';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { CouponCard } from '../../components';
import useTranslation from '../../hooks/translation';
import cardData from '../../data/slider.json';
import './BrowseCoupons.scss';

type Stepstype = {
	setStep: Dispatch<SetStateAction<number>>;
};
const CouponDetails = ({ setStep }: Stepstype) => {
	const translation = useTranslation() as any;
	const [upgrade, setUpgrade] = useState(false);
	const [activeStep, setActiveStep] = useState(1);
	const [count, setCount] = useState(0);
	// console.log(count);

	const navigate = useNavigate();
	const navigateTo = () => {
		navigate('/');
	};

	const decrement = () => {
		if (count > 0) {
			setCount(count - 1);
		}
	};

	const handleActiveSteps = () => {
		switch (activeStep) {
			case 1:
				return (
					<div className='slct-cpn-typ'>
						<div>
							{/* <input type='radio' /> */}
							<FormControl className='radiobtn'>
								<RadioGroup
									// row
									aria-labelledby='demo-form-control-label-placement'
									name='position'
									defaultValue=''
								>
									<FormControlLabel
										value={
											translation.SellNow.couponDetails
												.wholeCoupon
										}
										control={<Radio />}
										label={
											<p>
												{
													translation.SellNow
														.couponDetails
														.wholeCoupon
												}
											</p>
										}
										// labelPlacement='start'
									/>
									<FormControlLabel
										// style={{ marginLeft: '7px' }}
										value={
											translation.SellNow.couponDetails
												.specificServices
										}
										control={<Radio />}
										label={
											<p>
												{
													translation.SellNow
														.couponDetails
														.specificServices
												}
											</p>
										}
										// labelPlacement='start'
									/>
								</RadioGroup>
							</FormControl>
							{/* <p>
								{translation.SellNow.couponDetails.wholeCoupon}
							</p> */}
						</div>
					</div>
				);
			case 2:
				return (
					<div className='cpn-count'>
						<h4>
							{translation.SellNow.couponDetails.couponNumbers}
						</h4>
						<div className='no-of-cpns'>
							<p>{translation.SellNow.couponDetails.quantity}</p>
							<div className='cpn-counter'>
								<div onClick={decrement} className='count'>
									<h5>-</h5>
								</div>
								<div className='count-value'>
									<h2>{count}</h2>
								</div>
								<div
									onClick={() => setCount(count + 1)}
									className='count'
								>
									<h5>+</h5>
								</div>
							</div>
						</div>
					</div>
				);
			case 3:
				return (
					<div className='cpn-count'>
						<h4>{translation.SellNow.couponDetails.couponId}</h4>
						<div className='entr-id'>
							<input
								type='text'
								placeholder={
									translation.SellNow.couponDetails.enterId
								}
							/>
						</div>
					</div>
				);

			default:
				return null;
		}
	};
	return (
		<section className='cpn_details'>
			<div className='container mn_cpn-dtls'>
				<div className='cpn-details-ryt'>
					{cardData.data?.length
						? cardData.data?.slice(0, 1).map((item) => {
								return (
									<CouponCard
										upgrade={upgrade}
										setUpgrade={setUpgrade}
										type='no btns'
										page=' sellCouponNow'
										key={item.id}
										data={item}
										id={item.id}
										image={item.img}
										title={item.title}
										address={item.address}
										price={item.price}
										price2={item.price2}
										navigateTo={navigateTo}
									/>
								);
						  })
						: null}
				</div>
				<div className='cpn-details-lft'>
					<div>
						<h2>{translation.SellNow.couponDetails.details}</h2>
					</div>
					<div className='mn-dtls-lft'>
						<div className='stppr-sec'>
							<div className='stpr_inr'>
								<h2>1</h2>
							</div>
							<div
								className={
									activeStep >= 2 ? 'stpr_inr' : 'stpr_inr2'
								}
							>
								<h2>2</h2>
							</div>
							<div
								className={
									activeStep >= 3 ? 'stpr_inr' : 'stpr_inr2'
								}
							>
								<h2>3</h2>
							</div>
						</div>
						<Divider />
						{handleActiveSteps()}
						<Divider />
						<div className='cpn-dtl-btns'>
							{activeStep === 1 ? (
								<button
									className='btn2'
									type='button'
									onClick={() => setStep(1)}
								>
									{translation.Globals.back}
								</button>
							) : (
								<button
									className='btn2'
									type='button'
									onClick={() =>
										setActiveStep(activeStep - 1)
									}
								>
									{translation.Globals.back}
								</button>
							)}

							{activeStep === 3 ? (
								<button
									className='btn'
									type='button'
									onClick={() => setStep(3)}
								>
									{translation.Globals.proceed}
								</button>
							) : (
								<button
									className='btn'
									type='button'
									onClick={() =>
										setActiveStep(activeStep + 1)
									}
								>
									{translation.Globals.next}
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default CouponDetails;
