import { Box, Divider, Modal } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { CheckAvailability, CouponCard } from '../../components';
import useTranslation from '../../hooks/translation';
import cardData from '../../data/slider.json';

const Appointment = () => {
	const translation = useTranslation() as any;
	const [upgrade, setUpgrade] = useState(false);
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const navigate = useNavigate();
	const navigateTo = () => {
		navigate('/details');
	};
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 900,
		bgcolor: 'background.paper',
		boxShadow: 24,
		borderRadius: 4,
		outline: 'none',
		border: 'none',
	};
	return (
		<div className='wishlist'>
			{!upgrade ? (
				<div className='cardsdiv'>
					{cardData.data?.length
						? cardData.data?.slice(0, 3).map((item) => {
								return (
									<CouponCard
										upgrade={upgrade}
										setUpgrade={setUpgrade}
										type='upgrade'
										page='presale'
										key={item.id}
										data={item}
										id={item.id}
										image={item.img}
										title={item.title}
										address={item.address}
										price={item.price}
										price2={item.price2}
										navigateTo={navigateTo}
									/>
								);
						  })
						: null}
				</div>
			) : (
				<div>
					<CheckAvailability />
					<button
						className='btn'
						type='button'
						onClick={handleOpen}
						style={{ display: 'flex', margin: '0 auto' }}
					>
						{translation.Globals.upgrade}
					</button>
				</div>
			)}
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box sx={style}>
					<div className='plc-bid'>
						<div className='top-sec'>
							<div onClick={handleClose}>
								<figure>
									<img
										src='/static/images/crossicon.svg'
										alt=''
									/>
								</figure>
							</div>
						</div>
						<div className='mdl-sec'>
							<div className='img-sec'>
								<figure>
									<img
										src='/static/images/upgrade-mdl-img.svg'
										alt=''
									/>
								</figure>
							</div>
							<div className='modl-info'>
								<h2>{translation.upgradeModal.title}</h2>
								<p style={{ paddingBottom: '10px' }}>
									{translation.upgradeModal.description}
								</p>

								<Divider />
								<div
									style={{
										width: '16%',
										marginLeft: 'auto',
										paddingTop: '10px',
									}}
								>
									<button
										type='button'
										className='btn'
										onClick={handleClose}
									>
										{translation.Globals.proceed}
									</button>
								</div>
							</div>
						</div>
					</div>
				</Box>
			</Modal>
		</div>
	);
};

export default Appointment;
