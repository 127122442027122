import React from 'react';
import useTranslation from '../../hooks/translation';
import Layout from '../../layout';
import './TermsAndConditions.scss';

const TermsAndCondition = () => {
	const translation = useTranslation() as any;
	return (
		<Layout>
			<div className='contactmain'>
				<div className='privacybnr'>
					<div className='container'>
						<div className='ppbanner'>
							<div className='ppleft'>
								<h1>{translation.Cms.terms}</h1>
							</div>
							<div className='ppright'>
								<figure>
									<img
										src='/static/images/termscondition.svg'
										alt=''
									/>
								</figure>
							</div>
						</div>
					</div>
				</div>
				<div className='container'>
					<div className='policycontent'>
						<div className='content1'>
							<h2>{translation.Cms.heading1}</h2>
							<p>{translation.Cms.termspara1}</p>

							<h5>{translation.Cms.termhead1}</h5>
							<p>{translation.Cms.termspara2}</p>

							<h5>{translation.Cms.termhead2}</h5>
							<p>{translation.Cms.termspara3}</p>

							<h5>{translation.Cms.termhead3}</h5>
							<p>{translation.Cms.termspara4}</p>
							<h5>{translation.Cms.termhead4}</h5>
							<p>{translation.Cms.termspara5}</p>
							<h5>{translation.Cms.termhead5}</h5>
							<p>{translation.Cms.termspara6}</p>
							<h5>{translation.Cms.termhead6}</h5>
							<p>{translation.Cms.termspara7}</p>
							<h5>{translation.Cms.termhead7}</h5>
							<p>{translation.Cms.termspara8}</p>
							<h5>{translation.Cms.termhead8}</h5>
							<p>{translation.Cms.termspara9}</p>
							<h5>{translation.Cms.termhead9}</h5>
							<p>{translation.Cms.termspara10}</p>
							<h5>{translation.Cms.termhead10}</h5>
							<p>{translation.Cms.termspara11}</p>
							<p>{translation.Cms.termspara11A}</p>
							<p>{translation.Cms.termspara11b}</p>
							<h5>{translation.Cms.termhead11}</h5>
							<p>{translation.Cms.termspara12}</p>
							<h5>{translation.Cms.termhead12}</h5>
							<p>{translation.Cms.termspara13}</p>
							<h5>{translation.Cms.termhead13}</h5>
							<p>{translation.Cms.termspara14}</p>
							<h5>{translation.Cms.termhead14}</h5>
							<p>{translation.Cms.termspara15}</p>

							<div className='con_tnt'>
								<h2>{translation.Cms.termsheading}</h2>
								<h5>{translation.Cms.termhed}</h5>
								<p>{translation.Cms.termspara16}</p>
								<p>{translation.Cms.termspara16A}</p>
								<h5>{translation.Cms.termhead16}</h5>
								<p>{translation.Cms.termspara17}</p>
								<h5>{translation.Cms.termhead17}</h5>
								<p>{translation.Cms.termspara18}</p>
								<p>{translation.Cms.termspara18a}</p>
								<p>{translation.Cms.termspara18b}</p>
								<p>{translation.Cms.termspara18c}</p>
								<p>{translation.Cms.termspara18d}</p>
								<p>{translation.Cms.termspara18e}</p>
								<p>{translation.Cms.termspara18f}</p>
								<p>{translation.Cms.termspara18g}</p>
								<p>{translation.Cms.termspara18h}</p>
								<p>{translation.Cms.termspara18i}</p>
								<p>{translation.Cms.termspara18j}</p>
								<p>{translation.Cms.termspara18k}</p>

								<h5>{translation.Cms.termhead18}</h5>
								<p>{translation.Cms.termspara19}</p>
								<p>
									<span>{translation.Cms.termsspan19a}</span>
									{translation.Cms.termspara19a}
								</p>
								<p>
									<span>{translation.Cms.termsspan19b}</span>
									{translation.Cms.termspara19b}
								</p>
								<p>
									<span>{translation.Cms.termsspan19c}</span>
									{translation.Cms.termspara19c}
								</p>
								<p>
									<span>{translation.Cms.termsspan19d}</span>
									{translation.Cms.termspara19d}
								</p>
								<p>
									<span>{translation.Cms.termsspan19d}</span>
									{translation.Cms.termspara19d}
								</p>
								<p>
									<span>{translation.Cms.termsspan19e}</span>
									{translation.Cms.termspara19e}
								</p>
								<p>
									<span>{translation.Cms.termsspan19f}</span>
									{translation.Cms.termspara19f}
								</p>
								<p>
									<span>{translation.Cms.termsspan19g}</span>
									{translation.Cms.termspara19g}
								</p>
								<p>
									<span>{translation.Cms.termsspan19h}</span>
									{translation.Cms.termspara19h}
								</p>
								<p>
									<span>{translation.Cms.termsspan19i}</span>
									{translation.Cms.termspara19i}
								</p>
								<p>
									<span>{translation.Cms.termsspan19j}</span>
									{translation.Cms.termspara19j}
								</p>
								<p>
									<span>{translation.Cms.termsspan19k}</span>
									{translation.Cms.termspara19k}
								</p>
								<p>
									<span>{translation.Cms.termsspan19l}</span>
									{translation.Cms.termspara19l}
								</p>
								<div className='limitdiv'>
									<ul>
										<li>-{translation.Cms.prohibation1}</li>
										<li>-{translation.Cms.prohibation2}</li>
										<li>-{translation.Cms.prohibation3}</li>
										<li>-{translation.Cms.prohibation4}</li>
										<li>-{translation.Cms.prohibation5}</li>
										<li>-{translation.Cms.prohibation6}</li>
									</ul>
								</div>
								<p>
									<span>{translation.Cms.termsspan19m}</span>
									{translation.Cms.termspara19m}
								</p>
								<p>{translation.Cms.termspara20}</p>
								<p>{translation.Cms.termspara21}</p>
								<h5>{translation.Cms.termhead19}</h5>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default TermsAndCondition;
