import React from 'react';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import { Divider } from '@mui/material';
import { Coupons } from '../../types.ts/General';
import useTranslation from '../../hooks/translation';

type CouponCardtype = {
	data: Coupons;
};

const CouponsToResell = ({ data }: CouponCardtype) => {
	const translation = useTranslation() as any;
	return (
		<div style={{ display: 'flex' }}>
			<div className='cpn_crd3'>
				<div className='crd_lft'>
					<div className='crd-img-mn'>
						<figure>
							<img alt='' src={data.img} />
						</figure>
						<div className='crd-div'>
							<div className='crd-rtng'>
								<figure>
									<img
										src='/static/images/rating-star.svg'
										alt=''
									/>
								</figure>
								<h4>4.2</h4>
							</div>
							<div className='crd-like'>
								<figure>
									<img
										src='/static/images/like-img.svg'
										alt=''
									/>
								</figure>
							</div>
						</div>
					</div>
				</div>
				<div className='crd-mddl'>
					<h2>{data.title}</h2>
					<div className='adrss'>
						<PlaceOutlinedIcon />
						<p>{data.address}</p>
					</div>
					<p>{data.description}</p>
				</div>
				<div className='crd-ryt'>
					<div className='prc'>
						<h2>
							{data.price}{' '}
							<span>
								<i>
									<s>{data.price2}</s>
								</i>
							</span>
						</h2>
					</div>
					<Divider />
					<div className='btns-sec'>
						<button className='btn2' type='button'>
							{translation.Globals.check}
						</button>
						<button className='btn' type='button'>
							{translation.Globals.sell}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CouponsToResell;
