import {
	Divider,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	TextField,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useTranslation from '../hooks/translation';

const PaymentMethod = () => {
	const translation = useTranslation() as any;
	const navigate = useNavigate();
	const [value, setValue] = React.useState('');
	const [show, setShow] = useState(true);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValue((event.target as HTMLInputElement).value);
	};
	return (
		<div className='pymnt-mthd'>
			<h2>{translation.Presale.PresaleDetails.paymentMethod.payment}</h2>
			<div className='mn-pymnt-sec'>
				<FormControl>
					<RadioGroup
						aria-labelledby='demo-controlled-radio-buttons-group'
						name='controlled-radio-buttons-group'
						value={value}
						onChange={handleChange}
					>
						<div className='mthds'>
							<div className='slct-icns'>
								<FormControlLabel
									value={
										translation.Presale.PresaleDetails
											.paymentMethod.method.paypal
									}
									control={<Radio />}
									label={
										<h4>
											{
												translation.Presale
													.PresaleDetails
													.paymentMethod.method.paypal
											}
										</h4>
									}
								/>
								<figure>
									<img
										src='/static/images/down-arrow-btn.svg'
										alt=''
									/>
								</figure>
							</div>
							<p>
								{
									translation.Presale.PresaleDetails
										.paymentMethod.description.paypal
								}
							</p>
						</div>
						<div className='mthds'>
							<div className='slct-icns'>
								<FormControlLabel
									value={
										translation.Presale.PresaleDetails
											.paymentMethod.method.payLater
									}
									control={<Radio />}
									label={
										<h4>
											{
												translation.Presale
													.PresaleDetails
													.paymentMethod.method
													.payLater
											}
										</h4>
									}
								/>
								<figure>
									<img
										src='/static/images/down-arrow-btn.svg'
										alt=''
									/>
								</figure>
							</div>
							<p>
								{
									translation.Presale.PresaleDetails
										.paymentMethod.description.payLater
								}
							</p>
						</div>
						<div className='mthds'>
							<div className='slct-icns'>
								<FormControlLabel
									value={
										translation.Presale.PresaleDetails
											.paymentMethod.method.wireTransfer
									}
									control={<Radio />}
									label={
										<h4>
											{
												translation.Presale
													.PresaleDetails
													.paymentMethod.method
													.wireTransfer
											}
										</h4>
									}
								/>
								<figure>
									<img
										src='/static/images/down-arrow-btn.svg'
										alt=''
									/>
								</figure>
							</div>
							<p>
								{
									translation.Presale.PresaleDetails
										.paymentMethod.description.wireTransfer
								}
							</p>
						</div>
						<div className='mthds'>
							<div className='slct-icns'>
								<FormControlLabel
									value={
										translation.Presale.PresaleDetails
											.paymentMethod.method.creditCard
									}
									control={<Radio />}
									label={
										<h4>
											{
												translation.Presale
													.PresaleDetails
													.paymentMethod.method
													.creditCard
											}
										</h4>
									}
								/>
								{/* <div className='cardicn'>
									<figure>
										<img
											src='/static/images/credit.svg'
											alt=''
										/>
									</figure>
									<figure>
										<img
											src='/static/images/securely.svg'
											alt=''
										/>
									</figure>
								</div> */}
								{show === false ? (
									<div onClick={() => setShow(true)}>
										<figure>
											<img
												src='/static/images/down-arrow-btn.svg'
												alt=''
											/>
										</figure>
									</div>
								) : (
									<div onClick={() => setShow(false)}>
										<figure>
											<img
												src='/static/images/up-arrow-btn.svg'
												alt=''
											/>
										</figure>
									</div>
								)}
							</div>
							<p>
								{
									translation.Presale.PresaleDetails
										.paymentMethod.description.creditCard
								}
							</p>
							{show ? (
								<div>
									<Divider sx={{ margin: '10px 0' }} />
									<TextField
										id='filled-basic'
										fullWidth
										placeholder={
											translation.Presale.PresaleDetails
												.paymentMethod.creditCard
												.cardHolder
										}
										variant='outlined'
										className='cntct-info-txtfld'
									/>
									<TextField
										id='filled-basic'
										fullWidth
										placeholder={
											translation.Presale.PresaleDetails
												.paymentMethod.creditCard
												.cardNumber
										}
										variant='outlined'
										className='cntct-info-txtfld'
									/>
									<div className='crd-dtls'>
										<TextField
											id='filled-basic'
											fullWidth
											placeholder={
												translation.Presale
													.PresaleDetails
													.paymentMethod.creditCard
													.expiry
											}
											variant='outlined'
											className='cntct-info-txtfld'
										/>
										<TextField
											id='filled-basic'
											fullWidth
											placeholder={
												translation.Presale
													.PresaleDetails
													.paymentMethod.creditCard
													.cvv
											}
											variant='outlined'
											className='cntct-info-txtfld'
										/>
									</div>

									<button className='btn' type='button'>
										{
											translation.Presale.PresaleDetails
												.paymentMethod.saveCard
										}
									</button>
								</div>
							) : null}
						</div>
					</RadioGroup>
				</FormControl>
			</div>
			<div className='pymnt-btn-sec'>
				<button
					className='btn'
					type='button'
					onClick={() =>
						navigate('/order-successful', { state: 'cms' })
					}
				>
					{translation.Presale.PresaleDetails.paymentMethod.payNow}
				</button>
			</div>
		</div>
	);
};

export default PaymentMethod;
