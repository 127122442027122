import useTranslation from '../../hooks/translation';

const Logout = () => {
	const translation = useTranslation() as any;
	return (
		<div className='logout'>
			<div className='crossbar'>
				<figure>
					<img src='/static/images/crossicon.svg' alt='' />
				</figure>
			</div>
			<div className='logdata'>
				<h5>{translation.account.logouttext}</h5>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit ut
					aliquam, purus sit amet luctus venenatis, lectus magna
					fringilla urna, porttitor
				</p>

				<div className='btns'>
					<div>
						<button className='btn2' type='button'>
							{translation.account.cancel}
						</button>
					</div>
					<div>
						<button className='btn' type='button'>
							{translation.account.yes}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Logout;
