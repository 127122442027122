/* eslint-disable react/no-array-index-key */
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	InputAdornment,
	Radio,
	RadioGroup,
	TextField,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SearchIcon from '@mui/icons-material/Search';
import React, { useState } from 'react';
import useTranslation from '../hooks/translation';
// import filterData from '../data/ProductFilter.json';

const ProductFilters = () => {
	const translation = useTranslation() as any;
	const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
	const [showLocation, setShowLocation] = useState(true);
	const [showCategories, setShowCategories] = useState(true);
	const [showDistance, setShowDistance] = useState(true);
	const [showPrice, setShowPrice] = useState(true);
	const [showRating, setShowRating] = useState(true);

	const array = [
		{
			id: 1,
			tab1: {
				label: 'Location',
				location: 'Bur Dubai (90+)',
				location1: 'Al Nassma (10)',
				location2: ' Dubai Coffee Museum (10)',
				location3: 'Meydan Stables (10)',
				location4: 'Alserkal Avenue (10)',
				location5: 'Design District (10)',
			},
		},
	];

	const array2 = [
		'Waxing',
		'Brazilian Wax',
		'Laser Hair Removal',
		'Electrolysis Hair Removal',
		'Upper Lip Threading',
		'Underarm Wax',
	];

	const array3 = [
		'Any Distance',
		'Within 1.0 mi (<10)',
		'Within 5.0 mi (40+)',
		'Within 10.0 mi (90+)',
		'Within 20.0 mi (130+)',
		'Within 50.0 mi (240+)',
	];
	const array4 = [
		'$0.0 - $25.0',
		'$25.0 - $45.0',
		'$45.0 - $80.0',
		'$80.0 - $190.0',
		'$190.0+',
	];

	const array5 = [
		{
			id: 1,
			text: 'All',
		},
		{
			id: 2,
			image: '/static/images/ratingStar2.svg',
			text: '& Up (210+)',
		},
		{
			id: 3,
			image: '/static/images/ratingStar2.svg',
			text: '& Up (210+)',
		},
		{
			id: 4,
			image: '/static/images/ratingStar2.svg',
			text: '& Up (210+)',
		},
		{
			id: 5,
			image: '/static/images/ratingStar2.svg',
			text: '& Up (220+)',
		},
	];

	return (
		<div>
			<div className='prdct-fltr'>
				<div className='fltr-dta'>
					{array.map((item) => {
						return (
							<div className='mn-flters' key={item.id}>
								<div className='fltr-hdng'>
									<h2>{item.tab1.label}</h2>
									<div
										style={{ cursor: 'pointer' }}
										onClick={() =>
											setShowLocation(!showLocation)
										}
									>
										<h2>-</h2>
									</div>
								</div>
								{showLocation ? (
									<div className='fltrs-vsbl'>
										<TextField
											id='input-with-icon-textfield'
											className='srch-fltr'
											placeholder={
												translation.Globals.search
											}
											InputProps={{
												startAdornment: (
													<InputAdornment position='start'>
														<SearchIcon />
													</InputAdornment>
												),
											}}
											variant='outlined'
										/>
										<div className='maincheck'>
											<div className='check'>
												<Checkbox
													{...label}
													className='fltr-chckbx'
												/>
												<h6>{item.tab1.location}</h6>
											</div>

											<div className='check'>
												<Checkbox
													{...label}
													className='fltr-chckbx'
												/>
												<h6>{item.tab1.location1}</h6>
											</div>
											<div className='check'>
												<Checkbox
													{...label}
													className='fltr-chckbx'
												/>
												<h6>{item.tab1.location2}</h6>
											</div>
											<div className='check'>
												<Checkbox
													{...label}
													className='fltr-chckbx'
												/>
												<h6>{item.tab1.location3}</h6>
											</div>
											<div className='check'>
												<Checkbox
													{...label}
													className='fltr-chckbx'
												/>
												<h6>{item.tab1.location4}</h6>
											</div>
											<div className='check'>
												<Checkbox
													{...label}
													className='fltr-chckbx'
												/>
												<h6>{item.tab1.location5}</h6>
											</div>
										</div>
										<h6>
											{translation.productFilter.seeAll}
										</h6>
									</div>
								) : null}
							</div>
						);
					})}
				</div>
			</div>
			<div className='prdct-fltr '>
				<div className='fltr-hdng'>
					<h2>Categories</h2>
					<div
						onClick={() => setShowCategories(!showCategories)}
						style={{ cursor: 'pointer' }}
					>
						<h2>-</h2>
					</div>
				</div>
				{showCategories ? (
					<div className='fltrs-vsbl'>
						<h3>
							<ArrowBackIosIcon />
							HAIR REMOVAL
						</h3>
						<div className='ctry-mn'>
							{array2.map((item, i) => {
								return (
									<div className='ctry' key={i}>
										<p>{item}</p>
									</div>
								);
							})}
						</div>
					</div>
				) : null}
			</div>
			<div className='prdct-fltr '>
				<div className='fltr-hdng'>
					<h2>Distance</h2>
					<div
						onClick={() => setShowDistance(!showDistance)}
						style={{ cursor: 'pointer' }}
					>
						<h2>-</h2>
					</div>
				</div>
				{showDistance ? (
					<div className='ctry-mn'>
						{array3.map((item, i) => {
							return (
								<div className='radio' key={i}>
									{/* <input type='radio' /> */}
									<FormControl>
										<RadioGroup
											row
											aria-labelledby='demo-form-control-label-placement'
											name='position'
											defaultValue=''
										>
											<FormControlLabel
												value='start'
												control={<Radio />}
												label={<p>{item}</p>}
												// labelPlacement='start'
											/>
										</RadioGroup>
									</FormControl>
									{/* <p>{item}</p> */}
								</div>
							);
						})}
					</div>
				) : null}
			</div>
			<div className='prdct-fltr '>
				<div className='fltr-hdng'>
					<h2>Price</h2>
					<div
						onClick={() => setShowPrice(!showPrice)}
						style={{ cursor: 'pointer' }}
					>
						<h2>-</h2>
					</div>
				</div>
				{showPrice ? (
					<div className='ctry-mn'>
						{array4.map((item, i) => {
							return (
								<div className='chk_bx ' key={i}>
									<Checkbox className='fltr-chckbx' />
									<p>{item}</p>
								</div>
							);
						})}
					</div>
				) : null}
			</div>
			<div className='prdct-fltr '>
				<div className='fltr-hdng'>
					<h2>Rating</h2>
					<div
						onClick={() => setShowRating(!showRating)}
						style={{ cursor: 'pointer' }}
					>
						<h2>-</h2>
					</div>
				</div>
				{showRating ? (
					<div className='ctry-mn'>
						{array5.map((item) => {
							return (
								<div className='radio' key={item.id}>
									<FormControl>
										<RadioGroup
											row
											aria-labelledby='demo-form-control-label-placement'
											name='position'
											defaultValue=''
										>
											<FormControlLabel
												value='start'
												control={<Radio />}
												label={
													<div className='stardiv'>
														<figure>
															<img
																src={item.image}
																alt=''
															/>
														</figure>
														<h5>{item.text}</h5>
													</div>
												}
												// labelPlacement='start'
											/>
										</RadioGroup>
									</FormControl>
								</div>
							);
						})}
					</div>
				) : null}
			</div>
		</div>
	);
};

export default ProductFilters;
