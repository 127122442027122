import { Divider, InputAdornment, TextField } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import useTranslation from '../hooks/translation';

type Ordertype = {
	activeStep: number;
	setActiveStep: Dispatch<SetStateAction<number>>;
};

const OrderSummary = ({ activeStep, setActiveStep }: Ordertype) => {
	const translation = useTranslation() as any;
	const data = [
		{
			id: 1,
			img: '/static/images/ordr-smry-img.svg',
			proName: 'Oderm',
			proDescription: 'Simple Beaute, Esthetique',
			proPrice: '$ 420.00',
			subtotal: '$ 480.00',
			service: 'Free',
			couponCode: '-$ 80.00',
			taxpercent: '15%',
			totalTax: '$ 60',
			total: '$ 460.00',
			timeRemaining: '5 minutes',
		},
	];

	return (
		<div className='ordr-summary'>
			<div>
				<h2>{translation.account.orderSummary.title}</h2>
				<p>{translation.account.orderSummary.des}</p>
			</div>
			<Divider />

			{data.map((item) => {
				return (
					<div className='ordr_card'>
						<div className='ordr_card_bx'>
							<div>
								<figure>
									<img src={item.img} alt='' />
								</figure>
							</div>
							<div>
								<h4>{item.proName}</h4>
								<p>{item.proDescription}</p>
								<h2>{item.proPrice}</h2>
							</div>
						</div>
						<div className='align_div'>
							<p>{translation.account.orderSummary.subtotal}</p>
							<h4>{item.subtotal}</h4>
						</div>
						<div className='sr_align'>
							<p>{translation.account.orderSummary.service}</p>
							<p>{item.service}</p>
						</div>
						<Divider />
						{activeStep === 1 ? (
							<div className='cpn-code'>
								<TextField
									id='input-with-icon-textfield'
									className='txt_fld'
									fullWidth
									placeholder={
										translation.account.orderSummary
											.applyHere
									}
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<button
													className='applybtn'
													type='button'
												>
													{
														translation.Globals
															.applyNow
													}
												</button>
											</InputAdornment>
										),
									}}
									variant='outlined'
								/>
								<div className='ordernline'>
									<figure>
										<img
											src='/static/images/carbon_time.svg'
											alt=''
										/>
									</figure>
									<p>
										{
											translation.account.orderSummary
												.endTime
										}{' '}
										<span>{item.timeRemaining}</span>
									</p>
								</div>
							</div>
						) : null}
						<div className='coup_code'>
							<p>{translation.account.orderSummary.couponCode}</p>
							<h4>{item.couponCode}</h4>
						</div>
						<div className='sr_align'>
							<p>
								{translation.account.orderSummary.tax}{' '}
								<span>{item.taxpercent}</span>
							</p>
							<p>{item.totalTax}</p>
						</div>
						<Divider />
						<div className='align_div'>
							<p>{translation.account.orderSummary.total}</p>
							<h4>{item.total}</h4>
						</div>
						{activeStep === 3 ? (
							<p className='cntr-txt'>
								{translation.account.orderSummary.paymentMethod}
							</p>
						) : null}
					</div>
				);
			})}
		</div>
	);
};

export default OrderSummary;
