import { Divider, Modal, TextField, Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Yup from 'yup';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useFormik } from 'formik';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import EastIcon from '@mui/icons-material/East';
import Slider from 'react-slick';
import { useState, useEffect, useRef } from 'react';
import { DiscountedServices } from '../../features';
import useTranslation from '../../hooks/translation';
import Layout from '../../layout';
import './PresaleDetails.scss';

type pageType = {
	page: string;
};

const PresaleDetails = () => {
	const translation = useTranslation() as any;
	const navigate = useNavigate();
	const location = useLocation();
	const { state } = location;
	const dropdownRef = useRef<HTMLDivElement>(null);

	const [buyNow, setbuyNow] = useState(false);
	const [show, setshow] = useState(false);
	const [bid, setBid] = useState(false);
	const [bid2, setBid2] = useState(false);
	const [open, setOpen] = useState(false);
	const [open2, setOpen2] = useState(false);
	const [count, setCount] = useState(0);
	const handleOpen = () => setOpen(true);
	const handleOpen2 = () => setOpen2(true);
	const handleClose2 = () => setOpen2(false);
	const handleClose = () => setOpen(false);
	const handlePlaceBid = () => {
		setBid(true);
		setBid2(true);
	};
	const formik = useFormik({
		initialValues: {
			email: '',
			message: '',
		},
		validationSchema: Yup.object({
			email: Yup.string()
				.required('This field is required')
				// .email('Enter a valid email address'),
				.matches(
					/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
					'Enter a valid email address',
				),
			message: Yup.string()
				.required('This field is required')
				.min(3, 'Message must contain atleast 3 characters'),
		}),
		onSubmit: () => {
			formik.setSubmitting(true);
			handleClose();
		},
	});
	const decrement = () => {
		if (count > 0) {
			setCount(count - 1);
		}
	};

	useEffect(() => {
		if (state === 'data') {
			setbuyNow(true);
		} else if (state === 'discounted_services') {
			setBid(true);
		}
	}, [state]);
	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target as Node)
			) {
				setshow(false);
			}
		}

		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [dropdownRef]);
	const handleItemClick = (event: React.MouseEvent<HTMLDivElement>) => {
		event.stopPropagation(); // Prevent the event from propagating to parent
	};

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		boxShadow: 24,
		borderRadius: 4,
		outline: 'none',
		border: 'none',
	};
	const style2 = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 900,
		bgcolor: 'background.paper',
		boxShadow: 24,
		borderRadius: 4,
		outline: 'none',
		border: 'none',
	};

	const settings = {
		dots: false,
		arrows: true,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		prevArrow: <KeyboardBackspaceIcon />,
		nextArrow: <EastIcon />,
	};
	const data = [
		{
			id: 1,
			title: 'Centre Option Détente',
			views: '255 ',
			city: 'BROMONT. ',
			completeAddress: '837 rue shefford,  QC, J2L 1C4 aesthetic',
			description:
				"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
			price1: '$420.00',
			price2: '$1026',
			days: '10',
			hours: '14',
			minutes: '56',
		},
	];
	const offers = [
		{
			id: 1,
			offer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam convallis elit ',
			value: '$420.00',
		},
		{
			id: 2,
			offer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam convallis elit ',
			value: '$420.00',
		},
		{
			id: 3,
			offer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam convallis elit ',
			value: '$420.00',
		},
		{
			id: 4,
			offer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam convallis elit ',
			value: '$420.00',
		},
		{
			id: 5,
			offer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam convallis elit ',
			value: '$420.00',
		},
		{
			id: 6,
			offer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam convallis elit ',
			value: '$420.00',
		},
	];
	const services = [
		{
			id: 1,
			image: '/static/images/maskgirl.svg',
			title: "Facial Coup d'éclat GLO",
		},
		{
			id: 2,
			image: '/static/images/maskgirl.svg',
			title: "Facial Coup d'éclat GLO",
		},
		{
			id: 3,
			image: '/static/images/maskgirl.svg',
			title: "Facial Coup d'éclat GLO",
		},
		{
			id: 4,
			image: '/static/images/maskgirl.svg',
			title: "Facial Coup d'éclat GLO",
		},
		{
			id: 5,
			image: '/static/images/maskgirl.svg',
			title: "Facial Coup d'éclat GLO",
		},
		{
			id: 6,
			image: '/static/images/maskgirl.svg',
			title: "Facial Coup d'éclat GLO",
		},
		{
			id: 7,
			image: '/static/images/maskgirl.svg',
			title: "Facial Coup d'éclat GLO",
		},
		{
			id: 8,
			image: '/static/images/maskgirl.svg',
			title: "Facial Coup d'éclat GLO",
		},
	];

	return (
		<Layout>
			<div className='mn-preDtls'>
				<div className='pre-details container'>
					<Divider />
					<div className='cpn-dtls '>
						<div className='dtls-lft'>
							{data.map((item) => {
								return (
									<div>
										<h2>{item.title}</h2>
										<div className='view-sec'>
											<div className='eye-icn'>
												<figure>
													<img
														src='/static/images/eye-img.svg'
														alt=''
													/>
												</figure>
												<p>
													{item.views}
													{translation.Globals.views}
												</p>
											</div>
											<div className='lctn-sec'>
												<figure>
													<img
														src='/static/images/map2.svg'
														alt=''
													/>
												</figure>
												<p>
													{item.city}
													<span>
														{item.completeAddress}
													</span>
												</p>
											</div>
										</div>
										<Divider />
										<p className='crd-des'>
											{item.description}
										</p>

										{!bid ? (
											<div className='srvcs'>
												<h3>
													{
														translation.Presale
															.PresaleDetails
															.services
													}
												</h3>
												<Divider />
												<div>
													<ul className='all-srvcs'>
														{services.map((val) => {
															return (
																<div
																	onClick={() =>
																		navigate(
																			'/product-detail',
																		)
																	}
																	className='srvc-lst'
																>
																	<div>
																		<figure>
																			<img
																				src={
																					val.image
																				}
																				alt=''
																			/>
																		</figure>
																	</div>
																	<div>
																		{
																			val.title
																		}
																	</div>
																	<div className='ryt-arr'>
																		<EastIcon />
																	</div>
																</div>
															);
														})}
													</ul>
												</div>
											</div>
										) : (
											<div className='bk-aptmnt'>
												<h2>
													{
														translation.account
															.checkAvailabilty
															.bookAppointment
													}
												</h2>
												<Divider />
												<div className='check_date'>
													<div className='chk'>
														<h6>
															{
																translation
																	.account
																	.checkAvailabilty
																	.date
															}
														</h6>
														<div>
															<input type='date' />
															{/* <figure>
																<img
																	src='/static/images/calender-img.svg'
																	alt=''
																/>
															</figure> */}
														</div>
													</div>
													<div className=''>
														<button
															className='btn2'
															type='button'
														>
															{
																translation
																	.account
																	.checkAvailabilty
																	.checkAvailability
															}
														</button>
													</div>
												</div>
											</div>
										)}
										<Divider />
										{!buyNow ? (
											<div className='divchange'>
												<div className='qty'>
													<div className='cpn-counter'>
														<div
															className='incre-decre'
															onClick={decrement}
														>
															<h5>-</h5>
														</div>
														<div className='count-val'>
															<h2>
																Qty{' '}
																<span>
																	{count}
																</span>
															</h2>
														</div>
														<div
															className='incre-decre'
															onClick={() =>
																setCount(
																	count + 1,
																)
															}
														>
															<h5>+</h5>
														</div>
													</div>
													<div>
														<h2 className='itm-prc'>
															{item.price1}
															<span>
																<s>
																	<i>
																		{
																			item.price2
																		}
																	</i>
																</s>
															</span>
														</h2>
													</div>
												</div>
												{state === 'presaleBuy' ? (
													<div className='pesale-end'>
														<figure>
															<img
																src='/static/images/carbon_time.svg'
																alt=''
															/>
														</figure>
														<p>
															{
																translation
																	.Presale
																	.PresaleDetails
																	.endTime
															}
															{/* <span></span>{' '} */}
															<span>
																{item.days}{' '}
																{
																	translation
																		.Globals
																		.day2
																}
																:
															</span>{' '}
															<span>
																{item.hours}
																{
																	translation
																		.Globals
																		.hour2
																}
																:
															</span>{' '}
															<span>
																{' '}
																{
																	item.minutes
																}{' '}
																minutes
															</span>
														</p>
													</div>
												) : null}
											</div>
										) : (
											<div className='section2'>
												<div className='max-minPrc'>
													<div className='prcs'>
														<p>
															{
																translation
																	.Presale
																	.PresaleDetails
																	.minPrice
															}
														</p>
														<h2>60.00$</h2>
													</div>
													<div className='prcs2'>
														<p>
															{
																translation
																	.Presale
																	.PresaleDetails
																	.maxPrice
															}
														</p>
														<h2>400.00$</h2>
													</div>
												</div>
												<Divider />
												<div className='offrd-prc'>
													<p>
														{
															translation.Presale
																.PresaleDetails
																.priceOffered
														}
													</p>
													<div className='ofrdPrc-ryt'>
														<div className='Ofrd-Prc-Top'>
															<input
																type='text'
																placeholder='100.25 $'
															/>
															{/* <h4>100.25 $</h4> */}
															<p>
																{
																	translation
																		.Presale
																		.PresaleDetails
																		.max
																}
															</p>
														</div>
														<p>
															{
																translation
																	.Presale
																	.PresaleDetails
																	.note
															}
														</p>
													</div>
												</div>
												<Divider />
											</div>
										)}
										{/* {page === 'sell' ? (
											<div className='section2'>
												<div className='max-minPrc'>
													<div className='prcs'>
														<p>
															{
																translation
																	.Presale
																	.PresaleDetails
																	.minPrice
															}
														</p>
														<h2>60.00$</h2>
													</div>
													<div className='prcs2'>
														<p>
															{
																translation
																	.Presale
																	.PresaleDetails
																	.maxPrice
															}
														</p>
														<h2>400.00$</h2>
													</div>
												</div>
												<Divider />
												<div className='offrd-prc'>
													<p>
														{
															translation.Presale
																.PresaleDetails
																.priceOffered
														}
													</p>
													<div className='ofrdPrc-ryt'>
														<div className='Ofrd-Prc-Top'>
															<h4>100.25 $</h4>
															<p>
																{
																	translation
																		.Presale
																		.PresaleDetails
																		.max
																}
															</p>
														</div>
														<p>
															{
																translation
																	.Presale
																	.PresaleDetails
																	.note
															}
														</p>
													</div>
												</div>
												<Divider />
											</div>
										) : (
											<div className='divchange'>
												<div className='qty'>
													<div className='cpn-counter'>
														<div className='incre-decre'>
															<h2>-</h2>
														</div>
														<div className='count-val'>
															<h2>
																Qty{' '}
																<span>01</span>
															</h2>
														</div>
														<div className='incre-decre'>
															<h2>+</h2>
														</div>
													</div>
													<div>
														<h2 className='itm-prc'>
															{item.price1}
															<span>
																<s>
																	<i>
																		{
																			item.price2
																		}
																	</i>
																</s>
															</span>
														</h2>
													</div>
												</div>
												<div className='pesale-end'>
													<figure>
														<img
															src='/static/images/carbon_time.svg'
															alt=''
														/>
													</figure>
													<p>
														{
															translation.Presale
																.PresaleDetails
																.endTime
														}
														<span>{item.days}</span>{' '}
														<span>
															{item.hours}
														</span>{' '}
														<span>
															{item.minutes}
														</span>
													</p>
												</div>
											</div>
										)} */}

										<div className='btns-section'>
											{!buyNow ? (
												<button
													type='button'
													className='btn'
													onClick={() =>
														navigate(
															'/book-appointment',
														)
													}
												>
													{translation.Globals.buy}
												</button>
											) : (
												<div style={{ width: '60%' }}>
													{!bid2 ? (
														<button
															type='button'
															className='btn'
															onClick={
																handlePlaceBid
															}
															style={{
																width: '100%',
															}}
														>
															{
																translation
																	.Globals
																	.placeBid
															}
														</button>
													) : (
														<button
															type='button'
															className='btn'
															onClick={
																handleOpen2
															}
															style={{
																width: '100%',
															}}
														>
															{
																translation
																	.Globals
																	.placeBid
															}
														</button>
													)}
												</div>
											)}

											<button
												type='button'
												className='btn2'
												onClick={handleOpen}
											>
												{translation.Globals.gift}
											</button>
										</div>
									</div>
								);
							})}
						</div>
						<div className='dtls-ryt'>
							<div className='presale-sldr'>
								<Slider {...settings}>
									<div className='sldr-img-sec'>
										<figure className='sldr-img'>
											<img
												src='/static/images/ordr-smry-img.svg'
												alt=''
											/>
										</figure>
										<div className='share'>
											<div onClick={() => setshow(true)}>
												<figure className='shr-btn'>
													<img
														src='/static/images/share-icn.svg'
														alt=''
													/>
												</figure>
											</div>
											{show ? (
												<div
													ref={dropdownRef}
													className='scl-icns-sec'
												>
													<figure>
														<img
															src='/static/images/fb1.svg'
															alt=''
														/>
													</figure>
													<figure>
														<img
															src='/static/images/insta1.svg'
															alt=''
														/>
													</figure>
													<figure>
														<img
															src='/static/images/twitter1.svg'
															alt=''
														/>
													</figure>
												</div>
											) : null}
										</div>
									</div>
									<div className='sldr-img-sec'>
										<figure className='sldr-img'>
											<img
												src='/static/images/pre-img-1.svg'
												alt=''
											/>
										</figure>
										<div className='share'>
											<div onClick={() => setshow(true)}>
												<figure className='shr-btn'>
													<img
														src='/static/images/share-icn.svg'
														alt=''
													/>
												</figure>
											</div>
											{show ? (
												<div
													ref={dropdownRef}
													className='scl-icns-sec'
												>
													<figure>
														<img
															src='/static/images/fb1.svg'
															alt=''
														/>
													</figure>
													<figure>
														<img
															src='/static/images/insta1.svg'
															alt=''
														/>
													</figure>
													<figure>
														<img
															src='/static/images/twitter1.svg'
															alt=''
														/>
													</figure>
												</div>
											) : null}
										</div>
									</div>
									<div className='sldr-img-sec'>
										<figure className='sldr-img'>
											<img
												src='/static/images/pre-img2.svg'
												alt=''
											/>
										</figure>
										<div className='share'>
											<div onClick={() => setshow(true)}>
												<figure className='shr-btn'>
													<img
														src='/static/images/share-icn.svg'
														alt=''
													/>
												</figure>
											</div>
											{show ? (
												<div
													ref={dropdownRef}
													className='scl-icns-sec'
												>
													<figure>
														<img
															src='/static/images/fb1.svg'
															alt=''
														/>
													</figure>
													<figure>
														<img
															src='/static/images/insta1.svg'
															alt=''
														/>
													</figure>
													<figure>
														<img
															src='/static/images/twitter1.svg'
															alt=''
														/>
													</figure>
												</div>
											) : null}
										</div>
									</div>
									<div className='sldr-img-sec'>
										<figure className='sldr-img'>
											<img
												src='/static/images/pre-img3.svg'
												alt=''
											/>
										</figure>
										<div className='share'>
											<div onClick={() => setshow(true)}>
												<figure className='shr-btn'>
													<img
														src='/static/images/share-icn.svg'
														alt=''
													/>
												</figure>
											</div>
											{show ? (
												<div
													ref={dropdownRef}
													className='scl-icns-sec'
												>
													<figure>
														<img
															src='/static/images/fb1.svg'
															alt=''
														/>
													</figure>
													<figure>
														<img
															src='/static/images/insta1.svg'
															alt=''
														/>
													</figure>
													<figure>
														<img
															src='/static/images/twitter1.svg'
															alt=''
														/>
													</figure>
												</div>
											) : null}
										</div>
									</div>
								</Slider>
							</div>
							<div className='btmsldr-imgs'>
								<figure>
									<img
										src='/static/images/pre-img-1.svg'
										alt=''
									/>
								</figure>
								<figure>
									<img
										src='/static/images/pre-img2.svg'
										alt=''
									/>
								</figure>
								<figure>
									<img
										src='/static/images/pre-img3.svg'
										alt=''
									/>
								</figure>
							</div>
							<div className='pymnt-mthds'>
								<figure>
									<img
										src='/static/images/secure-payment.svg'
										alt=''
									/>
								</figure>
								<figure>
									<img src='/static/images/visa.svg' alt='' />
								</figure>
								<figure>
									<img
										src='/static/images/master-card.svg'
										alt=''
									/>
								</figure>
								<figure>
									<img
										src='/static/images/paypal.svg'
										alt=''
									/>
								</figure>
							</div>
						</div>
					</div>
					<div className='the-dtls pre-pt '>
						<h2 className='pre-hdng pl'>
							{translation.Presale.PresaleDetails.theDetails}
						</h2>
						<Divider
							sx={{ borderColor: '#FE6E6B', opacity: '50%' }}
						/>
						<h3 className='pl'>Centre Option Detente</h3>
						<h6 className='pl'>Simple Beaute, Esthetique</h6>
						<p className='pl'>
							Lorem Ipsum is simply dummy text of the printing and
							typesetting industry. Lorem Ipsum has been the
							industrys standard dummy text ever since the 1500s
							Lorem Ipsum is simply dummy text of the printing and
							typesetting industry. Lorem Ipsum has been the
							industrys.
						</p>
						<div className='pl'>
							<h6>
								{translation.Presale.PresaleDetails.highlights}
							</h6>
							<ul>
								<li>
									Lorem Ipsum is simply dummy text of the
									printing and typesetting industry. Lorem
									Ipsum has been
								</li>
								<li>
									Lorem Ipsum is simply dummy text of the
									printing and typesetting industry. Lorem
									Ipsum has been
								</li>

								<li>
									Lorem Ipsum is simply dummy text of the
									printing and typesetting industry. Lorem
									Ipsum has been
								</li>
								<li>
									Lorem Ipsum is simply dummy text of the
									printing and typesetting industry. Lorem
									Ipsum has been
								</li>
								<li>
									Lorem Ipsum is simply dummy text of the
									printing and typesetting industry. Lorem
									Ipsum has been
								</li>
							</ul>
						</div>
					</div>
					<div className='mr-offrs pre-pt '>
						<h2 className='pre-hdng pl'>
							{translation.Presale.PresaleDetails.moreOffers}{' '}
							<span>
								<i>
									{
										translation.Presale.PresaleDetails
											.optional
									}
								</i>
							</span>
						</h2>
						<Divider
							sx={{ borderColor: '#FE6E6B', opacity: '50%' }}
						/>
						<ul className='preOfrs pl'>
							{offers.map((item) => {
								return (
									<li>
										<div className='ofr-txt'>
											<p>{item.offer}</p>
										</div>
										<div className='ofr-val'>
											<p>
												{
													translation.Presale
														.PresaleDetails.value
												}
											</p>
											<h6>{item.value}</h6>
										</div>
									</li>
								);
							})}
						</ul>
					</div>
					<div className='str-plcs pre-pt'>
						<h2 className='pre-hdng pl'>
							{translation.Presale.PresaleDetails.storePolicies}
						</h2>
						<Divider
							sx={{ borderColor: '#FE6E6B', opacity: '50%' }}
						/>
						<ul className=' plcs'>
							<li>
								Lorem Ipsum is simply dummy text of the printing
								and typesetting industry. Lorem Ipsum has been
							</li>
							<li>
								Lorem Ipsum is simply dummy text of the printing
								and typesetting industry. Lorem Ipsum has been
							</li>

							<li>
								Lorem Ipsum is simply dummy text of the printing
								and typesetting industry. Lorem Ipsum has been
							</li>
							<li>
								Lorem Ipsum is simply dummy text of the printing
								and typesetting industry. Lorem Ipsum has been
							</li>
							<li>
								Lorem Ipsum is simply dummy text of the printing
								and typesetting industry. Lorem Ipsum has been
							</li>
						</ul>
					</div>
					<div className='str-plcs pre-pt'>
						<h2 className='pre-hdng pl'>
							{translation.Presale.PresaleDetails.info}
						</h2>
						<Divider
							sx={{ borderColor: '#FE6E6B', opacity: '50%' }}
						/>
						<ul className=' plcs'>
							<li>
								Lorem Ipsum is simply dummy text of the printing
								and typesetting industry. Lorem Ipsum has been
							</li>
							<li>
								Lorem Ipsum is simply dummy text of the printing
								and typesetting industry. Lorem Ipsum has been
							</li>

							<li>
								Lorem Ipsum is simply dummy text of the printing
								and typesetting industry. Lorem Ipsum has been
							</li>
							<li>
								Lorem Ipsum is simply dummy text of the printing
								and typesetting industry. Lorem Ipsum has been
							</li>
							<li>
								Lorem Ipsum is simply dummy text of the printing
								and typesetting industry. Lorem Ipsum has been
							</li>
						</ul>
					</div>
					<div className='g-map'>
						<figure>
							<img src='/static/images/google-map.svg' alt='' />
						</figure>
					</div>
				</div>
				<DiscountedServices />
			</div>
			{/* modal */}
			<div>
				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby='modal-modal-title'
					aria-describedby='modal-modal-description'
				>
					<Box sx={style}>
						<div className='gift'>
							<div className='crossbar'>
								<h5>{translation.Globals.gift}</h5>
								<div onClick={handleClose}>
									<figure>
										<img
											src='/static/images/crossicon.svg'
											alt=''
										/>
									</figure>
								</div>
							</div>
							<div className='logdata'>
								<figure>
									<img
										src='/static/images/giftimg.svg'
										alt=''
									/>
								</figure>
								<form
									className='inputdiv'
									onSubmit={formik.handleSubmit}
								>
									<TextField
										className='gift-inpt'
										placeholder={
											translation.PartnerWithUs
												.registration.email
										}
										id='email'
										{...formik.getFieldProps('email')}
										helperText={
											formik.touched.email &&
											formik.errors.email
										}
									/>
									<TextField
										placeholder={
											translation.Presale.modal
												.entermessage
										}
										className='gift-inpt'
										id='message'
										{...formik.getFieldProps('message')}
										helperText={
											formik.touched.message &&
											formik.errors.message
										}
										multiline
										maxRows={4}
									/>
									<div className='btndiv'>
										<button
											className='btn'
											type='submit'
											// onClick={handleClose}
										>
											{translation.Globals.submit}
										</button>
									</div>
								</form>
							</div>
						</div>
					</Box>
				</Modal>
				<Modal
					open={open2}
					onClose={handleClose2}
					aria-labelledby='modal-modal-title'
					aria-describedby='modal-modal-description'
				>
					<Box sx={style2}>
						<div className='plc-bid'>
							<div className='top-sec'>
								<div onClick={handleClose2}>
									<figure>
										<img
											src='/static/images/crossicon.svg'
											alt=''
										/>
									</figure>
								</div>
							</div>
							<div className='mdl-sec'>
								<div className='img-sec'>
									<figure>
										<img
											src='/static/images/place-bid-img.svg'
											alt=''
										/>
									</figure>
								</div>
								<div className='modl-info'>
									<h2>{translation.placeBid.lowestPrice}</h2>
									<p>
										{translation.placeBid.des}{' '}
										<span className='red_text'>
											{translation.placeBid.price}
										</span>
									</p>
									<p>
										{' '}
										{translation.placeBid.des2}{' '}
										<span className='green-text'>
											{translation.placeBid.price2}
										</span>{' '}
										<span>{translation.placeBid.des3}</span>
									</p>
								</div>
							</div>
							<Divider />
							<div className='modl-btns'>
								<button
									type='button'
									className='btn2'
									onClick={handleClose2}
								>
									{translation.Globals.cancel}
								</button>
								<button
									type='button'
									className='btn'
									onClick={() => navigate('/')}
								>
									{translation.Globals.proceed}
								</button>
							</div>
						</div>
					</Box>
				</Modal>
			</div>
		</Layout>
	);
};

export default PresaleDetails;
