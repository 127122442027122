import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import useTranslation from '../hooks/translation';
import { Coupons } from '../types.ts/General';

type CouponCardtype = {
	upgrade: any;
	setUpgrade: Dispatch<SetStateAction<any>>;
	data: Coupons;
	type: string;
	page: string;
	id: string;
	image: string;
	title: string;
	address: string;
	price: string;
	price2: string;
	navigateTo: () => void;
};

const CouponCard = ({
	upgrade,
	setUpgrade,
	data,
	type,
	page,
	id,
	image,
	title,
	address,
	price,
	price2,
	navigateTo,
}: CouponCardtype) => {
	const navigate = useNavigate();
	const translation = useTranslation() as any;

	const handleBuy = (e: any) => {
		e.stopPropagation();
		if (page === 'auction') {
			navigate('/details', { state: 'data' });
		} else if (page !== 'auction' && page !== 'discounted services') {
			navigate('/details', { state: 'presaleBuy' });
		} else {
			navigate('/details');
		}
	};
	const handleSell = (e: any) => {
		e.stopPropagation();
		navigate('/sell-coupons', { state: 'data' });
	};
	const handleUpgrade = (e: any) => {
		e.stopPropagation();
		setUpgrade(true);
	};
	const handleDiscountOffer = (e: any) => {
		e.stopPropagation();
		navigate('/details', { state: 'discounted_services' });
	};
	const handleButtons = () => {
		switch (type) {
			case 'buy':
				return (
					<div>
						{page === 'discounted services' ? (
							<button
								className='btn'
								type='button'
								onClick={handleDiscountOffer}
							>
								{translation.Globals.makeOffer}
							</button>
						) : (
							<button
								className='btn'
								type='button'
								onClick={handleBuy}
							>
								{translation.Globals.buy}
							</button>
						)}
					</div>
				);

			case 'sell':
				return (
					<div className='crd_btns'>
						<button
							className='btn'
							type='button'
							onClick={handleBuy}
						>
							{translation.Globals.makeOffer}
						</button>
						<button
							className='btn2'
							type='button'
							onClick={handleSell}
						>
							{translation.Globals.sell}
						</button>
					</div>
				);
			case 'upgrade':
				return (
					<button
						className='btn'
						type='button'
						onClick={handleUpgrade}
					>
						{translation.Globals.upgrade}
					</button>
				);

			default:
				return null;
		}
	};
	return (
		<div className='wishOrder'>
			<div
				className={page === 'auction' ? 'cpn_crd2' : 'cpn_crd'}
				onClick={navigateTo}
			>
				<div className='crd-img-mn'>
					<figure>
						<img alt='' src={image} />
					</figure>
					<div className='crd-div'>
						{/* <div className='crd-rtng'>
							<figure>
								<img
									src='/static/images/rating-star.svg'
									alt=''
								/>
							</figure>
							<h4>4.2</h4>
						</div> */}
						<div className='crd-like'>
							<figure>
								<img src='/static/images/like-img.svg' alt='' />
							</figure>
						</div>
					</div>
				</div>
				<h2>{title}</h2>
				<div className='ctgy_nm'>
					<h6>Skincare</h6>
				</div>
				<div className='adrss'>
					<PlaceOutlinedIcon />
					<p>{address}</p>
				</div>

				<div className='rtng-crd'>
					<figure>
						<img src='/static/images/Star-fill.png' alt='' />
					</figure>
					<figure>
						<img src='/static/images/Star-fill.png' alt='' />
					</figure>
					<figure>
						<img src='/static/images/Star-fill.png' alt='' />
					</figure>
					<figure>
						<img src='/static/images/Star-fill.png' alt='' />
					</figure>
					<figure>
						<img src='/static/images/Star-empty.png' alt='' />
					</figure>
				</div>

				<div
					className={
						type === 'buy' || type === 'upgrade' ? 'prc' : 'prc2'
					}
				>
					{page === 'auction' ||
					page === 'discounted services' ? null : (
						<div>
							<h2>
								{price}{' '}
								<span>
									<i>
										<s>{price2}</s>
									</i>
								</span>
							</h2>
						</div>
					)}
					<div>{handleButtons()}</div>
				</div>
			</div>
		</div>
	);
};

export default CouponCard;
