import {
	Autocomplete,
	Divider,
	InputAdornment,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import React, { Dispatch, SetStateAction, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { ProductFilters, SignUp } from '../../components';
import useTranslation from '../../hooks/translation';
import cardData from '../../data/slider.json';
import filterData from '../../data/ProductFilter.json';
import { DiscountedServices } from '../../features';
import Layout from '../../layout';
import './Product2.scss';

const Product2 = () => {
	const translation = useTranslation() as any;

	const navigate = useNavigate();
	const [service, setService] = React.useState('');
	const [screen, setScreen] = useState<number>(1);
	const [step, setStep] = useState<number>(1);
	const handleChange = (event: {
		target: { value: React.SetStateAction<string> };
	}) => {
		setService(event.target.value);
	};

	const sortOptions = [
		{ id: 1, option: translation.productFilter.new },
		{ id: 2, option: translation.productFilter.bestSelling },
		{ id: 3, option: translation.productFilter.featured },
		{ id: 4, option: translation.productFilter.onSale },
		{ id: 5, option: translation.productFilter.rating },
	];

	const navigateTo = () => {
		navigate('/product-detail');
	};

	return (
		<Layout>
			<div className='prdcts-lst'>
				<div className='container mn-prdcts-lst'>
					<div className='prdct-sectop'>
						<div className='lst-lft'>
							{filterData.data?.length
								? cardData.data?.map((item) => {
										return (
											<div className='mn-cpns-lst'>
												<ProductFilters key={item.id} />
											</div>
										);
								  })
								: null}
						</div>
						<div className='lst-ryt'>
							<div className='lst-ryt-top'>
								<h4>{translation.productFilter.allProducts}</h4>
								<div className='align_dropdown'>
									<Select
										value={service}
										onChange={handleChange}
										displayEmpty
										inputProps={{
											'aria-label': 'Without label',
										}}
										className='srvc-sel'
									>
										<MenuItem className='srcv-lst' value=''>
											{translation.productFilter.service}
										</MenuItem>
										<MenuItem
											className='srcv-lst'
											value='service1'
										>
											{translation.productFilter.service}
										</MenuItem>
										<MenuItem
											className='srcv-lst'
											value='service2'
										>
											{translation.productFilter.service}
										</MenuItem>
									</Select>
									<Autocomplete
										multiple
										id='tags-outlined'
										options={sortOptions}
										getOptionLabel={(option) =>
											option?.option
										}
										className='fltr-optns'
										renderInput={(params) => (
											<TextField
												{...params}
												placeholder={
													translation.productFilter
														.sort
												}
												InputProps={{
													...params.InputProps,
													startAdornment: (
														<>
															<InputAdornment position='start'>
																<figure>
																	<img
																		src='/static/images/sort-icn.svg'
																		alt=''
																	/>
																</figure>
															</InputAdornment>
															{
																params
																	.InputProps
																	.startAdornment
															}
														</>
													),
												}}
											/>
										)}
									/>
								</div>
							</div>
							<div className='no-ofr'>
								<h4>{translation.product2.noOffer}</h4>
							</div>
							<div className='signUp'>
								<SignUp
									setScreen={setScreen}
									step={step}
									setStep={setStep}
								/>
							</div>
						</div>
					</div>
				</div>
				<DiscountedServices />
			</div>
		</Layout>
	);
};

export default Product2;
