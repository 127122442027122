import {
	IconButton,
	Menu,
	MenuItem,
	Paper,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
} from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import useTranslation from '../../hooks/translation';

const ITEM_HEIGHT = 48;

const Payout = () => {
	const translation = useTranslation() as any;
	const options = [translation.Globals.edit, translation.account.delete];

	const data = [
		{
			id: 1,
			orderNo: '#2468',
			date: '	March 4, 2020',
			status: 'Sold',
			total: '$125.00 for 2 item',
			accNo: '045984901',
		},
		{
			id: 2,
			orderNo: '#2468',
			date: '	March 4, 2020',
			status: 'Published',
			total: '$125.00 for 2 item',
			accNo: '045984901',
		},
		{
			id: 3,
			orderNo: '#2468',
			date: '	March 4, 2020',
			status: 'Sold',
			total: '$125.00 for 2 item',
			accNo: '045984901',
		},
		{
			id: 4,
			orderNo: '#2468',
			date: '	March 4, 2020',
			status: 'Sold',
			total: '$125.00 for 2 item',
			accNo: '045984901',
		},
		{
			id: 5,
			orderNo: '#2468',
			date: '	March 4, 2020',
			status: 'Sold',
			total: '$125.00 for 2 item',
			accNo: '045984901',
		},
		{
			id: 6,
			orderNo: '#2468',
			date: '	March 4, 2020',
			status: 'Published',
			total: '$125.00 for 2 item',
			accNo: '045984901',
		},
	];

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div className='payoutmain'>
			<h3>{translation.account.bankAccount}</h3>
			<div className='detaildiv'>
				<div className='detail'>
					<div className='dleft'>
						<h4>{translation.account.holdername} </h4>
					</div>
					<div className='dright'>
						<h4>Josephin Villa</h4>
					</div>
				</div>
				<div className='detail'>
					<div className='dleft'>
						<h4>{translation.account.AccNO}</h4>
					</div>
					<div className='dright'>
						<h4>8014165879248</h4>
					</div>
				</div>
				<div className='detail'>
					<div className='dleft'>
						<h4>{translation.account.branchName}</h4>
					</div>
					<div className='dright'>
						<h4>Commercial Bank PLC</h4>
					</div>
				</div>
				<div className='detail'>
					<div className='dleft'>
						<h4>{translation.account.bankName}</h4>
					</div>
					<div className='dright'>
						<h4>Maharagama</h4>
					</div>
				</div>
			</div>

			<div className='reveivediv'>
				<div className='heading'>
					<figure>
						<img src='/static/images/payout.svg' alt='' />
					</figure>
					<h3>{translation.account.revieve}</h3>
				</div>
				<div className='maincontent'>
					<div className='mcleft'>
						<TextField
							id='filled-basic'
							placeholder='*****'
							fullWidth
						/>
						<TextField
							id='filled-basic'
							placeholder={translation.account.transitNo}
							fullWidth
						/>
						<TextField
							id='filled-basic'
							placeholder={translation.account.pNo}
							fullWidth
						/>
						<TextField
							id='filled-basic'
							placeholder={translation.account.IbanNo}
							fullWidth
						/>
						<div className='btndiv'>
							<button className='btnnn' type='button'>
								{translation.Globals.submit}
							</button>
						</div>
					</div>
					<div className='mcryt'>
						<div className='balance'>
							<h4>{translation.account.Balance} :</h4>
							<p>
								1230$ <ArrowDropUpIcon />
							</p>
						</div>

						<figure>
							<img src='/static/images/house.svg' alt='' />
						</figure>
					</div>
				</div>
			</div>

			<div className='tabledv'>
				<TableContainer component={Paper}>
					<Table sx={{ minWidth: 700 }} aria-label='customized table'>
						<TableHead>
							<TableRow>
								<TableCell>
									{translation.account.withdrawnID}
								</TableCell>
								<TableCell align='right'>
									{translation.account.wddate}
								</TableCell>
								<TableCell align='right'>
									{translation.account.status}
								</TableCell>
								<TableCell align='right'>
									{translation.account.fundDate}
								</TableCell>
								<TableCell align='right'>
									{translation.account.bankName}
								</TableCell>
								<TableCell align='right'>
									{translation.account.payaction}
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{data.map((item) => (
								<TableRow key={item.id}>
									<TableCell
										className='ordr-no'
										align='center'
									>
										<h4>{item.orderNo}</h4>
									</TableCell>
									<TableCell align='center'>
										<h4>{item.date}</h4>
									</TableCell>
									<TableCell
										className={
											item.status === 'Sold'
												? 'status'
												: 'status2'
										}
										align='center'
									>
										<h4>{item.status}</h4>
									</TableCell>
									<TableCell align='center'>
										<h4>{item.date}</h4>
									</TableCell>
									<TableCell align='center'>
										<h4>{item.accNo}</h4>
									</TableCell>
									<TableCell align='center'>
										<IconButton
											aria-label='more'
											id='long-button'
											aria-controls={
												open ? 'long-menu' : undefined
											}
											aria-expanded={
												open ? 'true' : undefined
											}
											aria-haspopup='true'
											onClick={handleClick}
											sx={{ position: 'relative' }}
										>
											<MoreHorizIcon />
										</IconButton>
										<Menu
											className='account_item'
											id='long-menu'
											MenuListProps={{
												'aria-labelledby':
													'long-button',
											}}
											anchorEl={anchorEl}
											open={open}
											onClose={handleClose}
											PaperProps={{
												style: {
													maxHeight:
														ITEM_HEIGHT * 4.5,
													width: '10ch',
												},
											}}
										>
											{options.map((option, i) => (
												<MenuItem
													// eslint-disable-next-line react/no-array-index-key
													key={i}
													selected={option === 'None'}
													onClick={handleClose}
												>
													{option}
												</MenuItem>
											))}
										</Menu>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</div>
	);
};

export default Payout;
