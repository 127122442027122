import React from 'react';
import {
	HowDoesCost,
	HowItWorks,
	Packages,
	PartnerBanner,
} from '../../features';
import useTranslation from '../../hooks/translation';
import Layout from '../../layout';
import './PartnerWithUs.scss';

const PartnerWithUs = () => {
	const translation = useTranslation() as any;

	const array = [
		{
			id: 1,
			count: '1,165',
			text: translation.PartnerWithUs.count.partner,
		},
		{
			id: 2,
			count: '954',
			text: translation.PartnerWithUs.count.clients,
		},
		{
			id: 3,
			count: '2,685',
			text: translation.PartnerWithUs.count.likes,
		},
		{
			id: 4,
			count: '24',
			text: translation.PartnerWithUs.count.Cities,
		},
	];
	return (
		<Layout>
			<PartnerBanner />
			<div className='container countmain'>
				{array.map((item) => {
					return (
						<div className='count' key={item.id}>
							<h2>
								{item?.count}
								<span>{item?.text}</span>
							</h2>
						</div>
					);
				})}
			</div>
			<Packages />
			<HowDoesCost />
			<HowItWorks />
		</Layout>
	);
};

export default PartnerWithUs;
