import React, { useState } from 'react';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import { SellCouponWork } from '../../types.ts/General';
import useTranslation from '../../hooks/translation';

type SellCouponCardtype = {
	data: SellCouponWork;
};

const SellCouponWorkflow = ({ data }: SellCouponCardtype) => {
	return (
		<div className='sellcpn_wrkcrd'>
			<div>
				<figure className='wrkcrd_img'>
					<img src={data.img} alt='' />
				</figure>

				<h4>{data.title}</h4>
				<p>{data.des}</p>
			</div>
			<figure className='crd_no'>
				<img src={data.img2} alt='' />
			</figure>
		</div>
	);
};

export default SellCouponWorkflow;
