import React, { useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
	CircularProgressbarWithChildren,
	buildStyles,
} from 'react-circular-progressbar';

type ScreenType = {
	screen: string;
	value: number;
};
const ProgressGraph = ({ screen, value }: ScreenType) => {
	const [percentage, setPercentage] = useState(25);

	return (
		<div>
			<div className={screen === 'myOrder' ? 'grph-size' : 'grph-size2'}>
				<CircularProgressbarWithChildren
					className='dsds'
					strokeWidth={12}
					value={percentage}
					styles={buildStyles({
						pathColor: value > 0 ? '#44B349' : 'red',
						trailColor: '#D6D4D4',
						strokeLinecap: 'round',
					})}
				>
					<div>
						{screen === 'myOrder' ? (
							<div>
								<figure>
									<img
										src='/static/images/green-tick2.svg'
										alt=''
									/>
								</figure>
							</div>
						) : (
							<div>
								{value < 0 ? (
									<figure className='arrw-img'>
										<img
											src='/static/images/down-arrow.svg'
											alt=''
										/>
									</figure>
								) : (
									<figure className='arrw-img'>
										<img
											src='/static/images/upward-arrow.svg'
											alt=''
										/>
									</figure>
								)}
							</div>
						)}
					</div>
				</CircularProgressbarWithChildren>
			</div>
		</div>
	);
};

export default ProgressGraph;
