import { Checkbox, Divider, Radio } from '@mui/material';
import React from 'react';
import useTranslation from '../hooks/translation';

const CheckAvailability = () => {
	const data = [
		{
			id: 1,
			heading:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
			option1:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
			option2:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
			option3:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
		},
		{
			id: 2,
			heading:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
			option1:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
			option2:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
			option3:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
		},
		{
			id: 3,
			heading:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
			option1:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
			option2:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
			option3:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
		},
		{
			id: 4,
			heading:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
			option1:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
			option2:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
			option3:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
		},
		{
			id: 5,
			heading:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
			option1:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
			option2:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
			option3:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
		},
		{
			id: 6,
			heading:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
			option1:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
			option2:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
			option3:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
		},
	];

	const [selectedValue, setSelectedValue] = React.useState('a');

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSelectedValue(event.target.value);
	};

	const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

	const translation = useTranslation() as any;
	return (
		<div className='mn-chck-avlbl'>
			<div className='check-avlbl'>
				<h2>
					{translation.account.checkAvailabilty.services}{' '}
					<span>{translation.account.checkAvailabilty.optional}</span>
				</h2>
				<p>{translation.account.checkAvailabilty.addUpgrades}</p>
				<Divider />

				{data.map((item) => {
					return (
						<div className='serv_list'>
							<div className='rdo'>
								<Radio
									checked={selectedValue === 'a'}
									onChange={handleChange}
									value='a'
									name='radio-buttons'
									inputProps={{ 'aria-label': 'A' }}
								/>
								<h4>{item.heading}</h4>
							</div>
							<div className='chk_list'>
								<div className='lst'>
									<Checkbox {...label} />
									<p>{item.option1}</p>
								</div>
								<div className='lst'>
									<Checkbox {...label} />
									<p>{item.option2}</p>
								</div>
								<div className='lst'>
									<Checkbox {...label} />
									<p>{item.option3}</p>
								</div>
							</div>
						</div>
					);
				})}
			</div>
			<div className='bk-aptmnt'>
				<h2>{translation.account.checkAvailabilty.bookAppointment}</h2>
				<Divider />
				<div className='check_date'>
					<div className='chk'>
						<h6>{translation.account.checkAvailabilty.date}</h6>
						<div>
							<input type='date' />
							{/* <figure>
								<img
									src='/static/images/calender-img.svg'
									alt=''
								/>
							</figure> */}
						</div>
					</div>
					<div className='avability'>
						<button className='btn2' type='button'>
							{
								translation.account.checkAvailabilty
									.checkAvailability
							}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CheckAvailability;
