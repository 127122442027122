import { useNavigate } from 'react-router-dom';
import { Divider } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { CouponCard } from '../../components';
import useTranslation from '../../hooks/translation';
import cardData from '../../data/slider.json';
import './BrowseCoupons.scss';

type Stepstype = {
	setStep: Dispatch<SetStateAction<number>>;
};
const CouponPrice = ({ setStep }: Stepstype) => {
	const translation = useTranslation() as any;
	const [upgrade, setUpgrade] = useState(false);

	const [activeStep, setActiveStep] = useState(1);

	const navigate = useNavigate();
	const navigateTo = () => {
		navigate('/');
	};

	const handleActiveSteps = () => {};
	return (
		<section className='cpn_prc'>
			<div className='container mn_cpn-dtls'>
				<div className='cpn-details-ryt'>
					<div>
						{cardData.data?.length
							? cardData.data?.slice(0, 1).map((item) => {
									return (
										<CouponCard
											upgrade={upgrade}
											setUpgrade={setUpgrade}
											type='no btns'
											page=' sellCouponNow'
											key={item.id}
											data={item}
											id={item.id}
											image={item.img}
											title={item.title}
											address={item.address}
											price={item.price}
											price2={item.price2}
											navigateTo={navigateTo}
										/>
									);
							  })
							: null}
					</div>
					<div className='cpn-sell-tips'>
						<h4>{translation.SellNow.sellCouponTips.tips}</h4>
						<Divider />
						<ul>
							<li>
								<p>{translation.SellNow.sellCouponTips.tip1}</p>
							</li>
							<li>
								<p>{translation.SellNow.sellCouponTips.tip2}</p>
							</li>
							<li>
								<p>{translation.SellNow.sellCouponTips.tip3}</p>
							</li>
							<li>
								<p>{translation.SellNow.sellCouponTips.tip4}</p>
							</li>
							<li>
								<p>{translation.SellNow.sellCouponTips.tip5}</p>
							</li>
							<li>
								<p>{translation.SellNow.sellCouponTips.tip6}</p>
							</li>
							<li>
								<p>{translation.SellNow.sellCouponTips.tip7}</p>
							</li>
						</ul>
					</div>
				</div>
				<div className='cpn-details-lft'>
					<div>
						<h2>{translation.SellNow.couponPrice.price}</h2>
					</div>
					<div className='mn-dtls-lft'>
						<div className='stppr-sec'>
							<div>
								<h4>
									{translation.SellNow.couponPrice.totalValue}
								</h4>
								<h2>$1026</h2>
							</div>
							<div>
								<h4>
									{
										translation.SellNow.couponPrice
											.presalePrice
									}
								</h4>
								<h2>$513</h2>
							</div>
						</div>
						<Divider />
						<div className='min-prc'>
							<h4>{translation.SellNow.couponPrice.minPrice}</h4>
							<div className='min-prc-inpt'>
								<input type='text' />
								<p>
									{translation.SellNow.couponPrice.perCoupon}
								</p>
							</div>
							<div className='sale-end'>
								<h4>
									{translation.SellNow.couponPrice.endDate}
								</h4>
								<div className='end-dt-optns'>
									<div className='end-dt'>
										<div className='end-dt-dta'>
											<input type='radio' />
											<p>
												{
													translation.SellNow
														.couponPrice.display
												}
											</p>
										</div>
										<div className='end-dt-dta'>
											<input type='radio' />
											<p>
												{
													translation.SellNow
														.couponPrice
														.stopDisplaying
												}
											</p>
										</div>
									</div>

									<div className='clndr-sec'>
										<input type='date' />
										{/* <figure>
											<img
												src='/static/images/calender-img.svg'
												alt=''
											/>
										</figure> */}
									</div>
								</div>
							</div>
						</div>
						<Divider />
						<div className='cpn-dtl-btns'>
							<button
								className='btn2'
								type='button'
								onClick={() => setStep(2)}
							>
								{translation.Globals.back}
							</button>

							<button
								className='btn'
								type='button'
								onClick={() => setStep(4)}
							>
								{translation.SellNow.couponPrice.setprice}
							</button>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default CouponPrice;
