import HomePage from './homepage';
import SellCoupons from './sellCoupons';
import SellCouponNow from './sellCouponNow';
import PartnerWithUs from './partnerWithUs';
import Authentication from './authentication';
import PartnerRegister from './partnerRegister';
import ContactUs from './contactUs';
import PrivacyPolicy from './privacyPolicy';
import Faq from './faq';
import HowItWorks from './HowItWorks';
import TermsAndCondition from './termsandCondition';
import PricingModel from './pricingModel';
import Products from './products';
import ProductDetail from './productDetail';
import OrderSuccessfull from './orderSuccessful';
import Account from './account';
import BookAppointment from './bookAppointment';
import Cart from './cart';
import PresaleCoupons from './presaleCoupons';
import CouponSaving from './couponSaving';
import PresaleDetails from './presaleDetails';
import Product2 from './product2';

export default {
	HomePage,
	SellCoupons,
	SellCouponNow,
	PartnerWithUs,
	Authentication,
	PartnerRegister,
	ContactUs,
	PrivacyPolicy,
	Faq,
	HowItWorks,
	TermsAndCondition,
	PricingModel,
	Products,
	ProductDetail,
	OrderSuccessfull,
	Account,
	BookAppointment,
	Cart,
	PresaleCoupons,
	CouponSaving,
	PresaleDetails,
	Product2,
};
