import React, { Dispatch, SetStateAction } from 'react';
import useTranslation from '../../hooks/translation';

type accountTab = {
	activeTab: number;
	setActiveTab: Dispatch<SetStateAction<number>>;
	image: any;
};

const Sidebar = ({ activeTab, setActiveTab, image }: accountTab) => {
	const translation = useTranslation() as any;

	const array = [
		{
			id: 1,
			img: '/static/images/lightbag.svg',
			text: translation.account.order,
			click: 1,
		},
		{
			id: 2,
			img: '/static/images/heart.svg',
			text: translation.account.wishlist,
			click: 2,
		},
		{
			id: 3,
			img: '/static/images/upgrade.svg',
			text: translation.account.upgrade,
			click: 3,
		},
		{
			id: 4,
			img: '/static/images/user.svg',
			text: translation.account.personalInfo,
			click: 4,
		},
		{
			id: 5,
			img: '/static/images/dashboard.svg',
			text: translation.account.dashboard,
			click: 5,
		},
		{
			id: 6,
			img: '/static/images/chat.svg',
			text: translation.account.chat,
			click: 6,
		},
		{
			id: 7,
			img: '/static/images/payout.svg',
			text: translation.account.payout,
			click: 7,
		},
		{
			id: 8,
			img: '/static/images/code.svg',
			text: translation.account.reference,
			click: 8,
		},
		{
			id: 9,
			img: '/static/images/logout.svg',
			text: translation.account.logout,
			click: 9,
		},
	];
	return (
		<div className='accsidebar'>
			<div className='acctop'>
				<div className='atleft' onClick={() => setActiveTab(4)}>
					<figure>
						<img
							src={image || '/static/images/user-img-ph.svg'}
							alt=''
						/>
					</figure>
				</div>
				<div className='atright'>
					<div onClick={() => setActiveTab(4)}>
						<h2>Sameera Madush</h2>
					</div>

					<div className='mebr'>
						<figure>
							<img src='/static/images/greystar.svg' alt='' />
						</figure>
						<h5>{translation.account.member}</h5>
					</div>
					<div className='bar'>
						<figure>
							<img src='/static/images/bar.svg' alt='' />
						</figure>
						<h5>85/100</h5>
					</div>
					<p>{translation.account.earn}</p>
				</div>
			</div>
			<div className='accbtm'>
				<ul>
					{array.map((item) => {
						return (
							<li key={item?.id}>
								<div
									className={
										activeTab === item?.click
											? 'accmain'
											: 'accmain2'
									}
									onClick={() => setActiveTab(item?.click)}
								>
									<figure>
										<img src={item.img} alt='' />
									</figure>
									<h3>{item.text}</h3>
								</div>
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
};

export default Sidebar;
