import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import useTranslation from '../../hooks/translation';
import Layout from '../../layout';
import './CouponSaving.scss';

const CouponSaving = () => {
	const translation = useTranslation() as any;

	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
	};

	const data = [
		{
			id: 1,
			sideImages: [
				'/static/images/ordr-smry-img.svg',
				'/static/images/pre-img-1.svg',
				'/static/images/pre-img2.svg',
				'/static/images/pre-img3.svg',
				'/static/images/pre-img4.svg',
				'/static/images/pre-img4.svg',
			],
			mainImage: '/static/images/ordr-smry-img.svg',
			daysLeft: '27',
			hoursLeft: '14',
			minutesLeft: '39',
			title: 'Centre Option Détente',
			subTitle: 'Simple Beaute, Esthetique',
			rating: '4.2',
			reviews: '255 reviews',
			description:
				"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.",
			city: 'BROMONT. ',
			completeAddress: '837 rue shefford, QC, J2L 1C4 aesthetic',
			price1: '$420.00',
			price2: '$1026',
			button: translation.Globals.viewDetails,
		},
		{
			id: 2,
			sideImages: [
				'/static/images/ordr-smry-img.svg',
				'/static/images/pre-img-1.svg',
				'/static/images/pre-img2.svg',
				'/static/images/pre-img3.svg',
				'/static/images/pre-img4.svg',
				'/static/images/pre-img4.svg',
			],
			mainImage: '/static/images/ordr-smry-img.svg',
			daysLeft: '27',
			hoursLeft: '14',
			minutesLeft: '39',
			title: 'Centre Option Détente',
			subTitle: 'Simple Beaute, Esthetique',
			rating: '4.2',
			reviews: '255 reviews',
			description:
				"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.",
			city: 'BROMONT. ',
			completeAddress: '837 rue shefford, QC, J2L 1C4 aesthetic',
			price1: '$420.00',
			price2: '$1026',
		},
		{
			id: 3,
			sideImages: [
				'/static/images/ordr-smry-img.svg',
				'/static/images/pre-img-1.svg',
				'/static/images/pre-img2.svg',
				'/static/images/pre-img3.svg',
				'/static/images/pre-img4.svg',
				'/static/images/pre-img4.svg',
			],
			mainImage: '/static/images/ordr-smry-img.svg',
			daysLeft: '27',
			hoursLeft: '14',
			minutesLeft: '39',
			title: 'Centre Option Détente',
			subTitle: 'Simple Beaute, Esthetique',
			rating: '4.2',
			reviews: '255 reviews',
			description:
				"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.",
			city: 'BROMONT. ',
			completeAddress: '837 rue shefford, QC, J2L 1C4 aesthetic',
			price1: '$420.00',
			price2: '$1026',
		},
	];
	return (
		<Layout>
			<div className='presalemain'>
				<div className='savingcoupn'>
					<div className='container'>
						<div className='bnrdiv'>
							<div className='sleft'>
								<h1>{translation.Presale.Banner.saveupto}</h1>
								<p>{translation.Presale.Banner.des}</p>
							</div>
							<div className='sryt'>
								<figure>
									<img
										src='/static/images/rocket.svg'
										alt=''
									/>
								</figure>
							</div>
						</div>
					</div>
				</div>
				<div className='container'>
					{data.map((item) => {
						return (
							<div className='coupcard'>
								<div className='cpnleft'>
									<div className='title-sec'>
										<h2>{item.title}</h2>
										<figure>
											<img
												src='static/images/Like.svg'
												alt=''
											/>
										</figure>
									</div>
									<h3>{item.subTitle}</h3>

									<div className='rtng'>
										<div className='rating'>
											<figure>
												<img
													src='static/images/rating-star.svg'
													alt=''
												/>
											</figure>
											<h6>{item.rating}</h6>
										</div>
										<p>({item.reviews})</p>
									</div>

									<p>{item.description}</p>
									<div className='addressdiv'>
										<figure>
											<img
												src='/static/images/map2.svg'
												alt=''
											/>
										</figure>
										<p>
											{item.city}
											<span>{item.completeAddress}</span>
										</p>
									</div>
									<div className='price'>
										<h3>{item.price1}</h3>
										<h4>{item.price2}</h4>
									</div>

									<div className='tym-lft'>
										<div>
											{item.daysLeft}
											<h6>{translation.Globals.days}</h6>
										</div>
										<div>
											{item.hoursLeft}
											<h6>{translation.Globals.hours}</h6>
										</div>
										<div>
											{item.minutesLeft}
											<h6>
												{translation.Globals.minutes}
											</h6>
										</div>
									</div>

									<div
										className='btndiv'
										onClick={handleOpen}
									>
										<h4>View more details</h4>
										<ArrowForwardIcon />
									</div>
								</div>
								<div className='cpnRight'>
									<div className='mainimg'>
										<figure>
											<img src={item.mainImage} alt='' />
										</figure>
									</div>

									<div className='allimgs'>
										{item.sideImages.map((ele) => {
											return (
												<figure>
													<img src={ele} alt='' />
												</figure>
											);
										})}
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
			<div>
				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby='modal-modal-title'
					aria-describedby='modal-modal-description'
				>
					<Box sx={style}>
						<div className='giftdiv'>
							<div className='heading'>
								<p>{translation.Presale.modal.giveGift}</p>
								<figure>
									<img
										src='/static/images/crossicon.svg'
										alt=''
									/>
								</figure>
							</div>
							<div className='content'>
								<figure>
									<img
										src='/static/images/giftimg.svg'
										alt=''
									/>
								</figure>
								<TextField
									id='filled-basic'
									variant='filled'
									placeholder={translation.signUp.email}
								/>
								<TextField
									id='filled-basic'
									variant='filled'
									placeholder={
										translation.Presale.modal.entermessage
									}
								/>
								<div className='btndiv'>
									<button type='button' className='btn'>
										{translation.Globals.submit}
									</button>
								</div>
							</div>
						</div>
					</Box>
				</Modal>
			</div>
		</Layout>
	);
};

export default CouponSaving;
