import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckAvailability, OrderSummary } from '../../components';
import useTranslation from '../../hooks/translation';
import Layout from '../../layout';
import './BookAppointment.scss';

// type Ordertype = {
// 	activeStep: number;
// 	setActiveStep: Dispatch<SetStateAction<number>>;
// };
const BookAppointment = () => {
	const translation = useTranslation() as any;
	const [activeStep, setActiveStep] = useState(1);
	const navigate = useNavigate();
	return (
		<Layout>
			<div className='book-apntmnt container'>
				<div className='book-appont-sec'>
					<CheckAvailability />
					<div className='shipping'>
						<button
							className='btn2'
							type='button'
							onClick={() => navigate('/cart')}
						>
							{
								translation.account.checkAvailabilty
									.continueShopping
							}
						</button>
						<button
							className='btn'
							type='button'
							onClick={() => navigate('/cart')}
						>
							{translation.account.checkAvailabilty.skip}
						</button>
					</div>
				</div>
				<div className='order-summry'>
					<OrderSummary
						setActiveStep={setActiveStep}
						activeStep={activeStep}
					/>
				</div>
			</div>
		</Layout>
	);
};

export default BookAppointment;
