import React from 'react';
import { PartnerRegistrationForm } from '../../features';
import Layout from '../../layout';
import './PartnerRegister.scss';

const PartnerRegister = () => {
	return (
		<Layout>
			<div className='pRegister'>
				<div className='prleft'>
					{/* <figure>
						<img src='/static/images/pregister1.svg' alt='' />
					</figure> */}
				</div>

				<div className='prRight'>
					{/* <figure>
						<img src='/static/images/pregister2.svg' alt='' />
					</figure> */}
				</div>
			</div>
			<PartnerRegistrationForm />
		</Layout>
	);
};

export default PartnerRegister;
