import React from 'react';
import { SellCouponWorkflow } from '../../components';
import useTranslation from '../../hooks/translation';

const SellCouponWork = () => {
	const translation = useTranslation() as any;
	const data = [
		{
			id: 1,
			img: '../static/images/post-coupon.svg',
			img2: '../static/images/1.svg',
			title: translation.SellCoupons.workflow.title.post,
			des: translation.SellCoupons.workflow.description.post,
		},
		{
			id: 2,
			img: '../static/images/coupon-sold.svg',
			img2: '../static/images/2.svg',
			title: translation.SellCoupons.workflow.title.sold,
			des: translation.SellCoupons.workflow.description.sold,
		},
		{
			id: 3,
			img: '../static/images/receive-email.svg',
			img2: '../static/images/3.svg',
			title: translation.SellCoupons.workflow.title.receive_email,
			des: translation.SellCoupons.workflow.description.receive_email,
		},
		{
			id: 4,
			img: '../static/images/send-e-coupons.svg',
			img2: '../static/images/4.svg',
			title: translation.SellCoupons.workflow.title.send_ecoupons,
			des: translation.SellCoupons.workflow.description.send_ecoupons,
		},
		{
			id: 5,
			img: '../static/images/get-paid.svg',
			img2: '../static/images/5.svg',
			title: translation.SellCoupons.workflow.title.getpaid,
			des: translation.SellCoupons.workflow.description.getpaid,
		},
	];
	return (
		<section className='sell-cpn-wrk'>
			<div className='mncpn-wrk'>
				<h2>
					{translation.SellCoupons.workflow.howDoes}
					<span>{translation.SellCoupons.workflow.work}</span>
				</h2>
				<div className='sellcpn-wrk-cntnt '>
					{data?.length
						? data?.map((item) => {
								return (
									<SellCouponWorkflow
										key={item.id}
										data={item}
									/>
								);
						  })
						: null}
				</div>
			</div>
		</section>
	);
};

export default SellCouponWork;
