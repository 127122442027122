import { useNavigate } from 'react-router-dom';
import useTranslation from '../../hooks/translation';

const Packages = () => {
	const translation = useTranslation() as any;
	const navigate = useNavigate();

	const array = [
		{
			id: 1,
			title: translation.PartnerWithUs.packages.pulicity,
			img: '/static/images/mail1.png',
		},
		{
			id: 2,
			title: translation.PartnerWithUs.packages.social,
			img: '/static/images/socialmedia1.png',
		},
		{
			id: 3,
			title: translation.PartnerWithUs.packages.seo,
			img: '/static/images/seo1.png',
		},
		{
			id: 4,
			title: translation.PartnerWithUs.packages.telephone,
			img: '/static/images/call.png',
		},
		{
			id: 5,
			title: translation.PartnerWithUs.packages.influencer,
			img: '/static/images/influencer1.png',
		},
		{
			id: 6,
			title: translation.PartnerWithUs.packages.email,
			img: '/static/images/email1.png',
		},
	];

	return (
		<div className='packagemain'>
			<div className='container '>
				<ul>
					<li className='choose'>
						<h3>{translation.PartnerWithUs.packages.heading}</h3>
						<p>{translation.PartnerWithUs.packages.description}</p>
						<div className='choosearrow'>
							<h6>{translation.PartnerWithUs.packages.choose}</h6>
							<figure>
								<img
									src='/static/images/chooseArrow.png'
									alt=''
								/>
							</figure>
						</div>
						<button
							style={{ marginTop: '6px' }}
							className='btn'
							type='button'
							onClick={() => navigate('/partner-register')}
						>
							{translation.Globals.setStarted}
						</button>
					</li>
					{array.map((item) => {
						return (
							<li>
								<div
									className='packagecard'
									onClick={() => navigate('/pricing-model')}
								>
									<h3>{item?.title}</h3>
									<figure>
										<img src={item?.img} alt='' />
									</figure>
								</div>
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
};

export default Packages;
