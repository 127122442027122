import React from 'react';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Player } from '@lottiefiles/react-lottie-player';
import useTranslation from '../../hooks/translation';

const PartnerBanner = () => {
	const navigate = useNavigate();
	const translation = useTranslation() as any;
	const speed = parseFloat('1');

	return (
		<div className='partnermain'>
			<div className='container partner'>
				<div className='pleft'>
					<h1>
						{translation.PartnerWithUs.banner.title}{' '}
						<span>
							{translation.PartnerWithUs.banner.titlespan}
						</span>
					</h1>
					<p>{translation.PartnerWithUs.banner.description}</p>
					<div className='btnDiv'>
						<button
							className='btn'
							type='button'
							onClick={() => navigate('/partner-register')}
						>
							{translation.Globals.setStarted}
						</button>
					</div>
				</div>
				<div className='pright'>
					<Player
						src='https://lottie.host/30bb6cc7-7737-497f-9a6c-23d1ef2b92f3/o4lOlXYB7C.json'
						className='prtnr-bnr-lottie'
						autoplay
						loop
						background='transparent'
						speed={speed}
					/>
				</div>
			</div>
		</div>
	);
};

export default PartnerBanner;
