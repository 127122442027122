import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Player } from '@lottiefiles/react-lottie-player';
import { PresaleCard } from '../../components';
import useTranslation from '../../hooks/translation';
import Layout from '../../layout';
import './PresaleCoupons.scss';

const PresaleCoupons = () => {
	const translation = useTranslation() as any;
	const navigate = useNavigate();
	const speed = parseFloat('1');
	const navigateTo = () => {
		navigate('/');
	};

	const data = [
		{
			id: 1,
			sideImages: [
				'/static/images/ordr-smry-img.svg',
				'/static/images/pre-img-1.svg',
				'/static/images/pre-img2.svg',
				'/static/images/pre-img3.svg',
				'/static/images/pre-img4.svg',
				'/static/images/pre-img4.svg',
			],
			mainImage: '/static/images/ordr-smry-img.svg',
			daysLeft: '27',
			hoursLeft: '14',
			minutesLeft: '39',
			title: 'Centre Option Détente',
			subTitle: 'Simple Beaute, Esthetique',
			rating: '4.2',
			reviews: '255 reviews',
			description:
				"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.",
			city: 'BROMONT. ',
			completeAddress: '837 rue shefford, QC, J2L 1C4 aesthetic',
			price1: '$420.00',
			price2: '$1026',
		},
		{
			id: 2,
			sideImages: [
				'/static/images/ordr-smry-img.svg',
				'/static/images/pre-img-1.svg',
				'/static/images/pre-img2.svg',
				'/static/images/pre-img3.svg',
				'/static/images/pre-img4.svg',
				'/static/images/pre-img4.svg',
			],
			mainImage: '/static/images/ordr-smry-img.svg',
			daysLeft: '27',
			hoursLeft: '14',
			minutesLeft: '39',
			title: 'Centre Option Détente',
			subTitle: 'Simple Beaute, Esthetique',
			rating: '4.2',
			reviews: '255 reviews',
			description:
				"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.",
			city: 'BROMONT. ',
			completeAddress: '837 rue shefford, QC, J2L 1C4 aesthetic',
			price1: '$420.00',
			price2: '$1026',
		},
		{
			id: 3,
			sideImages: [
				'/static/images/ordr-smry-img.svg',
				'/static/images/pre-img-1.svg',
				'/static/images/pre-img2.svg',
				'/static/images/pre-img3.svg',
				'/static/images/pre-img4.svg',
				'/static/images/pre-img4.svg',
			],
			mainImage: '/static/images/ordr-smry-img.svg',
			daysLeft: '27',
			hoursLeft: '14',
			minutesLeft: '39',
			title: 'Centre Option Détente',
			subTitle: 'Simple Beaute, Esthetique',
			rating: '4.2',
			reviews: '255 reviews',
			description:
				"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.",
			city: 'BROMONT. ',
			completeAddress: '837 rue shefford, QC, J2L 1C4 aesthetic',
			price1: '$420.00',
			price2: '$1026',
		},
		{
			id: 4,
			sideImages: [
				'/static/images/ordr-smry-img.svg',
				'/static/images/pre-img-1.svg',
				'/static/images/pre-img2.svg',
				'/static/images/pre-img3.svg',
				'/static/images/pre-img4.svg',
				'/static/images/pre-img4.svg',
			],
			mainImage: '/static/images/ordr-smry-img.svg',
			daysLeft: '27',
			hoursLeft: '14',
			minutesLeft: '39',
			title: 'Centre Option Détente',
			subTitle: 'Simple Beaute, Esthetique',
			rating: '4.2',
			reviews: '255 reviews',
			description:
				"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.",
			city: 'BROMONT. ',
			completeAddress: '837 rue shefford, QC, J2L 1C4 aesthetic',
			price1: '$420.00',
			price2: '$1026',
		},
	];

	return (
		<Layout>
			<div className='presale-pg'>
				<div className='pre-bg'>
					<div className='mn-pre container'>
						<div className='pre-lft'>
							<h1>{translation.Presale.Banner.save}</h1>
							<p>{translation.Presale.Banner.des}</p>
						</div>
						<div className='pre-ryt'>
							{/* <figure>
								<img src='/static/images/Stars.svg' alt='' />
							</figure> */}
							<Player
								src='https://lottie.host/909ebaa5-62e7-4fa6-ae39-da2d2fe1535f/OY7Q2oPmeo.json'
								className='pre_lottie'
								autoplay
								loop
								background='transparent'
								speed={speed}
							/>
						</div>
					</div>
				</div>
				<div className='container presale_crds_sec'>
					{data?.length
						? data?.map((item) => {
								return (
									<PresaleCard
										navigateTo={navigateTo}
										key={item.id}
										id={item.id}
										sideImages={item.sideImages}
										image={item.mainImage}
										daysLeft={item.daysLeft}
										hoursLeft={item.hoursLeft}
										minutesLeft={item.minutesLeft}
										title={item.title}
										subTitle={item.subTitle}
										rating={item.rating}
										reviews={item.reviews}
										description={item.description}
										city={item.city}
										completeAddress={item.completeAddress}
										price1={item.price1}
										price2={item.price2}
									/>
								);
						  })
						: null}
				</div>
			</div>
		</Layout>
	);
};

export default PresaleCoupons;
