import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import useTranslation from '../../hooks/translation';
import './Chatwithus.scss';

const ReferenceCode = () => {
	const translation = useTranslation() as any;
	const [open, setOpen] = useState(false);
	const [upgrade, setUpgrade] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 600,
		bgcolor: 'background.paper',
		boxShadow: 24,
		border: 'none',
		borderRadius: 4,
	};
	return (
		<div className='refermain'>
			<div className='rtop'>
				<div className='rtleft'>
					<figure>
						<img src='/static/images/codehand.svg' alt='' />
					</figure>
				</div>
				<div className='rtRyt'>
					<h2>{translation.account.earndiscount}</h2>
					<p>{translation.account.sharediscount}</p>
					<div onClick={handleOpen} className='btndiv'>
						<button type='button' className='btn'>
							{translation.Globals.invite}
						</button>
					</div>
				</div>
			</div>
			<div className='rBtm'>
				<div className='rtRyt'>
					<h2>{translation.account.earndiscount2}</h2>
					<p>{translation.account.sharediscount2}</p>
					<div className='btndiv'>
						<button type='button' className='btn'>
							{translation.Globals.merchant}
						</button>
					</div>
				</div>
				<div className='rtleft'>
					<figure>
						<img src='/static/images/codehorn.svg' alt='' />
					</figure>
				</div>
			</div>
			{/* modal */}
			<div className='modolstyle'>
				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby='modal-modal-title'
					aria-describedby='modal-modal-description'
				>
					<Box sx={style}>
						<div className='sharemodal'>
							<div className='crossbar' onClick={handleClose}>
								<figure>
									<img
										src='/static/images/crossicon.svg'
										alt=''
									/>
								</figure>
							</div>
							<div className='sharedata'>
								<div className='Gmail'>
									<figure>
										<img
											src='/static/images/gmailimg.svg'
											alt=''
										/>
									</figure>
									<h5>{translation.account.shareemail}</h5>
								</div>

								<div className='sharediv'>
									<h3>{translation.account.share}</h3>
									<ul>
										<li>
											<figure>
												<img
													src='../static/images/facebook.svg'
													alt=''
												/>
											</figure>
										</li>
										<li>
											<figure>
												<img
													src='../static/images/instagram.svg'
													alt=''
												/>
											</figure>
										</li>
										<li>
											<figure>
												<img
													src='../static/images/twitter.svg'
													alt=''
												/>
											</figure>
										</li>
										<li>
											<figure>
												<img
													src='../static/images/linkedin.svg'
													alt=''
												/>
											</figure>
										</li>
										<li>
											<figure>
												<img
													src='../static/images/youtube.svg'
													alt=''
												/>
											</figure>
										</li>
									</ul>
									<h3>{translation.account.copyLink}</h3>
									<div className='copycode'>
										<TextField
											placeholder='Enter code'
											value='https://dribble.com/tags/share_ui-pattern'
										/>
										<h3>{translation.account.copy}</h3>
									</div>
								</div>
							</div>
						</div>
					</Box>
				</Modal>
			</div>
		</div>
	);
};

export default ReferenceCode;
