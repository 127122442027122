import React from 'react';
import useTranslation from '../../hooks/translation';
import './HotDeals.scss';

const Features = () => {
	const translation = useTranslation() as any;
	const data = [
		{
			id: 1,
			img: '../static/images/dlvry_trck.svg',
			title: translation.Home.Features.cod,
			description: translation.Home.Features.cod_des,
		},
		{
			id: 2,
			img: '../static/images/payment.svg',
			title: translation.Home.Features.payment,
			description: translation.Home.Features.pay_des,
		},
		{
			id: 3,
			img: '../static/images/security.svg',
			title: translation.Home.Features.confidence,
			description: translation.Home.Features.confi_des,
		},
		{
			id: 4,
			img: '../static/images/support.svg',
			title: translation.Home.Features.support,
			description: translation.Home.Features.supprt_des,
		},
	];

	return (
		<section className='ftrs container'>
			<ul className='container mn_ftrs'>
				{data.map((item, i) => {
					return (
						<li key={item.id}>
							<figure>
								<img src={item.img} alt='' />
							</figure>
							<h2>{item.title}</h2>
							<p>{item.description}</p>
						</li>
					);
				})}
			</ul>
		</section>
	);
};

export default Features;
