// eslint-disable-next-line import/no-extraneous-dependencies
import { useFormik } from 'formik';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Yup from 'yup';
// eslint-disable-next-line import/no-extraneous-dependencies
import PhoneInput from 'react-phone-input-2';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-phone-input-2/lib/style.css';
import { useNavigate } from 'react-router-dom';
import { TextField } from '@mui/material';
import React, { useState } from 'react';
import useTranslation from '../../hooks/translation';
import Layout from '../../layout';
import './ContactUs.scss';

const ContactUs = () => {
	const translation = useTranslation() as any;
	const navigate = useNavigate();
	const [phoneNumber, setPhoneNumber] = useState('');

	const [countryName, setCountryName] = useState('in');
	const [phoneCode, setPhoneCode] = useState('+91');

	const formik = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			email: '',
			phone: '',
			topic: '',
			message: '',
		},
		validationSchema: Yup.object({
			firstName: Yup.string()
				.max(15, 'Must be 15 characters or less')
				.required('This field is required')
				.min(3, 'Must be atleast 3 characters'),
			lastName: Yup.string()
				.max(20, 'Must be 20 characters or less')
				.required('This field is required')
				.min(3, 'Must be atleast 3 characters'),
			email: Yup.string()
				// .email('Must be valid email address')
				.required('This field is required')
				.matches(
					/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
					'Must be a valid email.',
				),
			phone: Yup.number()
				.typeError('Must be a valid number.')
				.positive('Must be a valid number.')
				.integer('Must be a valid number.')
				.min(8)
				.required('This field is required.')
				.label('Phone number'),
			topic: Yup.string().required('This field is required'),
			message: Yup.string().required('This field is required'),
		}),
		onSubmit: async () => {
			formik.setSubmitting(true);
			navigate('/');
			formik.setSubmitting(false);
		},
	});

	// for country picker
	const handleChangePhone = (phone: any, country: any) => {
		setPhoneCode(country?.dialCode);
		formik.setFieldValue('phone', phone?.replace(country.dialCode, ''));
		setCountryName(country?.countryCode);
		// console.log(phone, 'kkk');
		// console.log(country, 'kppp');
	};

	return (
		<Layout>
			<div className='contactmain'>
				<div className='contactbnr'>
					<div className='container'>
						<h1>{translation.Cms.contact}</h1>
					</div>
				</div>
				<div className='container'>
					<div className='cForm'>
						<div className='cfleft'>
							<h3>{translation.Cms.getInTouch}</h3>
							<p>{translation.Cms.contactdes}</p>
							<form onSubmit={formik.handleSubmit}>
								<div className='personal'>
									<div className='email-div'>
										<TextField
											className='cntct-inpt'
											variant='outlined'
											placeholder={
												translation.PartnerWithUs
													.registration.firstName
											}
											id='firstName'
											type='text'
											{...formik.getFieldProps(
												'firstName',
											)}
											helperText={
												formik.touched.firstName &&
												formik.errors.firstName
											}
										/>

										<TextField
											variant='outlined'
											className='cntct-inpt'
											placeholder={
												translation.PartnerWithUs
													.registration.lastName
											}
											id='lastName'
											type='text'
											{...formik.getFieldProps(
												'lastName',
											)}
											helperText={
												formik.touched.lastName &&
												formik.errors.lastName
											}
										/>
									</div>
									<div className='email-div'>
										<TextField
											variant='outlined'
											className='cntct-inpt'
											placeholder={
												translation.PartnerWithUs
													.registration.email
											}
											id='email'
											{...formik.getFieldProps('email')}
											helperText={
												formik.touched.email &&
												formik.errors.email
											}
										/>
										{/* <TextField
										type='number'
										variant='filled'
										placeholder={
											translation.PartnerWithUs
												.registration.PhoneNo
										}
										id='phone'
										{...formik.getFieldProps('phone')}
										helperText={
											formik.touched.phone &&
											formik.errors.phone
										}
									/> */}

										<div className='phoneinputt'>
											<PhoneInput
												// country='us'
												value={
													phoneCode +
													formik.values.phone
												}
												onChange={(phone, country) =>
													handleChangePhone(
														phone,
														country,
													)
												}
												// onChange={() => {
												// 	setPhoneNumber(phoneNumber);
												// }}
											/>
											{formik.touched.phone &&
											formik.errors.phone ? (
												<div className='validation_msg'>
													{formik.touched.phone &&
														formik.errors.phone}
												</div>
											) : (
												''
											)}
										</div>
									</div>
								</div>
								<div className='bussiness'>
									<TextField
										className='cntct-inpt'
										variant='outlined'
										placeholder={
											translation.PartnerWithUs
												.registration.topic
										}
										id='topic'
										{...formik.getFieldProps('topic')}
										helperText={
											formik.touched.topic &&
											formik.errors.topic
										}
									/>
									<TextField
										className='cntct-inpt'
										variant='outlined'
										multiline
										rows={5}
										placeholder={
											translation.PartnerWithUs
												.registration.message
										}
										id='message'
										{...formik.getFieldProps('message')}
										helperText={
											formik.touched.message &&
											formik.errors.message
										}
									/>
								</div>

								<div className='register'>
									<button
										className='btn'
										type='submit'
										disabled={formik.isSubmitting}
									>
										{translation.Globals.sendNow}
									</button>
								</div>
							</form>
						</div>
						<div className='cfRight'>
							<div className='cont_info'>
								<h4>{translation.Cms.contactInfo}</h4>
								<ul>
									<li>
										<div className='cOption'>
											<div className='cOleft'>
												<figure>
													<img
														src='/static/images/mail.svg'
														alt=''
													/>
												</figure>
											</div>
											<div className='cOright'>
												<h5>{translation.Cms.email}</h5>
												<h6 className='underline'>
													{
														translation.Cms
															.coupifymail
													}
												</h6>
											</div>
										</div>
									</li>
									<li>
										<div className='cOption'>
											<div className='cOleft'>
												<figure>
													<img
														src='/static/images/contactcall.svg'
														alt=''
													/>
												</figure>
											</div>
											<div className='cOright'>
												<h5>
													{translation.Cms.helpline}
												</h5>
												<h6>
													{translation.Cms.helplineNo}
													|{' '}
													<span>
														{
															translation.Cms
																.helpline2
														}
													</span>
												</h6>
											</div>
										</div>
									</li>
									<li>
										<div className='cOption'>
											<div className='cOleft'>
												<figure>
													<img
														src='/static/images/location.svg'
														alt=''
													/>
												</figure>
											</div>
											<div className='cOright'>
												<h5>
													{translation.Cms.location}
												</h5>
												<h6>
													{translation.Cms.address}
												</h6>
											</div>
										</div>
									</li>
								</ul>
								<ul className='cotct_icons'>
									<li>
										<figure>
											<img
												src='/static/images/facebook-circle-fill.svg'
												alt=''
											/>
										</figure>
									</li>
									<li>
										<figure>
											<img
												src='/static/images/instagram-fill.svg'
												alt=''
											/>
										</figure>
									</li>
									<li>
										<figure>
											<img
												src='/static/images/linkedin-fill.svg'
												alt=''
											/>
										</figure>
									</li>
									<li>
										<figure>
											<img
												src='/static/images/twitter-fill.svg'
												alt=''
											/>
										</figure>
									</li>
									<li>
										<figure>
											<img
												src='/static/images/youtube-fill.svg'
												alt=''
											/>
										</figure>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default ContactUs;
