import React from 'react';
import useTranslation from '../../hooks/translation';
import Layout from '../../layout';
import './HowItWorks.scss';

const HowItWorks = () => {
	const translation = useTranslation() as any;

	const array = [
		{
			id: 1,
			title: translation.Cms.how1,
			description: translation.Cms.howpara1,
		},
		{
			id: 2,
			title: translation.Cms.how2,
			description: translation.Cms.howpara2,
		},
		{
			id: 3,
			title: translation.Cms.how3,
			description: translation.Cms.howpara3,
		},
		{
			id: 4,
			title: translation.Cms.how4,
			description: translation.Cms.howpara4,
		},
		{
			id: 5,
			title: translation.Cms.how5,
			description: translation.Cms.howpara5,
		},
	];

	const array2 = [
		{
			id: 1,
			title: translation.Cms.how6,
			description: translation.Cms.howpara6,
		},
		{
			id: 2,
			title: translation.Cms.how7,
			description: translation.Cms.howpara7,
		},
		{
			id: 3,
			title: translation.Cms.how8,
			description: translation.Cms.howpara8,
		},
		{
			id: 4,
			title: translation.Cms.how9,
			description: translation.Cms.howpara9,
		},
	];

	return (
		<Layout>
			<div className='contactmain howitwork'>
				<div className=' privacybnr'>
					<div className='container'>
						<div className='ppbanner'>
							<div className='ppleft'>
								<h1>{translation.Cms.howitwork}</h1>
								<h3>{translation.Cms.ourCategories}</h3>
							</div>
							<div className='ppright'>
								<figure>
									<img
										src='/static/images/itworksbnr.svg'
										alt=''
									/>
								</figure>
							</div>
						</div>
					</div>
				</div>
				<div className='container'>
					<div className='tickets'>
						<ul>
							{array.map((item) => {
								return (
									<li>
										<h3>{item?.title}</h3>
										<p>{item?.description}</p>
									</li>
								);
							})}
						</ul>
					</div>

					<div className='tickets2'>
						<h3>{translation.Cms.simple}</h3>
						<ul>
							{array2.map((item) => {
								return (
									<li>
										<h3>{item?.title}</h3>
										<p>{item?.description}</p>
									</li>
								);
							})}
						</ul>
					</div>

					<div className='termsofuse'>
						<h5>
							{translation.Cms.contacthow}
							<span>{translation.Cms.howspan}</span>
						</h5>
						<p>{translation.Cms.termsUse}</p>
						<p>{translation.Cms.sendEmail}</p>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default HowItWorks;
