/* eslint-disable react/no-array-index-key */
import {
	Checkbox,
	IconButton,
	Menu,
	MenuItem,
	MenuList,
	Paper,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import useTranslation from '../../hooks/translation';
import { ProgressGraph } from '../../components';
import './MyOrder.scss';

const MyOrder = () => {
	const translation = useTranslation() as any;
	const [track, setTrack] = useState(false);
	const [trackStatus, setTrackStatus] = useState(1);
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	function toggleMenu() {
		setIsMenuOpen(!isMenuOpen);
	}

	const data = [
		{
			id: 1,
			orderNo: '#2468',
			date: '	March 45, 2020',
			status: 'Processing',
			total: '$125.00 for 2 item',
		},
		{
			id: 2,
			orderNo: '#2468',
			date: '	March 45, 2020',
			status: 'Completed',
			total: '$125.00 for 2 item',
		},
		{
			id: 3,
			orderNo: '#2468',
			date: '	March 45, 2020',
			status: 'Completed',
			total: '$125.00 for 2 item',
		},
	];

	const array = [
		{
			id: 1,
			productname: 'Oderm',
			des: 'Simple Beaute, Esthetique',
			price: '$ 420.00',
			subdescription: 'You have three services out of eight',
			rating: '3/8',
			img: '/static/images/ordr-smry-img.svg',
			checks: [
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
			],
		},
		{
			id: 2,
			productname: 'Oderm',
			des: 'Simple Beaute, Esthetique',
			price: '$ 420.00',
			subdescription: 'You have three services out of eight',
			rating: '3/8',
			img: '/static/images/ordr-smry-img.svg',
			checks: [
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
			],
		},
	];
	const options = [translation.Globals.view, translation.Globals.message];
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const ITEM_HEIGHT = 48;

	return (
		<div className='myOrder'>
			{!track ? (
				<div className='ormain'>
					<div className='tbldv'>
						<TableContainer component={Paper}>
							<Table
								sx={{ minWidth: 700 }}
								aria-label='customized table'
							>
								<TableHead>
									<TableRow>
										<TableCell>
											{translation.account.order1}
										</TableCell>
										<TableCell align='right'>
											{translation.account.date}
										</TableCell>
										<TableCell align='right'>
											{translation.account.status}
										</TableCell>
										<TableCell align='right'>
											{translation.account.total}
										</TableCell>
										<TableCell align='right'>
											{translation.account.action}
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data.map((item) => (
										<TableRow key={item.id}>
											<TableCell
												className='ordr-no'
												align='center'
											>
												<h4>{item.orderNo}</h4>
											</TableCell>
											<TableCell align='center'>
												<h4>{item.date}</h4>
											</TableCell>
											<TableCell
												className={
													item.status === 'Processing'
														? 'status'
														: 'status2'
												}
												align='center'
											>
												<h4>{item.status}</h4>
											</TableCell>
											<TableCell align='center'>
												<h4>{item.total}</h4>
											</TableCell>

											<TableCell align='center'>
												<IconButton
													aria-label='more'
													id='long-button'
													aria-controls={
														open
															? 'long-menu'
															: undefined
													}
													aria-expanded={
														open
															? 'true'
															: undefined
													}
													aria-haspopup='true'
													onClick={handleClick}
													sx={{
														position: 'relative',
													}}
												>
													<MoreHorizIcon />
												</IconButton>
												<Menu
													id='long-menu'
													className='account_item'
													MenuListProps={{
														'aria-labelledby':
															'long-button',
													}}
													anchorEl={anchorEl}
													open={open}
													onClose={handleClose}
													PaperProps={{
														style: {
															maxHeight:
																ITEM_HEIGHT *
																4.5,
															width: '10ch',
														},
													}}
												>
													{options.map((option) => (
														<MenuItem
															key={option}
															selected={
																option ===
																'None'
															}
															onClick={
																handleClose
															}
														>
															{option}
														</MenuItem>
													))}
												</Menu>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</div>
					<div className='orderMain'>
						{array.map((item) => {
							return (
								<div className='productt' key={item.id}>
									<div className='prtop'>
										<div className='prleft'>
											<div
												className='plleft'
												onClick={() => setTrack(true)}
											>
												<figure>
													<img
														src={item?.img}
														alt=''
													/>
												</figure>
											</div>
											<div className='plright'>
												<h3>{item?.productname}</h3>
												<p>{item?.des}</p>
												<h4>{item?.price}</h4>
												<div className='bar'>
													<figure>
														<img
															src='/static/images/bar.svg'
															alt=''
														/>
													</figure>
													<h5>{item?.rating}</h5>
												</div>
												<p className='services'>
													{item?.subdescription}
												</p>
											</div>
										</div>
										<div className='prRgt'>
											<ProgressGraph
												screen='myOrder'
												value={1}
											/>
										</div>
									</div>
									<div className='prbtm'>
										<ul>
											{item?.checks.map((ele, index) => {
												return (
													<li key={index}>
														<div className='cLeft'>
															<Checkbox className='chckbx-clr' />
														</div>
														<div className='cRight'>
															<p>{ele}</p>
														</div>
													</li>
												);
											})}
										</ul>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			) : (
				<div className='trackDiv'>
					<ul>
						<li>
							<h3>{translation.account.step1}</h3>
							<div
								className={
									trackStatus >= 1 ? 'stepfig' : 'stepfig2'
								}
								onClick={() => setTrackStatus(1)}
							>
								<figure>
									<img
										src='/static/images/orderCart.svg'
										alt=''
									/>
								</figure>
							</div>
							<h5>{translation.account.placeorder}</h5>
							<p>
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit
							</p>
						</li>
						<li>
							<h3>{translation.account.step2}</h3>
							<div
								className={
									trackStatus >= 2 ? 'stepfig' : 'stepfig2'
								}
								onClick={() => setTrackStatus(2)}
							>
								<figure>
									<img
										src='/static/images/ordercar.svg'
										alt=''
									/>
								</figure>
							</div>
							<h5>{translation.account.orderrecive}</h5>
							<p>
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit
							</p>
						</li>
						<li>
							<h3>{translation.account.step3}</h3>
							<div
								className={
									trackStatus >= 3 ? 'stepfig' : 'stepfig2'
								}
								onClick={() => setTrackStatus(3)}
							>
								<figure>
									<img
										src='/static/images/orderhouse.svg'
										alt=''
									/>
								</figure>
							</div>
							<h5>{translation.account.emailsent}</h5>
							<p>
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit
							</p>
						</li>
						<li>
							<h3>{translation.account.step4}</h3>
							<div
								className={
									trackStatus >= 4 ? 'stepfig' : 'stepfig2'
								}
								onClick={() => setTrackStatus(4)}
							>
								<figure>
									<img
										src='/static/images/orderhand.svg'
										alt=''
									/>
								</figure>
							</div>
							<h5>{translation.account.orderRecieved}</h5>
							<p>
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit
							</p>
						</li>
					</ul>
				</div>
			)}
		</div>
	);
};

export default MyOrder;
