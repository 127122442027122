import React from 'react';
import { useNavigate } from 'react-router-dom';

import useTranslation from '../../hooks/translation';

const Categories = () => {
	const translation = useTranslation() as any;
	const navigate = useNavigate();
	const data = [
		{
			id: 1,
			image: '../static/images/facial_massage.svg',
			category: translation.Home.Category.spa,
		},
		{
			id: 2,
			image: '../static/images/garage.svg',
			category: translation.Home.Category.garage,
		},
		{
			id: 3,
			image: '../static/images/beauty.svg',
			category: translation.Home.Category.beauty,
		},
		{
			id: 4,
			image: '../static/images/restaurant.svg',
			category: translation.Home.Category.restaurant,
		},
		{
			id: 5,
			image: '../static/images/gym.svg',
			category: translation.Home.Category.gym,
		},
		{
			id: 6,
			image: '../static/images/cinema.svg',
			category: translation.Home.Category.cinema,
		},
		{
			id: 7,
			image: '../static/images/mesotherapy.svg',
			category: translation.Home.Category.Mesotherapy,
		},
		{
			id: 8,
			image: '../static/images/hair_dresser.svg',
			category: translation.Home.Category.hair,
		},
	];

	return (
		<section className='cat_gries'>
			<div className='container '>
				<h2>
					{translation.Home.Category.all}
					<span className='undrln_hdng'>
						{translation.Home.Category.categories}
					</span>
				</h2>
				<div className='mn_categories'>
					{data.map((item) => {
						return (
							<div
								key={item.id}
								onClick={() => navigate('/products')}
							>
								<figure>
									<img src={item.image} alt='' />
								</figure>
								<h4>{item.category}</h4>
							</div>
						);
					})}
				</div>
			</div>
		</section>
	);
};

export default Categories;
