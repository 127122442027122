import React, { useState } from 'react';
import useTranslation from '../../hooks/translation';
import { ProgressGraph } from '../../components';

const Dashboard = () => {
	const translation = useTranslation() as any;
	const data = [
		{
			id: 1,
			image: '/static/images/shopping.svg',
			pointname: translation.account.shoppingPoint,
			points: 2,
			rating: '/static/images/rate.svg',
		},
		{
			id: 2,
			image: '/static/images/doubleuser.svg',
			pointname: translation.account.fanPoint,
			points: -1,
			rating: '/static/images/rate.svg',
		},
		{
			id: 3,
			image: '/static/images/gift.svg',
			pointname: translation.account.bonusPoint,
			points: 3,
			rating: '/static/images/rate.svg',
		},
	];
	return (
		<div className='dashboardmain'>
			{/* <h3>Hi Sameera !</h3>
			<p>{translation.account.dashboardtext}</p> */}

			<div className='dtop'>
				<ul>
					<li>
						<figure>
							<img src='/static/images/star1.svg' alt='' />
						</figure>
						<h6>{translation.account.bronz}</h6>
						<p>0-50%</p>
					</li>

					<li>
						<figure>
							<img src='/static/images/maskgirl.svg' alt='' />
						</figure>
						<figure className='silverstar'>
							<img src='/static/images/silverStar.svg' alt='' />
						</figure>
						<h6>{translation.account.silver}</h6>
						<p>50-100%</p>
					</li>
					<li>
						<figure>
							<img src='/static/images/star2.svg' alt='' />
						</figure>
						<h6>{translation.account.gold}</h6>
						<p>100%</p>
					</li>
				</ul>
			</div>
			<div className='dBtm'>
				<div className='dBleft'>
					<p>
						{translation.account.totalpoint} 2021-09 - 2022-08:
						<span>04</span>
					</p>

					<div className='dBright'>
						<ul>
							{data.map((item) => {
								return (
									<li key={item.id}>
										<div className='dashline'>
											<div className='dashicon'>
												<figure>
													<img
														src={item.image}
														alt=''
													/>
												</figure>
												<h5>{item.pointname}</h5>
											</div>
											<div className='dashpoint'>
												<p>{item.points}</p>
												<ProgressGraph
													screen='dashboard'
													value={item.points}
												/>

												{/* <figure>
													<img
														src={item.rating}
														alt=''
													/>
												</figure> */}
											</div>
										</div>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
				<div className='dBRight'>
					<div className='perntdiv'>
						<div className='pernt'>
							<h2>85%</h2>
						</div>
					</div>

					<figure>
						<img src='/static/images/ratingbar.svg' alt='' />
					</figure>
					<p>{translation.account.earn}</p>
				</div>
			</div>
		</div>
	);
};

export default Dashboard;
