import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Divider } from '@mui/material';
import { Player } from '@lottiefiles/react-lottie-player';
import { useNavigate } from 'react-router-dom';
import useTranslation from '../../hooks/translation';
import '../../pages/sellCoupons/SellCoupon.scss';

const SellCoupon = () => {
	const navigate = useNavigate();
	const translation = useTranslation() as any;
	const speed = parseFloat('1');
	return (
		<section className='sell_cpns pt '>
			<div className='container '>
				<Divider sx={{ margin: '10px 0 30px' }} />
				<div className='mn_sl_cpns'>
					<div className='sl_cpns_lft'>
						<Player
							src='https://lottie.host/adc5633e-5943-46ea-8c41-af48e4c954bd/UrRy8VNHQn.json'
							className='sell_lottie'
							autoplay
							loop
							background='transparent'
							speed={speed}
						/>
					</div>
					<div className='sl_cpns_ryt'>
						<div>
							<h2>{translation.SellCoupons.sellQuickly}</h2>
							<figure>
								<img
									src='../static/images/logo_coup.svg'
									alt=''
								/>
							</figure>
						</div>
						<p>{translation.SellCoupons.des}</p>
						<button
							type='button'
							className='btn'
							onClick={() => navigate('/sell-coupon-now')}
						>
							{translation.SellCoupons.btn_txt}
						</button>
					</div>
				</div>
			</div>
		</section>
	);
};

export default SellCoupon;
